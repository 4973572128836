export const businessworkflow = {
  GPO: {
   ControlName : "LTCOmnicare",
   Required:true
  },
  "Home Medical Equipment": {
    ControlName : "HomeMedical",
    Required:false
  },
  "Medical Distributor": {
    ControlName : "HomeMedical",
    Required:false
  },
  "Alt Site": {
    ControlName : "Alt",
    Required:false
  },
  HME: {
    ControlName : "Alt",
    Required:false
  },
  "LTC Network": {
    ControlName : "LTCNetwork",
    Required:true
  },
};
