import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect, useContext, useState } from "react";
import { adminAPI, memberAPI } from "../../utility/axios";
import { CommandCell } from "../MemberApplications/CommandCell";
import { useHistory } from "react-router-dom";
// import LoadingPageItem from "../../components/LoadingPageItem";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import useAzureAuthenticationAdmin from "../../hooks/useAzureAuthenticationAdmin";
import { saveSessionStorageItem } from "../../helpers/SessionStorageHelpers";
import { EnvelopeHistoryModal } from "./EnvelopeHistoryModal";
import LoadingPageItem from "../../components/LoadingPageItem";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { b64toBlob } from "../../helpers/ConversionHelpers";
// for paging
const initialDataState = {
  skip: 0,
  take: 10,
};

export function MemberApplicationsGrid(props) {
  const { data, onRefresh } = props;
  let { authInfo } = useAzureAuthentication();

  const [page, setPage] = useState(initialDataState);
  const [docusignIdForHistory, setDocusignIdForHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  let { authInfoAdmin } = useAzureAuthenticationAdmin();

  const memberApplication = useContext(MemberApplicationContext);

  const MyCommandCell = (props) => {
    return (
      <CommandCell
        {...props}
        onContinue={onContinue}
        onView={onView}
        onViewEnvelopeHistory={onViewEnvelopeHistory}
        onClone={onClone}
        onDelete={onDelete}
        onViewOutputDocument={onViewOutputDocument}
        OnSendToMIDAS={OnSendToMIDAS}
      />
    );
  };

  function routeChange(path, id) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onContinue(dataItem) {
    //below is are two sample of saving data to sessionstorage
    //have 2 samples, one to save just the applicationId, other to save an object with multiple properties

    saveSessionStorageItem("Maid", dataItem.Maid);

    //or save object with multiple properties
    let applicationDetails = {
      Maid: dataItem.Maid,
      PharmacyName: dataItem.PharmacyName,
    };
    saveSessionStorageItem(
      "MaidDetailsSample",
      JSON.stringify(applicationDetails)
    );

    memberApplication.onIDChange(dataItem.Maid);

    routeChange("/MemberService", dataItem.Maid);
  }

  function onView(dataItem) {
    routeChange("/MemberService", dataItem.Maid);
  }

  async function onClone(dataItem) {
    async function cloneMemberApplication(applicationId) {
      await memberAPI.post(
        "api/MemApp/CloneMemberApplication",
        {
          applicationId: applicationId,
          user: authInfo.userEmail,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }
    await cloneMemberApplication(dataItem.Maid);
    onRefresh();
  }

  async function onViewEnvelopeHistory(dataItem) {
    setDocusignIdForHistory(dataItem.DocusignQueueId);
  }
  async function SendOutputReportToMIDAS(dataItem) {
    setIsLoading(true);
    const res = await adminAPI.get(
      "api/DocusignQueue/GetApplicationReportDocumentPreviewUrl",
      {
        params: {
          applicationId: dataItem.Maid,
          returnUrl: window.location.href,
        },
        headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
      }
    );
    async function PushDataToMIDAS(dataItem) {
      try {
        const result = await adminAPI.post(
          "api/MercuryToMidas/PushDataFromMercuryToMIDAS",
          {
            MercuryToMIDASInput: {
              ApplicationId: dataItem.Maid,
              OwnParent: dataItem.OwnParent,
              AddUser: authInfoAdmin.userEmail,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${authInfoAdmin.userAccessToken}`,
            },
          }
        );
        if (result != null) {
          const resLst = result.data;
          alert(resLst);
        } else {
          alert(
            "Something went wrong with the Push to Midas data, please contact the support team."
          );
        }
      } catch (e) {
        alert(
          "Something went wrong with the Push to Midas, please contact the support team."
        );
      }
    }
    //if(res !=null)
    await PushDataToMIDAS(dataItem);
    onRefresh();
    setIsLoading(false);
  }

  async function PassedGPOValidationToSendMidas(applicationId) {
    //Start with a default return value of validaqtion failed, if all tests pass then it will be changed to true at the end
    let errorMessage =
      "GPO Start Date, Final Signature Date, Remedyforce ID# and all other mandatory fields on Additional Output Information screen must be populated prior to sending to Midas.";
    let res = { passedValidation: false, vailedValidationMsg: errorMessage };

    const applicaticationAdditionalInfo = await memberAPI.get(
      "api/MemAppAdditionalOuputInfo/FetchAdditionalOutputGPO",
      {
        params: {
          id: applicationId,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      }
    );

    let additionalInfoData = applicaticationAdditionalInfo.data;

    //console.log(additionalInfoData);

    //verify if RemedyforceId is populated and has a value, if not return false
    if (
      !additionalInfoData.RemedyforceId ||
      additionalInfoData.RemedyforceId.length == 0
    )
      return res;

    //verify if GPOStartDate is populated and it is a valid date value, if not return false
    if (
      !additionalInfoData.GPOStart ||
      isNaN(Date.parse(additionalInfoData.GPOStart))
    ) {
      return res;
    }

    //verify if FinalSignature is populated and it is a valid date value, if not return false
    if (
      !additionalInfoData.FinalSignature ||
      isNaN(Date.parse(additionalInfoData.FinalSignature))
    ) {
      return res;
    }

    // mha-631 addtl validation for all required fields on addtl output info screen
    if(additionalInfoData.NAM == null || additionalInfoData.NAM.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.PAR == null || additionalInfoData.PAR.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.AccountVisitor == null || additionalInfoData.AccountVisitor.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.AccountOwner == null || additionalInfoData.AccountOwner.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.InMidas == null || additionalInfoData.InMidas.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.GenerateParticipation == null || additionalInfoData.GenerateParticipation.trim().length == 0) {
      return res;
    }
    
    if(additionalInfoData.GenerateAsDeclined == null || additionalInfoData.GenerateAsDeclined.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.ParentLevelPrimaryContact == null || additionalInfoData.ParentLevelPrimaryContact.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.AffiliateLevelPrimaryContact == null || additionalInfoData.AffiliateLevelPrimaryContact.trim().length == 0) {
      return res;
    }
    
    if(additionalInfoData.AccountRepSigner == null || additionalInfoData.AccountRepSigner.trim().length == 0) {
      return res;
    }

    //if it got here then all tests passed return true that validation passed
    res.passedValidation = true;
    res.vailedValidationMsg = "";

    return res;
  }

  async function PassedAltSiteValidationToSendMidas(applicationId) {
    //Start with a default return value of validation failed, if all tests pass then it will be changed to true at the end
    let errorMessage =
      "GPO Start Date, Remedyforce ID#, Pharmacy Name, Address, City, State must be populated prior to sending to Midas.";
    let res = { passedValidation: false, vailedValidationMsg: errorMessage };

    const applicaticationAdditionalInfo = await memberAPI.get(
      "api/MemAppAdditionalOuputInfo/FetchAdditionalOutputAltSite",
      {
        params: {
          id: applicationId,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      }
    );

    let additionalInfoData = applicaticationAdditionalInfo.data;

    const applicaticationDemographicsInfo = await memberAPI.get(
      "api/MemApp/FetchMemberAppDemographics",
      {
        params: {
          id: applicationId,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      }
    );

    let demographicsData = applicaticationDemographicsInfo.data[0];

    //verify if RemedyforceId is populated and has a value, if not return false
    if (
      !additionalInfoData.RemedyforceId ||
      additionalInfoData.RemedyforceId.length == 0
    )
      return res;

    //verify if GPOStartDate is populated and it is a valid date value, if not return false
    if (
      !additionalInfoData.GPOStart ||
      isNaN(Date.parse(additionalInfoData.GPOStart))
    ) {
      return res;
    }

    //if PHARNAME, ADDR, CITY,STATE isnull or whitespace return false

    if (
      !demographicsData.PHARNAME ||
      demographicsData.PHARNAME.trim().length == 0
    ) {
      return res;
    }

    if (!demographicsData.ADDR || demographicsData.ADDR.trim().length == 0) {
      return res;
    }

    if (!demographicsData.CITY || demographicsData.CITY.trim().length == 0) {
      return res;
    }

    if (!demographicsData.STATE || demographicsData.STATE.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.NAM == null || additionalInfoData.NAM.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.PAR == null || additionalInfoData.PAR.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.AccountVisitor == null || additionalInfoData.AccountVisitor.trim().length == 0) {
      return res;
    }

    if(additionalInfoData.AccountOwner == null || additionalInfoData.AccountOwner.trim().length == 0) {
      return res;
    }

    // if(additionalInfoData.InMidas == null || additionalInfoData.InMidas.trim().length == 0) {
    //   return res;
    // }

    if(additionalInfoData.GenerateParticipation == null || additionalInfoData.GenerateParticipation.trim().length == 0) {
      return res;
    }

    //if it got here then all tests passed return true that validation passed
    res.passedValidation = true;
    res.vailedValidationMsg = "";

    return res;
  }

  //To pass validation RemedyForceId, GPOStartDatew, and Final Signature Date must be populated
  async function PassedValidationToSendMidas(applicationId, classOfTrade) {
    //define an array with a list of GPO COTs
    let gpoCOTs = ["OMNICARE", "LTC/NURSING HOME PROVIDER"];

    let result = {};

    //if COT is not in the list of GPO COTs call PassedGPOValidationToSendMidas validation logic, else cal AltSIte validation logic
    if (gpoCOTs.includes(classOfTrade)) {
      result = await PassedGPOValidationToSendMidas(applicationId);
    } else {
      result = await PassedAltSiteValidationToSendMidas(applicationId);
    }
    //console.log("1", result);

    //Ifg validation failed display reason - then return validation result whether ot passed or failed
    if (!result.passedValidation) {
      alert(result.vailedValidationMsg);
    }
    return result.passedValidation;
  }

  async function OnSendToMIDAS(dataItem) {
    //console.log("OnSendToMIDAS", dataItem);

    let passedValidation = await PassedValidationToSendMidas(
      dataItem.Maid,
      dataItem.ClassOfTrade
    );

    if (!passedValidation) {
      return;
    }

    /*Output Report Push to MIDAS*/
    SendOutputReportToMIDAS(dataItem);
  }

  async function onDelete(dataItem) {
    console.log(dataItem);
    async function deleteMemberApplication(applicationId) {
      await memberAPI.post(
        "api/MemApp/DeleteMemberApplication",
        {
          applicationId: applicationId,
          user: authInfo.userEmail,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    if (
      window.confirm("Are you sure you want to delete this member application?")
    ) {
      await deleteMemberApplication(dataItem.Maid);
      onRefresh();
    }
  }

  async function onViewOutputDocument(dataItem) {
    setIsLoading(true);

    try {
      const res = await adminAPI.get(
        "api/DocusignQueue/GetApplicationReportDocumentPreviewUrl",
        {
          params: {
            applicationId: dataItem.Maid,
            returnUrl: window.location.href,
          },
          headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
        }
      );

      // window.open(res.data, "_blank");

      async function fetchPdf() {
        const res1 = await adminAPI.get(
          "api/DocusignQueue/GetApplicationReportDocumentPDF",
          {
            params: {
              applicationId: dataItem.Maid,
            },
            headers: {
              Authorization: `Bearer ${authInfoAdmin.userAccessToken}`,
            },
          }
        );
        return res1.data;
      }
      const data = await fetchPdf();
      const blob = b64toBlob(data, "application/pdf");

      let url = URL.createObjectURL(blob);

      let tab = window.open();
      tab.location.href = url;
    } catch (e) {
      alert(
        "An error has occured while generating the output report. If the error continues please contact IT."
      );
    }
    setIsLoading(false);
  }
  useEffect(() => {}, [authInfo]);

  return (
    <>
      {isLoading ? (
        <LoadingPageItem />
      ) : (
        <>
          <Grid
            data={data.slice(page.skip, page.take + page.skip)}
            skip={page.skip}
            take={page.take}
            total={data.length}
            pageable
            onPageChange={(e) => setPage(e.page)}
          >
            <GridColumn field="PharmacyName" title="Pharmacy / Provider Name" />
            <GridColumn field="Service" />
            <GridColumn field="ClassOfTrade" title="COT" />
            <GridColumn field="Dea" title="DEA" />
            <GridColumn field="Hin" title="HIN" />
            <GridColumn field="Npi" title="NPI" />
            <GridColumn field="Ncpdp" title="NCPDP" />
            <GridColumn field="OwnerName" title="Owner" />
            <GridColumn field="Status" />
            <GridColumn
              field="DocusignStatus"
              title="Packet Status"
              width={170}
            />
            <GridColumn
              field="LastChangedDate"
              title="Last Changed Date"
              format={"{0:MM/dd/yyyy hh:mm:ss a}"}
            />
            <GridColumn title="Action" cell={MyCommandCell} />
          </Grid>
          <EnvelopeHistoryModal
            docusignId={docusignIdForHistory}
            onModalClose={() => setDocusignIdForHistory(null)}
          />
        </>
      )}
    </>
  );
}
