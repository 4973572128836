
export function SelectAllHeaderCell(props) {
    const {
        onSelectAll,
        checked
    } = props


    return (
        <span>
            <span className="form-check form-switch"> 
                <input className="form-check-input" id="select-all" type="checkbox" onChange={onSelectAll} checked={checked}/>
                <label className="form-check-label" htmlFor="select-all"> Select All</label>

            </span>
            
        </span>
    )
}