import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect, useRef, useState } from "react";
import useAzureAuthenticationAdmin from "../../hooks/useAzureAuthenticationAdmin";
import { adminAPI } from "../../utility/axios";
import { Modal } from "bootstrap";
import LoadingPageItem from "../../components/LoadingPageItem";

const initialDataState = {
  skip: 0,
  take: 10,
};

export function EnvelopeHistoryModal(props) {

  const { docusignId, onModalClose } = props;
  const [data, setData] = useState([]);
  const [dupData, setDupData] = useState([])
  const [page, setPage] = useState(initialDataState);
  const [isLoading, setIsLoading] = useState(true);

  let { authInfoAdmin } = useAzureAuthenticationAdmin();

  useEffect(() => {
    async function fetchEnvelopeHistory() {
        const res = await adminAPI.get(
          "api/Envelope/FetchEnvelopeHistory",
          {
              params: { ParamData : {
                  "FETCHFOR": "LIST",
                  "QUEUEID": docusignId
              }
                  
              },
              headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
          }
        )

        const newData = res.data.map((item) => {
            let temp = Object.assign({}, item);
            temp.PROCESSTIME = new Date(item.PROCESSTIME);
            return temp;
        });

        const newDupData = [{QUEUEID: res.data[0].QUEUEID, MEMBERID: res.data[0].MEMBERID, EMAILID: res.data[0].EMAILID}]
       
        setIsLoading(false);
        setData(newData)
        setDupData(newDupData)
    }
    
    if (authInfoAdmin && docusignId) {
        fetchEnvelopeHistory();
    }
  }, [authInfoAdmin, docusignId]);


  return (
    <>
      <div
        className="modal fade"
        id="envelope-history-grid-modal"
        tabIndex="-1"
        aria-hidden="true"
        aria-labelledby="envelope-history-grid-modal-label"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">Envelope History</div>
            <div className="modal-body">
              {isLoading ? (
                <LoadingPageItem />
              ) : (

                <>
                <Grid data={dupData}>
                    <GridColumn field="QUEUEID" title="Queue ID" width="125px"/>
                    <GridColumn field="MEMBERID" title="Application ID" width="125px" />
                    <GridColumn field="EMAILID" title="Email" />
                </Grid>
                <Grid
                  data={data.slice(page.skip, page.take + page.skip)}
                  skip={page.skip}
                  take={page.take}
                  total={data.length}
                  pageable
                  onPageChange={(e) => setPage(e.page)}
                >
                  <GridColumn field="MEMBERNAME" title="Member Name" width="150px" />
                  <GridColumn 
                    field="PROCESSTIME" 
                    title="Process Time"         
                    format={"{0:MM/dd/yyyy hh:mm:ss a}"}
                    width="125px"/>
                  <GridColumn field="DOCSTATUS" title="Status" width="100px" />
                  <GridColumn field="PROCESSHISTORY" title="Process History" />
                </Grid>
                </>

              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onModalClose()}
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
