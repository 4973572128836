export const pharmacyworkflow = {
    GPO: {
     ControlName : "GPO",
     Condition : "TaxIdMandatory",
     showVendor : "Allowed",
     required : true,
     requiredMessage:"Value is required"
    },
    "Home Medical Equipment": {
      ControlName : "Alt",
      Condition : "TaxIdNonMandatory",
      showVendor : "NotAllowed",
      required: false,
      requiredMessage:"Value is required"
    },
    "Medical Distributor": {
      ControlName : "Alt",
      Condition : "TaxIdNonMandatory",
      showVendor : "NotAllowed",
      required: false,
      requiredMessage:"Value is required"
    },
    "Alt Site": {
      ControlName : "Alt",
      Condition : "TaxIdNonMandatory",
      showVendor : "Allowed",
      required : false,
      requiredMessage:"Value is required"
    },
    "LTC Network": {
      ControlName : "LTCNetwork",
      Condition : "TaxIdMandatory",
      showVendor : "Allowed",
      required : true,
     requiredMessage:"Value is required"
    },
    // "HME": {
    //   ControlName : "Alt",
    //   Condition : "TaxIdNonMandatory",
    //   showVendor : "Allowed"
    // },
  };