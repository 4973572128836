export function OwnParentCheckbox(props) {

    return props.service === 1 && (
        <div className="form-check">
            <input className="form-check-input" type="checkbox" disabled={props.disabled} value="" onChange={props.onChange} id="OwnParent" checked={props.hasOwnParent}/>
            <label className="form-check-label" htmlFor="OwnParentCheck">
            Own Parent
            </label>
          </div>
    )
}