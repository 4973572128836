import useAzureAuthentication from "../../hooks/useAzureAuthentication";

function LoggedInAs() {
  let { authInfo, logout } = useAzureAuthentication();

  return (
    <>
      {authInfo && (
        <div>
          <span className="me-2">Logged in:</span>
          <span className="me-3" style={{ fontWeight: "bold" }}>
            {authInfo.userEmail}
          </span>
          <a className="text-white" onClick={logout}>
            Logout
          </a>
        </div>
      )}
    </>
  );
}

export default LoggedInAs;
