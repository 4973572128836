import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect, useState,useContext } from "react";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { memberAPI } from "../../utility/axios";
import LoadingPageItem from "../../components/LoadingPageItem";
import { businessworkflow as pageWorkflow } from "./../BusinessInfo/BusinessInfoWorkflow";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
const initialDataState = {
  skip: 0,
  take: 10,
};

export function VendorGrid(props) {
  const { id } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(initialDataState);
  const [isLoading, setIsLoading] = useState(true);
  let { authInfo } = useAzureAuthentication();
  const [businessInfocurrentWorkflow, setbusinessInfocurrentWorkflow] = useState(null);
  const memberApplication = useContext(MemberApplicationContext);
  useEffect(() => {
    if (memberApplication.businessworkflow) {
      setbusinessInfocurrentWorkflow(pageWorkflow[memberApplication.businessworkflow]);
    }
    async function fetchVendors() {
      const res = await memberAPI.get("api/Vendor/FetchVendors", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setData(res.data);
      setIsLoading(false);
    }
    async function fetchVendors_AltSite() {
      const res = await memberAPI.get("api/Vendor/FetchVendor_AltSite", {
        params: {
          applicationid: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setData(res.data);
      setIsLoading(false);
    }
    if (authInfo) { 
      if (businessInfocurrentWorkflow?.ControlName == "LTCOmnicare") {
      fetchVendors();
      } else {
        fetchVendors_AltSite();
      }
    }
  }, [authInfo, memberApplication.businessworkflow]);
  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <Grid
      data={data.slice(page.skip, page.take + page.skip)}
      skip={page.skip}
      take={page.take}
      total={data.length}
      pageable
      onPageChange={(e) => setPage(e.page)}
    >
      <GridColumn field="Name" title="Vendor Name" />
      <GridColumn field="ContractDescription" title="Contract Description" />
      <GridColumn field="ContractNumber" title="Contract Number" />
      <GridColumn field="Program" />
    </Grid>
  );
}
