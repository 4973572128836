export const memberSourceworkflow = {
    GPO: {
     required : true
    },
    "Home Medical Equipment": {
      
      required: false
    },
    "Medical Distributor": {
      required: false
    },
    "Alt Site": {
      required : false
    },
    "LTC Network": {
      required : true
    },
    // "HME": {
    //   ControlName : "Alt",
    //   Condition : "TaxIdNonMandatory",
    //   showVendor : "Allowed"
    // },
  };