import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfigAdmin";

function useAzureAuthenticationAdmin() {
  const { instance, accounts } = useMsal();

  const [authInfoAdmin, setAuthInfoAdmin] = useState(null);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      fetchAuthInfo();
    }
  }, [accounts]);

  async function fetchAuthInfo() {
    async function getAuthInfo() {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const result = {
        userEmail: accounts[0].username,
        userAccessToken: response.accessToken,
      };

      setAuthInfoAdmin(result);
    }

    await getAuthInfo();
  }

  return { authInfoAdmin };
}

export default useAzureAuthenticationAdmin;
