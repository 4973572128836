import PharmacyPrimaryContactForm from './PharmacyPrimaryContactForm'
import { useParams } from 'react-router-dom';
import PharmacyPrimaryContactGrid from './PharmacyPrimaryContactGrid';


export default function PharmacyPrimaryContact(props) {

    const params = useParams();

    const { id } = params;

    
    return (
        <>
            <h4 className="text-start">Pharmacy / Provider Contact</h4>
            <hr/>  
            <PharmacyPrimaryContactGrid id={id}/>
            
        </>
    )
}