import { useParams } from 'react-router-dom';
import { MemberSourceForm } from './MemberSourceForm';

export default function MemberSource(props) {

    const params = useParams();

    return (
        <>
            <h4 className="text-start">Member Source</h4>
            <hr/>  
            <MemberSourceForm id={params.id}/>
        </>
    )  
}