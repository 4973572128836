//implementing MOA-84 - now supports any GPO service
function ShowSendToMIDASButton(dataItem) {
  //first test items that apply to both workflows ALtSIte and Non-AltSite
  if (dataItem.DocusignStatus !== "Completed") return false;

  //Any GPO service
  if (dataItem.Service !== "GPO") return false;

  //Must be a single location
  if (dataItem.MultiLoc !== 0) return false;

  //Name cannot contain text "see roster", case insensitive
  if (dataItem.PharmacyName === null || (dataItem.PharmacyName !== null && dataItem.PharmacyName.toLowerCase().indexOf("see roster") !== -1))
    return false;

  return true;
}

export function CommandCell(props) {
  const {
    colSpan,
    style,
    dataItem,
    onView,
    onContinue,
    onClone,
    onViewEnvelopeHistory,
    onDelete,
    onViewOutputDocument,
    OnSendToMIDAS,
  } = props;

  return (
    <td colSpan={colSpan} style={style}>
      {dataItem.DocusignQueueId === 999 ? (
        <i
          className="action-icon bi-eye"
          onClick={() => onView(dataItem)}
          title="View"
        />
      ) : (
        <i
          className="action-icon bi-play-circle"
          onClick={() => onContinue(dataItem)}
          title="Continue Application"
        />
      )}

      <i
        className="action-icon bi-file"
        onClick={() => onViewOutputDocument(dataItem)}
        title="View Output Field Document"
      />

      {dataItem.DocusignQueueId && (
        <i
          data-bs-toggle="modal"
          data-bs-target="#envelope-history-grid-modal"
          className="action-icon bi-clock-history"
          onClick={() => onViewEnvelopeHistory(dataItem)}
          title="View Envelope History"
        />
      )}
      <i
        className="action-icon bi-clipboard"
        onClick={() => onClone(dataItem)}
        title="Clone App"
      />
      {!dataItem.DocusignId && (
        <i
          className="action-icon bi-trash"
          onClick={() => onDelete(dataItem)}
          title="Delete App"
        />
      )}
      {/* {dataItem.DocusignStatus === "Completed" &&
        dataItem.MultiLoc === 0 &&
        (dataItem.ClassOfTrade === "OMNICARE" ||
          dataItem.ClassOfTrade === "LTC/NURSING HOME PROVIDER") && (
          <i
            className="action-icon bi-arrow-right-circle"
            onClick={() => OnSendToMIDAS(dataItem)}
            title="Send to MIDAS"
          />
        )} */}

      {ShowSendToMIDASButton(dataItem) && (
        <i
          className="action-icon bi-arrow-right-circle"
          onClick={() => OnSendToMIDAS(dataItem)}
          title="Send to MIDAS"
        />
      )}
    </td>
  );
}
