import React, { useState, useEffect, useContext } from "react";
import { validateLocaleAndSetLanguage } from "typescript";
import { BusinessInfoState } from "./BusinessInfoContext";
import { getWorkFlow } from "../../helpers/AppDataHelpers";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
const BusinessInfoAltSite = (props) => {
  const {
    applicationId,
    targetBusinessInfoAltSite,
    setTargetBusinessInfoAltSite,
    AltValidationPassed,
    setAltValidationPassed,
    required
  } = BusinessInfoState();

  const memberApplication = useContext(MemberApplicationContext);
  const [ClosedDoorInfusionSpecialty, setClosedDoorInfusionSpecialty] = useState(false);
  const [Clinic, setClinic] = useState(false);
  const [Doctors, setDoctors] = useState(false);
  const [Retail, setRetail] = useState(false);
  const [MailOrder, setMailOrder] = useState(false);
  const [HMOStaffModel, setHMOStaffModel] = useState(false);
  const [HMOOnly, setHMOOnly] = useState(false);
  const [HomeHealth, setHomeHealth] = useState(false);
  const [InfusionPharmacy, setInfusionPharmacy] = useState(false);
  const [LTCPharmacy, setLTCPharmacy] = useState(false);
  const [Laboratory, setLaboratory] = useState(false);
  const [Hospice, setHospice] = useState(false);
  const [SurgeryCenter, setSurgeryCenter] = useState(false);
  const [AcuteCare, setAcuteCare] = useState(false);
  useEffect(() => {
    
    if (targetBusinessInfoAltSite.length > 0) {
      for (var i = 0; i < targetBusinessInfoAltSite.length; i++) {
        let ServiceId = targetBusinessInfoAltSite[i].ServiceId;
        if (ServiceId == 1)
          setClosedDoorInfusionSpecialty(true);
        else if (ServiceId == 2)
          setClinic(true);
        else if (ServiceId == 3)
          setDoctors(true);
        else if (ServiceId == 4)
          setRetail(true);
        else if (ServiceId == 5)
          setMailOrder(true);
        else if (ServiceId == 6)
          setHMOStaffModel(true);
        else if (ServiceId == 7)
          setHMOOnly(true);
        else if (ServiceId == 8)
          setHomeHealth(true);
        else if (ServiceId == 9)
          setInfusionPharmacy(true);
        else if (ServiceId == 10)
          setLTCPharmacy(true);
        else if (ServiceId == 11)
          setLaboratory(true);
        else if (ServiceId == 12)
          setHospice(true);
        else if (ServiceId == 13)
          setSurgeryCenter(true);
        else if (ServiceId == 14)
          setAcuteCare(true);
      }
    } else {
     // setAltPassedValidator(targetBusinessInfoAltSite);
    }
  }, [targetBusinessInfoAltSite]);

  useEffect(() => {
    
  }, []);

  const setAltPassedValidator = (list) => {
    let isValid;
    if(required)
    {
    if(list.length > 0) isValid=true; else isValid=false;
    }
    else
    isValid = true;
    //Check if current validationPassed value already set to our isValid - if so no need tro do anything.
    if (AltValidationPassed == isValid) return;

    //If it gets here the validation value is not same as isValid - we need to set it
    setAltValidationPassed(isValid);
  };
  function onClosedDoorInfusionSpecialtyChange(e) {

    if (e != undefined) {
      setClosedDoorInfusionSpecialty(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onClinicChange(e) {
    if (e != undefined) {
      setClinic(e.target.checked);
      cotPercentageUpdated(e);
    }
  }
  function onDoctorsCheckChange(e) {
    if (e != undefined) {
      setDoctors(e.target.checked);
      cotPercentageUpdated(e);
    }
  }

  function onRetailCheckChange(e) {
    if (e != undefined) {
      setRetail(e.target.checked);
        cotPercentageUpdated(e);
    }
  }

  function onMailOrderCheckChange(e) {
    if (e != undefined) {
      setMailOrder(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onHMOStaffModelCheckChange(e) {
    if (e != undefined) {
      setHMOStaffModel(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onHMOOnlyChange(e) {
    if (e != undefined) {
      setHMOOnly(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onHomeHealthChange(e) {
    if (e != undefined) {
      setHomeHealth(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onInfusionPharmacyChange(e) {
    if (e != undefined) {
      setInfusionPharmacy(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onLTCPharmacyChange(e) {
    if (e != undefined) {
      setLTCPharmacy(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onLaboratoryChange(e) {
    if (e != undefined) {
      setLaboratory(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onHospiceCheckChange(e) {
    if (e != undefined) {
      setHospice(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  function onSurgeryCenterChange(e) {
    if (e != undefined) {
      setSurgeryCenter(e.target.checked);
        cotPercentageUpdated(e);
    }
  }

  function onAcuteCareCheckChange(e) {
    if (e != undefined) {
      setAcuteCare(e.target.checked);
        cotPercentageUpdated(e);
    }
  }
  const getCotId = (input) => {
    const cotId = input.split("_").pop();

    return cotId;
  };
  const cotPercentageUpdated = (e) => {
    //let cotId = e.target.name.split("_").pop(); // => "Tabs1"
   let isValid;
    const value = e.target.value;
    let newList=[];
    //This has to be a number
    if (isNaN(value)) return;

    const thisCotId = getCotId(e.target.name);
    if (e.target.checked == true) {
      let exists = targetBusinessInfoAltSite.some((t) => t.ServiceId == thisCotId);

      if (!exists) {
        const newrecord = {
          applicationId: applicationId,
          ServiceId: thisCotId
        };

        let updatednewrecord = [...targetBusinessInfoAltSite, newrecord];
        setTargetBusinessInfoAltSite(updatednewrecord);
        setAltPassedValidator(updatednewrecord);
        return;
      }
    } else {
      newList = targetBusinessInfoAltSite.filter((item) => item.ServiceId != thisCotId);
      setTargetBusinessInfoAltSite(newList);
      setAltPassedValidator(newList);
    }
    
  };


  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onClosedDoorInfusionSpecialtyChange}
          id="ClosedDoorInfusionSpecialtyCheck"
          checked={ClosedDoorInfusionSpecialty}
          name="ClosedDoorInfusionSpecialty_1"
        />
        <label
          className="form-check-label"
          htmlFor="ClosedDoorInfusionSpecialtyCheck"
        >
          Closed-Door Infusion/Specialty
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onClinicChange}
          id="ClinicCheck"
          checked={Clinic}
          name="Clinic_2"
        />
        <label
          className="form-check-label"
          htmlFor="ClinicCheck"
        >
          Clinic
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onDoctorsCheckChange}
          id="DoctorsCheck"
          checked={Doctors}
          name="Doctors_3"
        />
        <label
          className="form-check-label"
          htmlFor="DoctorsCheck"
        >
          Doctors
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onRetailCheckChange}
          id="RetailCheck"
          checked={Retail}
          name="Retail_4"
        />
        <label
          className="form-check-label"
          htmlFor="RetailCheck"
        >
          Retail
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onMailOrderCheckChange}
          id="MailOrderCheck"
          checked={MailOrder}
          name="MailOrder_5"
        />
        <label
          className="form-check-label"
          htmlFor="MailOrderCheck"
        >
          Mail Order
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onHMOStaffModelCheckChange}
          id="HMOStaffModelCheck"
          checked={HMOStaffModel}
          name="HMOStaffModel_6"
        />
        <label
          className="form-check-label"
          htmlFor="HMOStaffModelCheck"
        >
          HMO Staff Model
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onHMOOnlyChange}
          id="HMOOnlyCheck"
          checked={HMOOnly}
          name="HMOOnly_7"
        />
        <label
          className="form-check-label"
          htmlFor="HMOOnlyCheck"
        >
          HMO Only
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onHomeHealthChange}
          id="HomeHealthCheck"
          checked={HomeHealth}
          name="HomeHealth_8"
        />
        <label
          className="form-check-label"
          htmlFor="HomeHealthCheck"
        >
          Home Health
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onInfusionPharmacyChange}
          id="InfusionPharmacyCheck"
          checked={InfusionPharmacy}
          name="InfusionPharmacy_9"
        />
        <label
          className="form-check-label"
          htmlFor="InfusionPharmacyCheck"
        >
          Infusion Pharmacy w/Addl Business Unit (HI Combo)
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onLTCPharmacyChange}
          id="LTCPharmacyCheck"
          checked={LTCPharmacy}
          name="LTCPharmacy_10"
        />
        <label
          className="form-check-label"
          htmlFor="LTCPharmacyCheck"
        >
          LTC Pharmacy w/Addl Business Unit (LTC Combo)
        </label>
      </div>


      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onLaboratoryChange}
          id="LaboratoryCheck"
          checked={Laboratory}
          name="Laboratory_11"
        />
        <label
          className="form-check-label"
          htmlFor="LaboratoryCheck"
        >
          Laboratory
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onHospiceCheckChange}
          id="HospiceCheck"
          checked={Hospice}
          name="Hospice_12"
        />
        <label
          className="form-check-label"
          htmlFor="HospiceCheck"
        >
          Hospice
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onSurgeryCenterChange}
          id="SurgeryCenterCheck"
          checked={SurgeryCenter}
          name="SurgeryCenter_13"
        />
        <label
          className="form-check-label"
          htmlFor="SurgeryCenterCheck"
        >
          Surgery Center
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onAcuteCareCheckChange}
          id="AcuteCareCheck"
          checked={AcuteCare}
          name="AcuteCare_14"
        />
        <label
          className="form-check-label"
          htmlFor="AcuteCareCheck"
        >
          Acute Care
        </label>
      </div>
      <div>
      {!AltValidationPassed && (
                  <div className="text-danger nowrap">Value is required</div>
      )}
       </div>
    </>
  );
};

export default BusinessInfoAltSite;
