import MemberServiceForm from './MemberServiceForm'
import { useParams } from 'react-router-dom';
import MemberServiceContext from './MemberServiceContext';
export default function MemberService(props) {

    const params = useParams();
    return (
        <>
            <h4 className="text-start">Application Service / COT</h4>
            <hr/>  
            <MemberServiceContext>
            <MemberServiceForm id={params.id}/>
            </MemberServiceContext>
        </>
    )
}