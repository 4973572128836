import React from "react";
import BusinessInfoHoursOfOperationDailyTimes from "./BusinessInfoHoursOfOperationDailyTimes";
import { DaysOfWeek as DaysOfWeekConstant } from "./BusinessInfoConstants";

import { BusinessInfoState } from "./BusinessInfoContext";

const BusinessInfoHoursOfOperationDaily = (props) => {
  const { dailyHoursOfOperation, setDailyHoursOfOperation } =
    BusinessInfoState();
  const {disabled} = props
  const updateDailyHoursOfOperation = (value, updateItem) => {
    const updated = dailyHoursOfOperation.map((item) => {
      if (item.dayOfWeek === value.dayOfWeek)
        return { ...value, [updateItem]: value[updateItem] };

      return item;
    });

    //alert(JSON.stringify(updated));

    setDailyHoursOfOperation(updated);
  };

  const isDayActive = (dayOfWeek) => {
    //alert(dayOfWeek);
    const isActive = dailyHoursOfOperation.some(
      (t) => t.dayOfWeek === dayOfWeek && t.isActive
    );

    //if (dayOfWeek === 0) alert(isActive);

    return isActive;
  };

  const getHoursOfOperationForDay = (day) => {
    //This record is guarnateed to exist. Threfore not even testing whether exists or not
    const result = dailyHoursOfOperation.find((t) => t.dayOfWeek === day);

    return result;
  };

  const updateIsActiveStatusForDay = (day) => {
    let updated = getHoursOfOperationForDay(day);
    updated.isActive = !updated.isActive;

    updateDailyHoursOfOperation(updated);
  };

  return DaysOfWeekConstant.map((item) => {
    const dayIsActive = isDayActive(item.value);

    return (
      <div key={item.value} className="list-group list-group">
        <div className="list-group-item row">
          <div className="col">
            <input
              className="form-check-input me-1"
              type="checkbox"
              value={dayIsActive}
              checked={dayIsActive}
              aria-label="..."
              disabled={disabled}
              onChange={() => {
                updateIsActiveStatusForDay(item.value);
              }}
            />
            {item.name}
          </div>

          <div className="col">
            {dayIsActive && (
              <BusinessInfoHoursOfOperationDailyTimes
                disabled={disabled}
                HoursOfOperation={getHoursOfOperationForDay(item.value)}
                updateDailyHoursOfOperation={updateDailyHoursOfOperation}
              />
            )}
          </div>
        </div>
      </div>
    );
  });
};

export default BusinessInfoHoursOfOperationDaily;
