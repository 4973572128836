import React from "react";
import { useParams } from "react-router";
import AdditionalInformationForm from "./AdditionalInformationForm";

const AdditionalInformation = () => {
  const params = useParams();

  return (
    <>
      <h4 className="text-start">Additional Member Information</h4>
      <hr />

      <AdditionalInformationForm id={params.id} />
    </>
  );
};

export default AdditionalInformation;
