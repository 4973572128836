import { useEffect, useState } from "react";
import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";

const AuthenticatedCallTests = () => {
  let { authInfo } = useAzureAuthentication();
  useEffect(() => {
    if (authInfo) makeCall();
  }, [authInfo]);

  async function makeCall() {
    let res = await memberAPI.get("api/COT/FetchAllClassOfTrades", {
      params: {
        addressType: "111",
      },
      headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
    });

    console.log("done");
  }

  return (
    <div>
      <button onClick={() => makeCall()}>Make API Call</button>
    </div>
  );
};

export default AuthenticatedCallTests;
