import React from "react";
import { useParams } from "react-router";
import DistributorWholesalerForm from "./DistributorWholesalerForm";

const DistributorWholesaler = () => {
  const params = useParams();
  return (
    <>
      <h4 className="text-start">Wholesaler/Distributor List</h4>
      <hr />

      <DistributorWholesalerForm id={params.id} />
    </>
  );
};

export default DistributorWholesaler;
