import React, { useEffect } from "react";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";

import { Input, RadioGroup } from "@progress/kendo-react-inputs";

import { TextArea } from "@progress/kendo-react-inputs";

import { Label, Hint, Error } from "@progress/kendo-react-labels";

const AdditionalInfoGPO = ({
  gpoData,
  setGpoData,
  optionsYesNo,
  wasSubmitted,
  setPostedDetails,
  additionalInfoCurrentworkflow,
  isReadOnly
}) => {
  const optionsIsProfit = [
    {
      label: "Profit",
      value: "Profit",
    },
    {
      label: "Non-Profit",
      value: "Non-Profit",
    },
  ];

  const FormRadioGroup = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      id,
      label,
      layout,
      valid,
      disabled,
      hint,

      ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    return (
      <FieldWrapper>
        <Label
          id={labelId}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <RadioGroup
          id={id}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ariaLabelledBy={labelId}
          valid={valid}
          disabled={disabled}
          layout={layout}
          {...others}
        />
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    );
  };

  const FormInput = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <Input
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            rows={3}
            ariaDescribedBy={`${hindId} ${errorId}`}
            {...others}
          />
          {showHint && <Hint id={hindId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    );
  };

  const FormTextArea = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <TextArea
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            rows={3}
            ariaDescribedBy={`${hindId} ${errorId}`}
            {...others}
          />
          {showHint && <Hint id={hindId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    );
  };

  const isSubmittedRef = React.useRef(false);

  const handleSubmit = (dataItem) => {
    isSubmittedRef.current = false;

    setGpoData(dataItem);
    
  };

  const radioGroupValidator = (value) =>
    value == null ? "Selection is required" : "";

  const requiredValidator = (value) => (!value ? "Value is required" : "");

  const AutoSubmitter = (formRenderProps) => {
    const isSubmittedRef = React.useRef(false);
    useEffect(() => {
      const { valid, modified, onSubmit } = formRenderProps;
      if (!wasSubmitted) {
        return;
      }

      onSubmit();

      const postDetails = {
        wasUpdated: modified,
        isValid: valid,
      };

      setPostedDetails(postDetails);

    });
    return [];
  };

  return (
    <>
      {gpoData && (
       
        <Form
          initialValues={{
            isProfitFacility: gpoData.profitFacility,

            doesofferIvTherapy: gpoData.doesOfferIvTherapy,
            baxterAccountNumberDescription:
              gpoData.baxterAccountNumberDescription,
            doesShareBuildingWithRetailPharmacy:
              gpoData.doesShareBuildingWithRetailPharmacy,
            howPrescriptionsDelivered: gpoData.howPrescriptionsDelivered,
            isDistributionCenter: gpoData.isDistributionCenter,
            toWhomeDistributesTo: gpoData.toWhoDistributesTo,
            includeSalesorInventoryAtRetail:
              gpoData.includeSalesOrRetailInventory,
            hasRetailPharmacyAffiliation: gpoData.hasRetailPharmacyAffiliation,
            hasWholesalerCustomerNumber: gpoData.hasWholesalerCustomerNumber,
            hasDeaNumber: gpoData.hasDeaNumber,
            isSeparatedByFourWalls: gpoData.isSeparatedByFourWalls,
            hasSeparateLicenseForEachPharmacy:
              gpoData.hasSeparateLicenseForEachPharmacy,
            hasSeparateEntranceForEachPharmacy:
              gpoData.hasSeparateEntranceForEachPharmacy,
            hasPhysicalInventorySeparations:
              gpoData.hasPhysicalInventorySeparations,
            physicalInventorySeparationDescriptions:
              gpoData.physicalInventorySeparationDescriptions,
            associatedPharmacyiesDEANumber:
              gpoData.associatedPharmacyDeaNumbers,
            isCompanyProvidePedigree:
              gpoData.companyProvidePedigree,
            identifySourceOfPedigreeValue:
              gpoData.identifySourceOfPedigree,
            isCompanyAccreditedvalue:
              gpoData.isCompanyAccredited,
            identifySourceOfAccreditationvalue:
              gpoData.identifySourceOfAccreditation = null? "" : gpoData.identifySourceOfAccreditation
          }}
          
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 650,
              }}
              name="myForm"
            >
              <AutoSubmitter {...formRenderProps} />
              {(additionalInfoCurrentworkflow?.Condition == "DisplayAdditionalQuestion") && (
                <>
                  <div class="row mt-4">
                    <Field
                      id={"isProfitFacility"}
                      name={"isProfitFacility"}
                      label={
                        "1. This facility undersigned states that it is a _____ facility."
                      }
                      component={FormRadioGroup}
                      data={optionsIsProfit}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div class="row">
                    <Field
                      id={"doesofferIvTherapy"}
                      name={"doesofferIvTherapy"}
                      label={
                        "2. Does any part of your facility provide IV Therapy?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>

                  {formRenderProps.valueGetter("doesofferIvTherapy") &&
                    formRenderProps.valueGetter("doesofferIvTherapy") && (
                      <div class="row">
                        <span className="ms-4">
                          <Field
                            id={"baxterAccountNumberDescription"}
                            name={"baxterAccountNumberDescription"}
                            label={
                              " 2A. Does your pharmacy currently have a Baxter Account Number, If so?"
                            }
                            component={FormInput}
                            disabled={isReadOnly}
                          />
                        </span>
                      </div>
                    )}
                  <div class="row">
                    <Field
                      id={"howPrescriptionsDelivered"}
                      name={"howPrescriptionsDelivered"}
                      label={
                        "3. How do you deliver prescriptions to your patients? *"
                      }
                      component={FormInput}
                      validator={requiredValidator}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div class="row">
                    <Field
                      id={"isDistributionCenter"}
                      name={"isDistributionCenter"}
                      label={
                        "4. Do you or any of your associated/ affiliated facilities act as a distribution center?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>
                  {formRenderProps.valueGetter("isDistributionCenter") &&
                    formRenderProps.valueGetter("isDistributionCenter") && (
                      <div class="row">
                        <span className="ms-4">
                          <Field
                            id={"toWhomeDistributesTo"}
                            name={"toWhomeDistributesTo"}
                            label={" 4A. If yes, whom do they distribute to:"}
                            component={FormTextArea}
                            rows="2"
                            disabled={isReadOnly}
                          />
                        </span>
                      </div>
                    )}
                  <div className="row mt-4 mb-2">
                    <h6>Relationship to a retail pharmacy</h6>
                  </div>
                  <div class="row">
                    <Field
                      id={"includeSalesorInventoryAtRetail"}
                      name={"includeSalesorInventoryAtRetail"}
                      label={
                        "5. Does any part of the facility's business(s) include sales of inventory at retail?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div class="row">
                    <Field
                      id={"doesShareBuildingWithRetailPharmacy"}
                      name={"doesShareBuildingWithRetailPharmacy"}
                      label={
                        "6. Is your pharmacy in the same building as a retail pharmacy?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div class="row">
                    <Field
                      id={"hasRetailPharmacyAffiliation"}
                      name={"hasRetailPharmacyAffiliation"}
                      label={
                        "7. Does this facility have any affiliation to a retail pharmacy?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>

                  {formRenderProps.valueGetter("hasRetailPharmacyAffiliation") &&
                    formRenderProps.valueGetter("hasRetailPharmacyAffiliation") && (
                      <>
                        <div className="row">
                          <Field
                            id={"hasWholesalerCustomerNumber"}
                            name={"hasWholesalerCustomerNumber"}
                            label={
                              "7A. Does your LTC pharmacy have its own wholesaler customer number?"
                            }
                            component={FormRadioGroup}
                            data={optionsYesNo}
                            layout={"horizontal"}
                            validator={radioGroupValidator}
                            disabled={isReadOnly}
                          />
                        </div>
                        <div className="row">
                          <Field
                            id={"hasDeaNumber"}
                            name={"hasDeaNumber"}
                            label={
                              "7B. Does your LTC pharmacy have its own DEA number?"
                            }
                            component={FormRadioGroup}
                            data={optionsYesNo}
                            layout={"horizontal"}
                            validator={radioGroupValidator}
                            disabled={isReadOnly}
                          />
                        </div>
                        <div className="row">
                          <Field
                            id={"isSeparatedByFourWalls"}
                            name={"isSeparatedByFourWalls"}
                            label={
                              "7C. Is your pharmacy separated by four walls (floor to ceiling) from any other pharmacy or facility?"
                            }
                            component={FormRadioGroup}
                            data={optionsYesNo}
                            layout={"horizontal"}
                            validator={radioGroupValidator}
                            disabled={isReadOnly}
                          />
                        </div>
                        <div className="row mt-4 mb-2">
                          <h6>Please check the fields below that apply:</h6>
                        </div>
                        <div className="row">
                          <Field
                            id={"hasSeparateLicenseForEachPharmacy"}
                            name={"hasSeparateLicenseForEachPharmacy"}
                            label={
                              "7D. Are there separate pharmacy licenses for each pharmacy?"
                            }
                            component={FormRadioGroup}
                            data={optionsYesNo}
                            layout={"horizontal"}
                            validator={radioGroupValidator}
                            disabled={isReadOnly}
                          />
                        </div>
                        <div className="row">
                          <Field
                            id={"hasSeparateEntranceForEachPharmacy"}
                            name={"hasSeparateEntranceForEachPharmacy"}
                            label={
                              "7E. Are there separate outside entrances for each pharmacy?"
                            }
                            component={FormRadioGroup}
                            data={optionsYesNo}
                            layout={"horizontal"}
                            validator={radioGroupValidator}
                            disabled={isReadOnly}
                          />
                        </div>
                        <div className="row">
                          <Field
                            id={"hasPhysicalInventorySeparations"}
                            name={"hasPhysicalInventorySeparations"}
                            label={
                              "7F. Is there physical separation of all inventories, including controlled substances?(i.e., separate vaults, separate rooms,etc.)"
                            }
                            component={FormRadioGroup}
                            data={optionsYesNo}
                            layout={"horizontal"}
                            validator={radioGroupValidator}
                            disabled={isReadOnly}
                          />
                        </div>
                        {formRenderProps.valueGetter(
                          "hasPhysicalInventorySeparations"
                        ) &&
                          formRenderProps.valueGetter(
                            "hasPhysicalInventorySeparations"
                          ) && (
                            <div className="row">
                              <span className="ms-4">
                                <Field
                                  id={"physicalInventorySeparationDescriptions"}
                                  name={"physicalInventorySeparationDescriptions"}
                                  label={
                                    "7G. Please describe the physical separation:  *"
                                  }
                                  component={FormTextArea}
                                  validator={requiredValidator}
                                  rows="2"
                                  disabled={isReadOnly}
                                />
                              </span>
                            </div>
                          )}
                        <div className="row">
                          <Field
                            id={"associatedPharmacyiesDEANumber"}
                            name={"associatedPharmacyiesDEANumber"}
                            label={
                              "7H. DEA Number of Any Associated Retail Pharmacy:"
                            }
                            component={FormTextArea}
                            //validator={requiredValidator}
                            rows="2"
                            disabled={isReadOnly}
                          />
                        </div>
                      </>
                    )}
                </>
              )}
              {(additionalInfoCurrentworkflow?.ControlName == "HomeMedical") && (
                <>
               
                  <div class="row">
                    <Field
                      id={"isCompanyAccreditedvalue"}
                      name={"isCompanyAccreditedvalue"}
                      label={
                        "1. Is your company accredited?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>

                  {formRenderProps.valueGetter("isCompanyAccreditedvalue") &&
                    formRenderProps.valueGetter("isCompanyAccreditedvalue") && (
                      <div class="row">
                        <span className="ms-4">
                          <Field
                            id={"identifySourceOfAccreditationvalue"}
                            name={"identifySourceOfAccreditationvalue"}
                            label={
                              " 1A. Please identify the source of accreditation *"
                            }
                            component={FormInput}
                            validator={requiredValidator}
                            disabled={isReadOnly}
                          />
                        </span>
                      </div>
                    )}
                </>
              )}
              {(additionalInfoCurrentworkflow?.ControlName == "MediDist") && (
                <>
                 <div class="row">
                    <Field
                      id={"isCompanyProvidePedigree"}
                      name={"isCompanyProvidePedigree"}
                      label={
                        "1. Can your company provide Pedigree?"
                      }
                      component={FormRadioGroup}
                      data={optionsYesNo}
                      layout={"horizontal"}
                      validator={radioGroupValidator}
                      disabled={isReadOnly}
                    />
                  </div>

                  {formRenderProps.valueGetter("isCompanyProvidePedigree") &&
                    formRenderProps.valueGetter("isCompanyProvidePedigree") && (
                      <div class="row">
                        <span className="ms-4">
                          <Field
                            id={"identifySourceOfPedigreeValue"}
                            name={"identifySourceOfPedigreeValue"}
                            label={
                              " 1A. Please identify the source of Pedigree *"
                            }
                            component={FormInput}
                            validator={requiredValidator}
                            disabled={isReadOnly}
                          />
                        </span>
                      </div>
                    )}
                </>
              )}
            </FormElement>
          )}
        />
      )}
    </>
  );
};

export default AdditionalInfoGPO;
