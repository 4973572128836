import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  ShortDateFormat,
  StringToDate,
  DateWithoutTime,
} from "../../helpers/DateHelpers";

const PharmacyInfoInsurancePolicy = ({
  dataInsurancePolicy,
  setDataInsurancePolicy,
  failedSubmitWasPressed,
  dataInsurancePolicyValidated,
  editItem,
  clearEdit,
  isEditMode,
  setEdit,
  isReadOnly,
}) => {
  const {
    control,
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    if (!values || Object.keys(values).length === 0) return;

    let updatedInsurancePolicy;

    if (values.editId < 0) {
      //const maxLocalId = Math.max(...dataInsurancePolicy.map((t) => t.IdLocal));

      const maxLocalId =
        !dataInsurancePolicy || dataInsurancePolicy.length == 0
          ? 0
          : Math.max(...dataInsurancePolicy.map((t) => t.IdLocal));

      const newRecord = {
        IdLocal: maxLocalId + 1,
        carrier: values.editCarrier,
        policyNum: values.editPolicyNum,
        expiration: values.editExpiration,
      };

      updatedInsurancePolicy = [...dataInsurancePolicy, newRecord];
    } else {
      updatedInsurancePolicy = dataInsurancePolicy.map((item) => {
        if (item.IdLocal == values.editId)
          return {
            ...item,
            carrier: values.editCarrier,
            policyNum: values.editPolicyNum,
            expiration: values.editExpiration,
          };

        return item;
      });
    }

    turnAddingViewOff();
    reset();

    setDataInsurancePolicy(updatedInsurancePolicy);
  };

  const DeletedDataInsurancePolic = (id) => {
    const updatedDataPharmacist = dataInsurancePolicy.filter(
      (item) => item.IdLocal != id
    );

    setDataInsurancePolicy(updatedDataPharmacist);
  };

  const [addingView, setAddingView] = useState(false);

  const turnAddingViewOff = () => {
    unRegisterAll();
    clearEdit();
    setAddingView(false);
  };

  //If this is not done the useform hook will retain values and cause many unwanted effects
  //There may be a better way to do it but didn't find it yet
  const unRegisterAll = () => {
    unregister("editCarrier");
    unregister("editPolicyNum");
    unregister("editExpiration");
    unregister("editId");
  };

  const EditView = (id, carrier, policyNum, expiration) => {
    const processedDate = DateWithoutTime(
      expiration == "" ? new Date() : StringToDate(expiration)
    );

    return (
      <tr key={id}>
        <td>
          <input
            type="hidden"
            value={id}
            name="editId"
            {...register("editId")}
          />

          <input
            type="text"
            className="form-control form-control-sm uppercase"
            defaultValue={carrier}
            name="editCarrier"
            {...register("editCarrier", {
              required: "Value is required",
            })}
          />

          {errors.editCarrier && (
            <p className="text-danger">{errors.editCarrier.message} </p>
          )}
        </td>
        <td>
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            defaultValue={policyNum}
            name="editPolicyNum"
            {...register("editPolicyNum", {
              required: "Value is required",
            })}
          />

          {errors.editPolicyNum && (
            <p className="text-danger">{errors.editPolicyNum.message} </p>
          )}
        </td>
        <td>
          <Controller
            control={control}
            name="editExpiration"
            defaultValue={processedDate}
            {...register("editExpiration", {
              valueAsDate: true,
              required: "Not a valid date",
              validate: {
                isTodayOrLater: (i) => {
                  let enteredDate = StringToDate(i);
                  var currentDate = new Date();

                  return (
                    DateWithoutTime(enteredDate) >=
                      DateWithoutTime(currentDate) ||
                    "Cannot be earlier than today"
                  );
                },
              },
            })}
            render={({ field }) => (
              <DatePicker
                defaultValue={processedDate}
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />

          {errors.editExpiration && (
            <p className="text-danger">{errors.editExpiration.message} </p>
          )}
        </td>
        <td className="buttoncell">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
          <button
            className="btn btn-secondary ms-2"
            onClick={(e) => {
              e.preventDefault();

              reset();
              turnAddingViewOff();
            }}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>Insurance Policy *</h6>

        {failedSubmitWasPressed && !dataInsurancePolicyValidated && (
          <div className="text-danger">
            At least one record needs to be entered
          </div>
        )}

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Carrier</th>
              <th scope="col">Policy#</th>
              <th scope="col">Expiration Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataInsurancePolicy.map((t) => {
              return editItem.editName == "insurancepolicy" &&
                editItem.editId == t.IdLocal ? (
                EditView(editItem.editId, t.carrier, t.policyNum, t.expiration)
              ) : (
                <tr key={t.IdLocal}>
                  <td>{t.carrier}</td>
                  <td>{t.policyNum}</td>
                  <td>{ShortDateFormat(t.expiration)}</td>

                  {!isReadOnly && (
                    <td className="buttoncell">
                      <button
                        className="btn btn-primary"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          e.preventDefault();

                          setEdit("insurancepolicy", t.IdLocal);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          if (window.confirm("Are you sure?")) {
                            e.preventDefault();

                            DeletedDataInsurancePolic(t.IdLocal);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
            {addingView && EditView(-1, "", "", "")}
          </tbody>
        </table>

        <button
          className="btn btn-primary"
          disabled={isEditMode() || isReadOnly}
          onClick={(e) => {
            e.preventDefault();
            setEdit("insurancepolicy", -1);
            unRegisterAll();
            setAddingView(true);
          }}
        >
          Add New Policy
        </button>
      </form>
    </>
  );
};

export default PharmacyInfoInsurancePolicy;
