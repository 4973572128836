import React, { useState, useEffect } from "react";
import { validateLocaleAndSetLanguage } from "typescript";
import { BusinessInfoState } from "./BusinessInfoContext";

const BusinessInfoCotPercentages = (props) => {
  const {
    applicationId,
    targetCotPercentageData,
    setTargetCotPercentageData,
    failedSubmitWasPressed,
    cotTotalsValidationPassed,
    setCotTotalsValidationPassed,
    cotTotalsFailedMsg,
    setCotTotalsFailedMsg,
    cotTotalsOtherDescriptionEnteredValidationPassed,
    setCotTotalsOtherDescriptionEnteredValidationPassed,
  } = BusinessInfoState();

  const { disabled} = props

  const otherCotValue = 999;

  const [targetCOTotalPercentage, setTargetCOTotalPercentage] = useState({});

  const [otherCOTDescription, setOtherCOTDescription] = useState();

  useEffect(() => {
    calculateTargetCOTotalPercentage();
    setOtherCOTDescriptionValidator();
  }, [targetCotPercentageData]);

  useEffect(() => {
    //On initial load, set the otherCOT description value
    const thiCotPercentageValue = targetCotPercentageData.find(
      (t) => t.cot == otherCotValue
    );

    if (thiCotPercentageValue)
      otherCOTDescriptionChanged(thiCotPercentageValue.description);
  }, []);

  const setOtherCOTDescriptionValidator = () => {
    let isValid;
    const otherCotExists = targetCotPercentageData.some(
      (t) => t.cot == otherCotValue && t.percentage > 0
    );

    if (!otherCotExists) {
      isValid = true;
    } else {
      //Valid if there is a value greater than zero
      isValid =
        otherCOTDescription && otherCOTDescription.length > 0 ? true : false;
    }

    //Check if current validationPassed value already set to our isValid - if so no need tro do anything.
    if (cotTotalsOtherDescriptionEnteredValidationPassed == isValid) return;

    //If it gets here the validation value is not same as isValid - we need to set it
    setCotTotalsOtherDescriptionEnteredValidationPassed(isValid);
  };

  const otherCOTDescriptionChanged = (otherCOTDescriptionValue) => {
    setOtherCOTDescription(otherCOTDescriptionValue);

    let updated = targetCotPercentageData.map((item) => {
      if (item.cot == otherCotValue)
        return { ...item, description: otherCOTDescriptionValue };

      return item;
    });

    setTargetCotPercentageData(updated);
  };

  const calculateTargetCOTotalPercentage = () => {
    let total = targetCotPercentageData.reduce(
      (sum, item) => sum + Number(item.percentage),
      0
    );

    if (total == 100) setCotTotalsValidationPassed(true);
    else {
      setCotTotalsValidationPassed(false);
      setCotTotalsFailedMsg("Needs to add to a total of 100%");
    }

    setTargetCOTotalPercentage(total);
  };

  const getCotId = (input) => {
    const cotId = input.split("_").pop();

    return cotId;
  };

  const cotPercentageUpdated = (e) => {
    //let cotId = e.target.name.split("_").pop(); // => "Tabs1"

    const value = e.target.value;

    //This has to be a number
    if (isNaN(value)) return;

    const thisCotId = getCotId(e.target.name);

    let exists = targetCotPercentageData.some((t) => t.cot == thisCotId);

    if (!exists) {
      const newrecord = {
        applicationId: applicationId,
        cot: thisCotId,
        percentage: value,
      };

      let updatednewrecord = [...targetCotPercentageData, newrecord];
      setTargetCotPercentageData(updatednewrecord);

      return;
    }

    let updated = targetCotPercentageData.map((item) => {
      if (item.cot == thisCotId) return { ...item, percentage: value };

      return item;
    });

    setTargetCotPercentageData(updated);

    exists = targetCotPercentageData.some((t) => t.cot == otherCotValue);
    if (!exists) {
      setCotTotalsOtherDescriptionEnteredValidationPassed(true);
      return;
    }

    if (thisCotId == otherCotValue) {
      const hasValue = otherCOTDescription && otherCOTDescription.length > 0;
      setCotTotalsOtherDescriptionEnteredValidationPassed(hasValue);
    }
  };

  const getPercentageValueForCot = (cot) => {
    const thisCotValue = targetCotPercentageData.find((t) => t.cot == cot);
    if (!thisCotValue) return "";

    if (isNaN(thisCotValue.percentage)) return;
    if (thisCotValue.percentage < 1) return "";

    return thisCotValue.percentage;
  };

  return (
    <>
      <div className="mb-3 row">
        <h6>Please indicate an approximate percentage *</h6>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm">
          Assisted Living (%)
        </label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="assistedLivingPctg_40"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(40)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
            // onChange={(e) => {
            //   let updated = {
            //     ...targetCotPercentageData,
            //     assistedLivingPctg: e.target.value,
            //   };
            //   setTargetCotPercentageData(updated);
            // }}
          />
        </div>

        <label className="col-sm-2 col-form-label-sm">Nursing Home (%)</label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="nursingHomePctg_1"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(1)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm">
          Skilled Nursing (%)
        </label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="skilledNursingPctg_94"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(94)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
          />
        </div>

        <label className="col-sm-2 col-form-label-sm">Correctional (%)</label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="correctionalPctg_41"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(41)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm">Hospice (%)</label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="hospicePctg_12"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(12)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
          />
        </div>

        <label className="col-sm-2 col-form-label-sm">Other (%)</label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="otherPctg_999"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(999)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
          />

          {getPercentageValueForCot(999) > 0 && (
            <div className="col-sm-3 mt-2">
              <input
                type="text"
                name="otherDescription"
                placeholder="Other Description"
                value={otherCOTDescription}
                autoComplete="off"
                onChange={(e) => otherCOTDescriptionChanged(e.target.value)}
                disabled={disabled}
              />

              {failedSubmitWasPressed &&
                !cotTotalsOtherDescriptionEnteredValidationPassed && (
                  <div className="text-danger nowrap">Value is required</div>
                )}
            </div>
          )}
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm">ICF/IDD (%)</label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="mhrqPctg_113"
            placeholder=""
            autoComplete="off"
            value={getPercentageValueForCot(113)}
            onChange={cotPercentageUpdated}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="mb-4 row">
        <label className="col-sm-2 col-form-label-sm">Total (%) </label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            name="totalpctg"
            placeholder=""
            value={targetCOTotalPercentage}
            readOnly
          />
        </div>

        {failedSubmitWasPressed && !cotTotalsValidationPassed && (
          <div className="text-danger">{cotTotalsFailedMsg}</div>
        )}

        {targetCOTotalPercentage === 100 && (
          <div className="col-sm-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-hand-thumbs-up col-form-label-sm text-light bg-success"
              viewBox="0 0 16 16"
            >
              <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default BusinessInfoCotPercentages;
