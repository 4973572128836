import React, {useState} from 'react'
export function RadioGroup(props) {
    const [selected,setSelected] = useState(props.selectedRadio)
    

    return(
        <div className="mb-3">
            <label className="col-sm-3 col-form-label-sm" htmlFor={props.type}>
                {props.label}
            </label>
            <div className="form-check form-check-inline">
                <input 
                    className="form-check-input"
                    onChange={props.onRadioChange}
                    type="radio"
                    name={props.type}
                    id={`${props.type}-yes`}
                    value="1"
                    checked={props.selectedRadio === '1'}
                    disabled={props.readOnly}
                    required={props.required}


                />
               <label className="form-check-label" htmlFor={`${props.type}-yes`}>
                Yes
               </label>
            </div>
            <div className="form-check form-check-inline">
                <input 
                    className="form-check-input"
                    onChange={props.onRadioChange}
                    type="radio"
                    name={props.type}
                    id={`${props.type}-no`}
                    value="0"
                    checked={props.selectedRadio === '0'}
                    disabled={props.readOnly}
                    required={props.required}
                />
               <label className="form-check-label" htmlFor={`${props.type}-no`}>
                No
               </label>
            </div>
        </div>
    )
}