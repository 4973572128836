import { useParams } from 'react-router-dom';

import AlternateAddressForm from './AlternateAddressForm';

export default function AlternateNetworkAddress(props) {

    const params = useParams();

    return (
        <>
            <h4 className="text-start">Network Mailing Address</h4>
            <hr/>  
            <AlternateAddressForm addressType={3} id={params.id}/>
        </>
    )
}