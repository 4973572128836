import { DropDownList } from "@progress/kendo-react-dropdowns"


export function BooleanYesNoCell(props) {

    const {dataItem, field, onChange} = props

    const data = [
        { text: "Yes", value: true},
        { text: "No", value: false}
    ]

    function handleChange(e) {
        if(onChange) {
            onChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value
            })
        }
    }

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList 
                    style={{width: '90px'}}
                    onChange={handleChange}
                    value={data.find(x => x.value == dataItem[field])}
                    data={data}
                    textField="text" />
            ) : (
                dataItem[field] ? "Yes" : "No"
            )}
        </td>
    )
}

