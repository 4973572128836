import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { MemberApplicationContext } from "../context/MemberApplicationContext";
import logo from "./mha_logo.jpg";
import { GetApplicationType } from "../helpers/AppDataHelpers";

export default function Sidebar(props) {
  const memberApplication = useContext(MemberApplicationContext);
  const currentPath = useLocation();

  const getLinkClass = (linkPath) => {
    let selected = false;

    if (linkPath === "") {
      if (currentPath.pathname === "/") selected = true;
    } else if (currentPath.pathname.includes(linkPath)) selected = true;

    return "ms-1 d-none d-sm-inline ".concat(
      selected ? "selectedPageLink" : "text-white"
    );
  };

  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse"
    >
      <div className="position-sticky">
        <Link
          className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none"
          to={`/`}
        >
          <span className={getLinkClass("")}>Home</span>
        </Link>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/MemberService/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house "></i>{" "}
              <span className={getLinkClass("MemberService")}>
                Member Service
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/MemberSource/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("MemberSource")}>
                Member Source
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/MemberDemographics/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("MemberDemographics")}>
                Member Demographics
              </span>
            </Link>

            <ul className="nav flex-column ms-1">
              {memberApplication.hasAlternateCorporateAddress && (
                <li className="nav-item">
                  <Link
                    className="nav-link align-middle px-0"
                    to={`/MemberAlternateCorporateAddress/${
                      memberApplication.id ?? ""
                    }`}
                  >
                    <i className="fs-4 bi-house"></i>{" "}
                    <span
                      className={getLinkClass(
                        "MemberAlternateCorporateAddress"
                      )}
                    >
                      Corporate Contact Address
                    </span>
                  </Link>
                </li>
              )}
              {memberApplication.hasAlternateRebateAddress && (
                <li className="nav-item">
                  <Link
                    className="nav-link align-middle px-0"
                    to={`/MemberAlternateRebateAddress/${
                      memberApplication.id ?? ""
                    }`}
                  >
                    <i className="fs-4 bi-house"></i>{" "}
                    <span
                      className={getLinkClass("MemberAlternateRebateAddress")}
                    >
                      Rebate Mailing Address
                    </span>
                  </Link>
                </li>
              )}
              {memberApplication.hasAlternateNetworkAddress && (
                <li className="nav-item">
                  <Link
                    className="nav-link align-middle px-0"
                    to={`/MemberAlternateNetworkAddress/${
                      memberApplication.id ?? ""
                    }`}
                  >
                    <i className="fs-4 bi-house"></i>{" "}
                    <span
                      className={getLinkClass("MemberAlternateNetworkAddress")}
                    >
                      Network Mailing Address
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </li>

         
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/BusinessInfo/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("BusinessInfo")}>
                Business Information
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/PharmacyPrimaryContact/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("PharmacyPrimaryContact")}>
                Pharmacy / Provider Contact
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/PharmacyInfo/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("PharmacyInfo")}>
                Pharmacy / Provider Info
              </span>
            </Link>
          </li>
          {GetApplicationType(memberApplication.service) === "GPO" && (<li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/DistributorWholesaler/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("DistributorWholesaler")}>
                Wholesalers
              </span>
            </Link>
          </li>
          )}
          {
            memberApplication.additionalworkflow !=="Alt Site" && (
              <li className="nav-item">
              <Link
                className="nav-link align-middle px-0"
                to={`/AdditionalInformation/${memberApplication.id ?? ""}`}
              >
                <i className="fs-4 bi-house"></i>{" "}
                <span className={getLinkClass("AdditionalInformation")}>
                  Additional Member Information
                </span>
              </Link>
            </li>
            )
          }
          {
            <li className="nav-item">
              <Link  
                className="nav-link align-middle px-0"
                to={`/AdditionalOutputInfo/${memberApplication.id ?? ""}`}>
                <i className="fs-4 bi-house"></i>{" "}
                <span className={getLinkClass("AdditionalOutputInfo")}>
                  Additional Output Information
                  </span>
                </Link>
            </li>
          }
        
          <li className="nav-item">
            <Link
              className="nav-link align-middle px-0"
              to={`/CreatePacket/${memberApplication.id ?? ""}`}
            >
              <i className="fs-4 bi-house"></i>{" "}
              <span className={getLinkClass("CreatePacket")}>
                Create Packet
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="fixed-bottom" id="bottom-logo-wrapper">
        <img src={logo} alt="..." className="img-fluid" />
      </div>
    </nav>
  );
}
