import MemberDemographicsForm from './MemberDemographicsForm'
import { useParams } from 'react-router-dom';

export default function MemberDemographics(props) {

    const params = useParams();

    return (
        <>
            <h4 className="text-start">Member Demographics</h4>
            <hr/>  
            <MemberDemographicsForm id={params.id}/>
        </>
    )
}