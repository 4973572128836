import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect, useRef, useState,useContext } from "react";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { memberAPI } from "../../utility/axios";
import { CheckboxCell } from "./CheckboxCell";
import { SelectAllHeaderCell } from "./SelectAllHeaderCell";
import { Modal } from "bootstrap";
import LoadingPageItem from "../../components/LoadingPageItem";
import { businessworkflow as pageWorkflow } from "./../BusinessInfo/BusinessInfoWorkflow";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";

const editField = "inEdit";
const initialDataState = {
  skip: 0,
  take: 10,
};

export function ContractFormModal(props) {
  const { id, onSave, isReadOnly } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(initialDataState);
  const [isLoading, setIsLoading] = useState(true);

  let { authInfo } = useAzureAuthentication();

  const [modal, setModal] = useState(null);
  const modalRef = useRef();
  const [businessInfocurrentWorkflow, setbusinessInfocurrentWorkflow] = useState(null);
  const memberApplication = useContext(MemberApplicationContext);
  useEffect(() => {
    if (memberApplication.businessworkflow) {
      setbusinessInfocurrentWorkflow(pageWorkflow[memberApplication.businessworkflow]);
    }
    async function fetchContracts() {
      const res = await memberAPI.get("api/ContractForm/FetchContractForms", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });

      const data = res.data.map((item) => {
        const newItem = item;
        newItem.inEdit = true;
        return newItem;
      });

      setData(data);
      setIsLoading(false);

      const formIds = data
      .filter((x) => x.IsSelected)
      .map((item) => item.FormId)
      .join("|");

      onSave(formIds)
      setModal(new Modal(modalRef.current));
    }
    async function fetchContracts_AltSite() {
      const res = await memberAPI.get("api/ContractForm/FetchContractForms_AltSite", {
        params: {
          applicationid: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });

      const data = res.data.map((item) => {
        const newItem = item;
        newItem.inEdit = true;
        return newItem;
      });

      setData(data);
      setIsLoading(false);

      const formIds = data
      .filter((x) => x.IsSelected)
      .map((item) => item.FormId)
      .join("|");

      onSave(formIds)
      setModal(new Modal(modalRef.current));
    }
    if (authInfo){ 
      if (businessInfocurrentWorkflow?.ControlName == "LTCOmnicare") {
        fetchContracts();
      } else {
        fetchContracts_AltSite();
      }
    } 
  }, [authInfo,, memberApplication.businessworkflow]);

  function updateContractForm() {
    async function updateContracts() {
      const formIds = data
        .filter((x) => x.IsSelected)
        .map((item) => item.FormId)
        .join("|");

      await memberAPI.post(
        "api/ContractForm/UpdateContractForms",
        {
          user: authInfo.userEmail,
          applicationId: id,
          formIds: formIds,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
      onSave(formIds)

      modal.hide();
    }

    updateContracts();
  }

  function itemChange(event) {
    const field = event.field || "";
    let value = event.value ?? "";

    const newData = data.map((item) =>
      item.FormId === event.dataItem.FormId ? { ...item, [field]: value } : item
    );
    setData(newData);
  }

  function onSelectAll(e) {
    const checked = e.target.checked;
    const newData = data.map((item) => {
      const newItem = item;
      newItem.IsSelected = checked;
      return newItem;
    });
    setData(newData);
  }

  const MySelectAllHeaderCell = (props) => {
    return (
      <SelectAllHeaderCell
        {...props}
        onSelectAll={onSelectAll}
        checked={data.every((x) => x.IsSelected)}
      />
    );
  };
  return (
    <>
      <div
        className="modal fade"
        ref={modalRef}
        id="contract-grid-modal"
        tabIndex="-1"
        aria-hidden="true"
        aria-labelledby="contract-grid-modal-label"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">Contract Forms</div>
            <div className="modal-body">
              {isLoading ? (
                <LoadingPageItem />
              ) : (
                <Grid
                  data={data.slice(page.skip, page.take + page.skip)}
                  skip={page.skip}
                  take={page.take}
                  total={data.length}
                  pageable
                  onPageChange={(e) => setPage(e.page)}
                  editField={editField}
                  editable
                  onItemChange={itemChange}
                >
                  <GridColumn field="FormId" title="Form ID" editable={false} />
                  <GridColumn field="FormName" title="Name" editable={false} />
                  {!isReadOnly && (<GridColumn
                    field="IsSelected"
                    title="Is Selected"
                    cell={CheckboxCell}
                    headerCell={MySelectAllHeaderCell}
                    editable
                  />)}
                </Grid>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {!isReadOnly && (<button
                type="button"
                onClick={updateContractForm}
                className="btn btn-primary"
              >
                Save Changes
              </button>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
