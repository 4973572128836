import "./LTCViewMessageStyle.css";

export function LTCViewMessage() {
  return (
    <div className="card ltcPacketMessageStyle">
      <div className="card-body">
        <h5 className="card-title">LTC Network Packet</h5>

        <p className="card-text fs-5 mt-3">
          There is no contracts selector for this type of member application.
        </p>

        <p className="card-text fs-5 mb-3">
          The Docusign packet generated will consist of <u>active</u>{" "}
          <strong>ExtraDocs</strong> templates which have been configured for a{" "}
          <strong>Service</strong> type of <strong>LTC Network</strong>.
        </p>
      </div>
    </div>
  );
}

export default LTCViewMessage;
