

export function NetworkInvoiceMailingAddress(props) {

    return props.service === 2 && (
        <div className="form-check">
            <input className="form-check-input" type="checkbox" disabled={props.disabled} value="" onChange={props.onChange} id="invoiceMailingAddressCheck" checked={props.hasNetworkMailingAddress}/>
            <label className="form-check-label" htmlFor="invoiceMailingAddressCheck">
              Network Invoice Mailing Address
            </label>
          </div>
    )
}