import React, { useEffect } from "react";

import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";

import { TextArea, RadioGroup } from "@progress/kendo-react-inputs";

import { Label, Hint, Error } from "@progress/kendo-react-labels";

const AdditionalInfoNetwork = ({
  ltcNetworkData,
  setLtcNetworkData,
  optionsYesNo,
  wasSubmitted,
  setPostedDetails,
  isReadOnly
}) => {
  const radioGroupValidator = (value) =>
    value == null ? "Selection is required" : "";

  const textAreaValidator = (value) => (!value ? "Value is required" : "");

  const FormRadioGroup = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      id,
      label,
      layout,
      valid,
      disabled,
      hint,

      ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    return (
      <FieldWrapper>
        <Label
          id={labelId}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <RadioGroup
          id={id}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ariaLabelledBy={labelId}
          valid={valid}
          disabled={disabled}
          layout={layout}
          {...others}
        />
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    );
  };

  const FormTextArea = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <TextArea
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            rows={3}
            ariaDescribedBy={`${hindId} ${errorId}`}
            {...others}
          />
          {showHint && <Hint id={hindId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    );
  };

  const handleSubmit = (dataItem) => {
    setLtcNetworkData(dataItem);
  };

  const AutoSubmitter = (formRenderProps) => {
    // const isSubmittedRef = React.useRef(false);
    useEffect(() => {
      const { valid, modified, onSubmit } = formRenderProps;

      if (!wasSubmitted) {
        return;
      }

      onSubmit();

      const postDetails = {
        wasUpdated: modified,
        isValid: valid,
      };

      setPostedDetails(postDetails);
    });
    return [];
  };

  return (
    <>
      {ltcNetworkData && (
        <Form
          initialValues={{
            pharmacyDeniedLicense: ltcNetworkData.wasDeniedLicense,
            pharmacyDeniedLicenseExplanation:
              ltcNetworkData.deniedLicenseExplanation,
            pharmacyCriminalInfo: ltcNetworkData.hasCriminalInfo,
            pharmacyCriminalInfoExplanation:
              ltcNetworkData.criminalInfoExplanation,
            pharmacyDisciplanaryInfo: ltcNetworkData.hasDisciplinaryAction,
            pharmacyDisciplanaryInfoExplanation:
              ltcNetworkData.disciplinaryActionExplanation,
          }}
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 650,
              }}
            >
              <AutoSubmitter {...formRenderProps} />
              <div className="row mt-4">
                <Field
                  id={"pharmacyDeniedLicense"}
                  name={"pharmacyDeniedLicense"}
                  label={
                    "1. Has Participating Pharmacy(s) ever been denied a permit or pharmacy license in any state, or had its permit or license revoked or suspended?"
                  }
                  component={FormRadioGroup}
                  data={optionsYesNo}
                  layout={"horizontal"}
                  validator={radioGroupValidator}
                  disabled={isReadOnly}
                />
                {formRenderProps.valueGetter("pharmacyDeniedLicense") && (
                  <span className="ms-3">
                    <Field
                      id={"pharmacyDeniedLicenseExplanation"}
                      name={"pharmacyDeniedLicenseExplanation"}
                      // label={"Address"}
                      hint={
                        " 1A. Please explain the denial, revocation or suspension. *"
                      }
                      component={FormTextArea}
                      validator={textAreaValidator}
                      disabled={isReadOnly}
                    />
                  </span>
                )}
              </div>
              <div className="row mt-4">
                <Field
                  id={"pharmacyCriminalInfo"}
                  name={"pharmacyCriminalInfo"}
                  label={
                    "2. Has the Participating Pharmacy(s) or any of its present owners, employees or officers ever been charged with a criminal offense involving government business or has the Participating Pharmacy(s) or any of its present owners, employees or officers ever been convicted of federal or state drug or pharmacy service-related law convictions?"
                  }
                  component={FormRadioGroup}
                  data={optionsYesNo}
                  layout={"horizontal"}
                  validator={radioGroupValidator}
                  disabled={isReadOnly}
                />
                {formRenderProps.valueGetter("pharmacyCriminalInfo") && (
                  <span className="ms-3">
                    <Field
                      id={"pharmacyCriminalInfoExplanation"}
                      name={"pharmacyCriminalInfoExplanation"}
                      hint={
                        " 2A. Please explain the charge(s) or conviction(s). *"
                      }
                      component={FormTextArea}
                      validator={textAreaValidator}
                      disabled={isReadOnly}
                    />
                  </span>
                )}
              </div>

              <div className="row mt-4">
                <Field
                  id={"pharmacyDisciplanaryInfo"}
                  name={"pharmacyDisciplanaryInfo"}
                  label={
                    "3. Has the Participating Pharmacy(s) ever been the subject to any outstanding regulatory or disciplinary action by either State,Federal, Government or civil entities or disciplinary action in front of the State Board of Pharmacy?"
                  }
                  component={FormRadioGroup}
                  data={optionsYesNo}
                  layout={"horizontal"}
                  validator={radioGroupValidator}
                  disabled={isReadOnly}
                />
                {formRenderProps.valueGetter("pharmacyDisciplanaryInfo") && (
                  <span className="ms-3">
                    <Field
                      id={"pharmacyDisciplanaryInfoExplanation"}
                      name={"pharmacyDisciplanaryInfoExplanation"}
                      hint={
                        " 3A. Please explain the reasoning and circumstances of the regulatory or disciplinary action."
                      }
                      component={FormTextArea}
                      validator={textAreaValidator}
                      disabled={isReadOnly}
                    />
                  </span>
                )}
              </div>
            </FormElement>
          )}
        />
      )}
    </>
  );
};

export default AdditionalInfoNetwork;
