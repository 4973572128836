export function DEADateInput (props) {
    const {value, onChange, workflow, disabled, hasDEA} = props
    const {show, required} = workflow ?? {}
    if (!show) {
      return null;
    }
    return show && (
        <>
            <label className="col-sm-2 col-form-label-sm" htmlFor="deaExp">
      DEA Expiration Date
        </label>
        <div className="col-sm-3">
      <input
            value={value}
            onChange={onChange}
            type="date"
            id="deaExpdate"
            placeholder="DEA Expiration Date"
            className="form-control form-control-sm"
            required = {required && hasDEA}
            disabled={disabled}
            onFocus={(e) => (e.target.type = "date")}
        onBlur={(e) => (e.target.type = "text")}
                      />
           <div className="invalid-feedback">Please select DEA Expiration Date </div>
      </div>
        </>
    )
}