import React from "react";
import { useParams } from "react-router";
import BusinessInfoForm from "./BusinessInfoForm";

import BusinessInfoContext from "./BusinessInfoContext";

const BusinessInfo = () => {
  const params = useParams();

  return (
    <>
      <h4 className="text-start">Business Information</h4>
      <hr />
      <BusinessInfoContext>
        <BusinessInfoForm id={params.id} />
      </BusinessInfoContext>
    </>
  );
};

export default BusinessInfo;
