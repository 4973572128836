import React, { useState, useEffect } from "react";
import { BusinessInfoState } from "./BusinessInfoContext";

import { ParseTime } from "../../helpers/DateHelpers";

import BusinessInfoHoursOfOperationDaily from "./BusinessInfoHoursOfOperationDaily";

const BusinessInfoHoursOfOperation = (props) => {
  //const [isAllDayOperation, setisAllDayOperation] = useState(true);

  const {
    rootBusinessInfoApplicationData,
    setRootBusinessInfoApplicationData,
    dailyHoursOfOperation,
    failedSubmitWasPressed,
    setHoursOfOperationValidationPassed,
    hoursOfOperationValidationPassed,
    newItemFailMsg,
    setNewItemFailMsgFailMsg,
  } = BusinessInfoState();

  const {disabled} = props
  const allDayOperation = Boolean(
    rootBusinessInfoApplicationData.isAllDayOperation
  );

  useEffect(() => {
    SetValidation();
  }, [rootBusinessInfoApplicationData, dailyHoursOfOperation]);

  const dailyHoursTimeValidationPassed = (hoursOfOperation) => {
    const adjustMinute = (min) => {
      if (Number(min) == 0) return "00";

      return String(min);
    };

    const fromTimeFullString = `${hoursOfOperation.fromHour}:${adjustMinute(
      hoursOfOperation.fromMin
    )} ${hoursOfOperation.fromAmPm}`;
    const toTimeFullString = `${hoursOfOperation.toHour}:${adjustMinute(
      hoursOfOperation.toMin
    )} ${hoursOfOperation.toAmPm}`;

    const fromTimeSeconds = ParseTime(fromTimeFullString).getTime();
    const toTimeSeconds = ParseTime(toTimeFullString).getTime();

    return toTimeSeconds >= fromTimeSeconds;
  };

  const SetValidation = () => {
    if (allDayOperation) {
      setHoursOfOperationValidationPassed(true);
      return;
    }

    const hasActiveDay = dailyHoursOfOperation.some((t) => t.isActive);

    if (hasActiveDay) {
      //still need to validate active day
      let hasFailedHoursValidation = dailyHoursOfOperation.some(
        (t) => t.isActive & !dailyHoursTimeValidationPassed(t)
      );

      if (hasFailedHoursValidation) {
        setHoursOfOperationValidationPassed(false);
        setNewItemFailMsgFailMsg("Correct invalid time value");
        return;
      }

      setHoursOfOperationValidationPassed(true);
    } else {
      setHoursOfOperationValidationPassed(false);
      setNewItemFailMsgFailMsg("At least one day needs to be selected");

      //setHoursOfOperationFailedMsg("At least one day needs to be selected");
    }
  };

  const updateAllDayOperationValue = (value) => {
    let updated = {
      ...rootBusinessInfoApplicationData,
      isAllDayOperation: value,
    };

    //If isAllDayOperation is true, no need to validate anything else so set the validator to true

    setRootBusinessInfoApplicationData(updated);
  };

  // useEffect(() => {}, [rootBusinessInfoApplicationData]);

  return (
    <div>
      <h5>Hours Of Operation *</h5>
      24 hour operation
      <input
        className="form-check-input me-1 ms-3"
        type="checkbox"
        value={allDayOperation}
        checked={allDayOperation}
        aria-label="..."
        disabled={disabled}
        onChange={(e) => {
          updateAllDayOperationValue(e.target.checked);
        }}
      />
      {!allDayOperation && (
        <BusinessInfoHoursOfOperationDaily
          key="BusinessInfoHoursOfOperationDailyId"
          dailyHoursOfOperation={dailyHoursOfOperation}
          disabled={disabled}
        />
      )}
      {failedSubmitWasPressed && !hoursOfOperationValidationPassed && (
        <div className="text-danger">{newItemFailMsg}</div>
      )}
    </div>
  );
};

export default BusinessInfoHoursOfOperation;
