export function NcpdpInput (props) {
    const {value, onChange, workflow, disabled} = props
    const {required} = workflow ?? {}
    return (
        <>
            <label className="col-sm-2 col-form-label-sm" htmlFor="ncpdp">
             NCPDP
            </label>
            <div className="col-sm-3">
                <input
                    value={value}
                    onChange={onChange}
                    type="text"
                    className="form-control form-control-sm"
                    id="ncpdp"
                    placeholder="NCPDP"
                    minLength="7"
                    maxLength="7"
                    pattern="[0-9]+"
                    required={required}
                    disabled={disabled}
                />
                <div className="invalid-feedback">Please enter a valid NCPDP.</div>
            </div>
        </>
    )
}