import React from "react";
import { useParams } from "react-router";
import AdditionalOutputInfoForm from "./AdditionalOutputInfoForm";

import AdditionalOutputInfoContext from "./AdditionalOutputInfoContext";

const AdditionalOutputInfo = () => {
  const params = useParams();

  return (
    <>
      <h4 className="text-start">Additional Output Information</h4>
      <hr />
      {/* <AdditionalOutputInfoContext> */}
        <AdditionalOutputInfoForm id={params.id} />
      {/* </AdditionalOutputInfoContext> */}
    </>
  );
};

export default AdditionalOutputInfo;
