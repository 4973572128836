const GetApplicationType = (service) => {
  if (!service) return null;

  if (service == 2) return "LTC";

  if (service == 1) return "GPO";

  throw `Invalid service value of ${service} argument was passed - valid values are 1 or 2`;
};

const getWorkFlow = (service, classOfTrade) => {
  if (!service) {
    return null;
  }

  // LTC Network service
  if (service == 2) {
    return "LTC Network";
  }

  // GPO service
  if (service == 1) {
    // GPO -  LTC/Nursing Home Provider or Omnicare
    // HME - HME-MED
    // Medical Distributor - Medical Distributor
    if (classOfTrade == 1 || classOfTrade == 88) {
      return "GPO";
    }
    // else if (classOfTrade == 114) {
    //   return "HME";
    // }
    else if (classOfTrade == 108) {
      return "Medical Distributor";
    } else if (classOfTrade == 107) {
      return "Home Medical Equipment";
    } else {
      return "Alt Site";
    }
  }
  throw new Error(
    `Invalid service value of ${service} argument was passed - valid values are 1 or 2`
  );
};

//MOA-85  Adding logic to identify Alt-Site specific workflow logic and the programs that available for this workflow and also which are required to be selected
const IsAltSiteWorkFlow = (service, classOfTrade) => {
  let workflow = getWorkFlow(service, classOfTrade);
  //return true if workflow is Medical Distributor, Home Medical Equipment, or Alt Site
  return (
    workflow == "Medical Distributor" ||
    workflow == "Home Medical Equipment" ||
    workflow == "Alt Site"
  );
};

const AltSiteProgramOptions = () => {
  //return a json array of ProgramIds for this workflow and a true/false value whether they are required (forced) to be selected
  //If the program is not in this list it will not be available for selection

  return [
    { ProgramId: 1, IsRequired: false }, //Home Medical Equipment
    { ProgramId: 2, IsRequired: false }, //Medical/Surgical
    { ProgramId: 5, IsRequired: true }, //Capital Equipment
    { ProgramId: 7, IsRequired: false }, //IVIG
    { ProgramId: 15, IsRequired: true }, //BUSINESS PRODUCT SERVICES
    { ProgramId: 52, IsRequired: false }, //INFUSION SERVICES
  ];
};

const IsPacketSent = (docusignId) => {
  return docusignId !== null;
};

export {
  GetApplicationType,
  IsPacketSent,
  getWorkFlow,
  IsAltSiteWorkFlow,
  AltSiteProgramOptions,
};
