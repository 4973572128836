import { memberAPI } from "../../utility/axios";
import { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import LoadingPageItem from "../../components/LoadingPageItem";

import ClassOfTradeSelector from "./ClassOfTradeSelector";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import MemberServiceExpansion from "./MemberServiceExpansion";
import { MemberServiceState } from "./MemberServiceContext";

import { IsAltSiteWorkFlow } from "../../helpers/AppDataHelpers";

export default function MemberServiceform(props) {
  const {
    targetTypeData,
    setTargetTypeData,
    ExpValidationPassed,
    setExpValidationPassed,
    selectedClassOfTrade,
    setSelectedClassOfTrade,
    selectedType,
    setselectedType,
    memAppProgram,
    setmemAppProgram,
  } = MemberServiceState();

  const { id } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [showClassOfTrades, setShowClassOfTrades] = useState(true);
  //const [selectedClassOfTrade, setSelectedClassOfTrade] = useState("");
  const [selectedService, setSelectedService] = useState(1);
  // const [selectedType, setselectedType] = useState(1);
  const [isValidated, setIsValidated] = useState(false);

  const [altSite, setAltSite] = useState(false);

  const memberApplication = useContext(MemberApplicationContext);
  const history = useHistory();
  const formRef = useRef();

  let { authInfo } = useAzureAuthentication();

  function routeChange(path, id) {
    if (path) {
      history.push(`${path ?? ""}/${id ?? ""}`);
    } else {
      history.push(`${path ?? ""}`);
    }
  }

  useEffect(() => {
    if (id) {
      if (id !== memberApplication.id) {
        memberApplication.onIDChange(id);
      }
      if (authInfo) {
        //if(onpageload === 1)
        initService();
      }
    }
  }, [authInfo]);

  useEffect(() => {
    if (memberApplication.id) {
      if (authInfo) {
        initService();
      }
    }
  }, [memberApplication.id]);

  //add a useeffect that always runs
  // useEffect(() => {
  //   alert("SelectedServiceChanged:" + selectedService);
  //   //console.log(memberApplication);
  // }, [selectedService]);

  //true if selected class of trade is a number greater than zero
  const isCotSelected = () => selectedClassOfTrade > 0;

  useEffect(() => {
    let isAltSite = IsAltSiteWorkFlow(selectedService, selectedClassOfTrade);
    setAltSite(isAltSite);

    setIsLoading((prev) => false);
  }, [selectedService, selectedClassOfTrade]);

  function initService() {
    // if id is undefined, then we are creating a new application
    if (!id) {
      return;
    }

    async function fetchService() {
      const res = await memberAPI.get("api/MemApp/FetchMemberAppService", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });

      const data = res.data[0];
      setSelectedService(data.ServiceID);
      setSelectedClassOfTrade(data.ClassOfTradeID);
      setShowClassOfTrades(data.ClassOfTradeID !== null);
      if (data.Type == undefined) setselectedType(1);
      else setselectedType(data.Type);
    }
    async function fetchService_Option() {
      setIsLoading((prev) => true);
      const res = await memberAPI.get(
        "api/MemApp/FetchMemberAppService_Option",
        {
          params: {
            id: id,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      const data = res.data;

      let memberService_option;
      if (!data) {
        memberService_option = {
          ApplicationID: 0,
          ClassOfTradeID: 0,
          Type: 1,
          TypeId: 0,
        };
      } else {
        memberService_option = data.map((item) => {
          return {
            ApplicationID: item.ApplicationID,
            ClassOfTradeID: item.ClassOfTradeID,
            Type: item.Type,
            TypeId: item.TypeId,
          };
        });
      }

      setTargetTypeData(memberService_option);

      setIsLoading((prev) => false);
    }

    fetchService();
    fetchService_Option();
  }

  const setExpPassedValidator = (list) => {
    if (
      altSite
      // selectedClassOfTrade != 1 &&
      // selectedClassOfTrade != 88 &&
      // selectedClassOfTrade != undefined &&
      // selectedClassOfTrade != ""
    ) {
      if (list.length > 0) {
        setExpValidationPassed(true);
        return true;
      } else {
        setExpValidationPassed(false);
        return false;
      }
    } else {
      setExpValidationPassed(true);
      return true;
    }
  };

  function onServiceChange(e) {
    const value = parseInt(e.target.value);
    if (value === 2) {
      setSelectedClassOfTrade(null);
    }
    setSelectedService(value);
    setShowClassOfTrades(value === 1);
  }

  function onClassOfTradeChange(e) {
    const value = parseInt(e.target.value);
    setSelectedClassOfTrade(value);

    let results = targetTypeData.map((x) => {
      return {
        ...x,
        ClassOfTradeID: value,
      };
    });
    setTargetTypeData(results);
  }

  function onBackClick(e) {
    e.preventDefault();
    routeChange();
  }
  function onNextClick(e) {
    e.preventDefault();

    if (memberApplication.isReadOnly) {
      //routeChange("/MemberDemographics", id);
      routeChange("/MemberSource", id);
    }

    async function updateService() {
      let MemberServiceOption = targetTypeData.map((item) => {
        return {
          ApplicationID: Number(item.ApplicationID),
          ClassOfTradeID: Number(item.ClassOfTradeID),
          Type: Number(item.Type),
          TypeId: Number(item.TypeId),
          ModifiedByUser: authInfo.userEmail,
        };
      });
      await memberAPI.post(
        "api/MemApp/UpdateMemberApplicationService",
        {
          user: authInfo.userEmail,
          classOfTrade: selectedClassOfTrade,
          service: selectedService,
          type: selectedType,
          id: id,
          memberServiceOption: MemberServiceOption,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
      //console.log(selectedService)
      memberApplication.onClassOfTradeChange(selectedClassOfTrade);
      memberApplication.onServiceChange(selectedService);
      //memberApplication.onTypeChange(selectedType);
      routeChange("/MemberSource", id);
    }

    async function insertMemberApp() {
      let MemberServiceOption = targetTypeData.map((item) => {
        return {
          ApplicationID: Number(item.ApplicationID),
          ClassOfTradeID: Number(item.ClassOfTradeID),
          Type: Number(item.Type),
          TypeId: Number(item.TypeId),
          ModifiedByUser: authInfo.userEmail,
        };
      });

      const res = await memberAPI.post(
        "api/MemApp/InsertMemberApplication",
        {
          user: authInfo.userEmail,
          classOfTrade: selectedClassOfTrade,
          service: selectedService,
          type: selectedType,
          memberServiceOption: MemberServiceOption,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );

      const id = res.data;
      memberApplication.onIDChange(id);
      history.replace(`/MemberService/${id}`);
      routeChange("/MemberSource", id);
    }

    setIsValidated(true);

    if (
      formRef.current.checkValidity() &&
      setExpPassedValidator(targetTypeData)
    ) {
      if (id) {
        updateService();
      } else {
        insertMemberApp();
      }
    }
  }

  function onResetClick(e) {
    e.preventDefault();

    initService();
  }

  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <form
      ref={formRef}
      className={isValidated ? "was-validated" : ""}
      noValidate
    >
      <div className="mb-3 row">
        <div className="row">
          <label className="col-sm-1">Service</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            onChange={onServiceChange}
            type="radio"
            name="service"
            id="gpo"
            value="1"
            checked={selectedService === 1}
            disabled={memberApplication.isReadOnly}
          />
          <label className="form-check-label" htmlFor="gpo">
            GPO
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            onChange={onServiceChange}
            type="radio"
            name="service"
            id="ltc"
            value="2"
            checked={selectedService === 2}
            disabled={memberApplication.isReadOnly}
          />
          <label className="form-check-label" htmlFor="ltc">
            LTC Network
          </label>
        </div>
      </div>

      <div
        className="modal fade"
        id="confirmModal"
        tabIndex="-1"
        aria-labelledby="confirmModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="confirmModalLabel">
                Confirm Save
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Any data that was entered on a subsequent screen(s) that is not
              applicable for the newly selected COT/Service will be discarded
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={onNextClick}
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <ClassOfTradeSelector
        disabled={memberApplication.isReadOnly}
        show={showClassOfTrades}
        selectedClassOfTrade={selectedClassOfTrade}
        onChange={onClassOfTradeChange}
      />

      {altSite && isCotSelected() && (
        // selectedClassOfTrade != 1 &&
        //   selectedClassOfTrade != 88 &&
        //   selectedClassOfTrade != undefined &&
        //   selectedClassOfTrade != ""
        <MemberServiceExpansion disabled={memberApplication.isReadOnly} />
      )}
      <hr />
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          onClick={onResetClick}
          disabled={memberApplication.isReadOnly}
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        {id && !memberApplication.isReadOnly ? (
          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#confirmModal"
            onClick={(e) => e.preventDefault()}
          >
            Next
          </button>
        ) : (
          <button className="btn btn-primary" onClick={onNextClick}>
            Next
          </button>
        )}
      </div>
    </form>
  );
}
