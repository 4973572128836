import { useEffect, useState } from "react";

import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";

export function RepSelector(props) {
  const [reps, setReps] = useState([]);

  let { authInfo } = useAzureAuthentication();

  useEffect(() => {
    async function fetchReps() {
      const res = await memberAPI.get(`api/MemAppAdditionalOuputInfo/FetchReps`, {
        params: {
          type: props.type,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setReps(res.data);
    }

    if (authInfo) fetchReps();
  }, [authInfo]);

  return (
    <div className="mb-3 row">
      <label className="col-sm-2 col-form-label-sm" htmlFor={props.id}>
        {props.label}
      </label>
      <div className="col-sm-3">
        <select
          id={props.id}
          onChange={props.onChange}
          className="form-select form-select-sm"
          value={props.selectedRep}
          required = {props.required}
          disabled={props.disabled}
        >
          <option value="">{"<--Not Selected-->"}</option>
          {reps.map((rep, i) => {
            return (
              <option key={i} value={rep.SEID}>
                {rep.DisplayName}
              </option>
            );
          })}
        </select>
        <div className="invalid-feedback">Please select a {props.label}</div>
      </div>
    </div>
  );
}
