import { useEffect, useState } from "react";

import { memberAPI } from "../utility/axios";
import useAzureAuthentication from "../hooks/useAzureAuthentication";

export function StateSelector(props) {
  const [states, setStates] = useState([]);

  let { authInfo } = useAzureAuthentication();

  useEffect(() => {
    async function fetchStates() {
      const res = await memberAPI.get("api/State/FetchStates", {
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setStates(res.data);
    }

    if (authInfo) fetchStates();
  }, [authInfo]);

  return (
    <div className="mb-3 row">
      <label className="col-sm-2 col-form-label-sm" htmlFor="state">
        {props.Statetype}State
      </label>
      <div className="col-sm-3">
        <select
          id="state"
          onChange={props.onChange}
          className="form-select form-select-sm"
          value={props.selectedState}
          required = {props.required}
          disabled={props.disabled}
        >
          <option value="">{"<--Not Selected-->"}</option>
          {states.map((state, i) => {
            return (
              <option key={i} value={state.STATECODE}>
                {state.STATENAME}
              </option>
            );
          })}
        </select>
        <div className="invalid-feedback">Please select a State.</div>
      </div>
    </div>
  );
}
