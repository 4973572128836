import React from "react";
import {
  DailyTimeOptionHours,
  DailyTimeOptionMinutes,
  DailyTimeOptionAmPm,
} from "./BusinessInfoConstants";

import { ParseTime } from "../../helpers/DateHelpers";

// import { ParseTime } from "../../helpers/DateHelpers";

const BusinessInfoHoursOfOperationDailyTimes = ({
  HoursOfOperation,
  updateDailyHoursOfOperation,
  disabled
}) => {
  const dailyHoursTimeValidationPassed = (hoursOfOperation) => {
    const adjustMinute = (min) => {
      if (Number(min) == 0) return "00";

      return String(min);
    };

    const fromTimeFullString = `${hoursOfOperation.fromHour}:${adjustMinute(
      hoursOfOperation.fromMin
    )} ${hoursOfOperation.fromAmPm}`;
    const toTimeFullString = `${hoursOfOperation.toHour}:${adjustMinute(
      hoursOfOperation.toMin
    )} ${hoursOfOperation.toAmPm}`;

    const fromTimeSeconds = ParseTime(fromTimeFullString).getTime();
    const toTimeSeconds = ParseTime(toTimeFullString).getTime();

    return toTimeSeconds >= fromTimeSeconds;
  };

  //console.log(timeValidationPassed());

  return (
    <>
      <div className="row ms-5 g-0">
        <div className="col-1">From</div>
        <div className="col-2">
          <select
            className="form-select"
            value={HoursOfOperation.fromHour}
            onChange={(e) => {
              let updated = {
                ...HoursOfOperation,
                fromHour: e.target.value,
              };
              updateDailyHoursOfOperation(updated, "fromHour");
            }}
            disabled={disabled}
          >
            {DailyTimeOptionHours.map((item) => (
              <option key={item.value} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
        </div>

        <div className="col-2">
          <select
            className="form-select"
            value={HoursOfOperation.fromMin}
            onChange={(e) => {
              let updated = {
                ...HoursOfOperation,
                fromMin: e.target.value,
              };
              updateDailyHoursOfOperation(updated, "fromMin");
            }}
            disabled={disabled}
          >
            {DailyTimeOptionMinutes.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-2">
          <select
            className="form-select"
            value={HoursOfOperation.fromAmPm}
            onChange={(e) => {
              let updated = {
                ...HoursOfOperation,
                fromAmPm: e.target.value,
              };
              updateDailyHoursOfOperation(updated, "fromAmPm");
            }}
            disabled={disabled}
          >
            {DailyTimeOptionAmPm.map((item) => (
              <option key={item.value} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row ms-5 g-0">
        <div className="col-1">To</div>
        <div className="col-2">
          <select
            className="form-select"
            value={HoursOfOperation.toHour}
            onChange={(e) => {
              let updated = {
                ...HoursOfOperation,
                toHour: e.target.value,
              };
              updateDailyHoursOfOperation(updated, "toHour");
            }}
            disabled={disabled}
          >
            {DailyTimeOptionHours.map((item) => (
              <option key={item.value} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
        </div>

        <div className="col-2">
          <select
            className="form-select"
            value={HoursOfOperation.toMin}
            onChange={(e) => {
              let updated = {
                ...HoursOfOperation,
                toMin: e.target.value,
              };
              updateDailyHoursOfOperation(updated, "toMin");
            }}
            disabled={disabled}
          >
            {DailyTimeOptionMinutes.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-2">
          <select
            className="form-select"
            value={HoursOfOperation.toAmPm}
            onChange={(e) => {
              let updated = {
                ...HoursOfOperation,
                toAmPm: e.target.value,
              };
              updateDailyHoursOfOperation(updated, "toAmPm");
            }}
            disabled={disabled}
          >
            {DailyTimeOptionAmPm.map((item) => (
              <option key={item.value} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!dailyHoursTimeValidationPassed(HoursOfOperation) && (
        <div className="row ms-5 g-0">
          <div className="col text-danger">From time is after To time.</div>
        </div>
      )}
    </>
  );
};

export default BusinessInfoHoursOfOperationDailyTimes;
