export const additionalworkflow = {
    GPO: {
     ControlName : "LTCOmnicare",
     Condition : "DisplayAdditionalQuestion"
    },
    "Home Medical Equipment": {
      ControlName : "HomeMedical",
      Condition : "DonotDisplayAdditionalQuestion"
    },
    "Medical Distributor": {
      ControlName : "MediDist",
      Condition : "DonotDisplayAdditionalQuestion"
    },
    "Alt Site": {
      ControlName : "Alt",
      Condition : "DisplayAdditionalQuestion"
    },
    "LTC Network": {
      ControlName : "LTCNetwork",
      Condition : "DisplayAdditionalQuestion"
    }
  };