const BusinessInfoMasterData = {
  applicationId: 0,
  numberBedsAndPharmacyServices: 0,
  numberFacilitiesAndPharmacyServices: 0,
  isAllDayOperation: true,
};

const BusinessInfoTargetCOTData = {
  applicationId: 0,
  assistedLivingPctg: 0,
  nursingHomePctg: 0,
  skilledNursingPctg: 0,
  correctionalPctg: 0,
  hospicePctg: 0,
  otherPctg: 0,
  mhrqPctg: 0,
};

const BusinessInfoSpokenLanguages = [
  {
    applicationId: 0,
    id: 1, // Need this ID value in case they want to be able to enter mumtiple other spoken languages - without this ID we can only have 1
    languageId: 1,
  },
  // {
  //   applicationId: 0,
  //   id: 2,
  //   languageId: 2,
  // },
  // {
  //   applicationId: 0,
  //   id: 3,
  //   languageId: 3,
  // },
  // {
  //   applicationId: 0,
  //   id: 4,
  //   languageId: 8,
  //   otherLanguageDescription: "Russian",
  // },
];

const BusinessInfoDailyHoursOfOperation = [
  {
    applicationId: 0,
    isActive: false,
    dayOfWeek: 0,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 12,
    toMin: 0,
    toAmPm: "PM",
  },
  {
    applicationId: 0,
    isActive: true,
    dayOfWeek: 1,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 5,
    toMin: 30,
    toAmPm: "PM",
  },
  {
    applicationId: 0,
    isActive: true,
    dayOfWeek: 2,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 5,
    toMin: 30,
    toAmPm: "PM",
  },
  {
    applicationId: 0,
    isActive: true,
    dayOfWeek: 3,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 5,
    toMin: 30,
    toAmPm: "PM",
  },
  {
    applicationId: 0,
    isActive: true,
    dayOfWeek: 4,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 5,
    toMin: 30,
    toAmPm: "PM",
  },
  {
    applicationId: 0,
    isActive: true,
    dayOfWeek: 5,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 5,
    toMin: 30,
    toAmPm: "PM",
  },
  {
    applicationId: 0,
    isActive: false,
    dayOfWeek: 6,
    fromHour: 9,
    fromMin: 0,
    fromAmPm: "AM",
    toHour: 12,
    toMin: 0,
    toAmPm: "PM",
  },
];

const DaysOfWeek = [
  {
    name: "Sunday",
    value: 0,
  },
  {
    name: "Monday",
    value: 1,
  },
  {
    name: "Tuesday",
    value: 2,
  },
  {
    name: "Wednesday",
    value: 3,
  },
  {
    name: "Thursday",
    value: 4,
  },
  {
    name: "Friday",
    value: 5,
  },
  {
    name: "Saturday",
    value: 6,
  },
];

const SpokenLanguages = [
  {
    name: "English",
    value: 1,
  },
  {
    name: "Chinese",
    value: 2,
  },
  {
    name: "German",
    value: 3,
  },
  {
    name: "Spanish",
    value: 4,
  },
  {
    name: "Vietnamese",
    value: 5,
  },
  {
    name: "Arabic",
    value: 6,
  },
  {
    name: "Other",
    value: 7,
  },
];

const DailyTimeOptionHours = [
  {
    value: 12,
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
  {
    value: 11,
  },
];

const DailyTimeOptionMinutes = [
  {
    value: 0,
    name: "00",
  },
  {
    value: 30,
    name: "30",
  },
];

const DailyTimeOptionAmPm = [
  {
    value: "AM",
  },
  {
    value: "PM",
  },
];

export {
  BusinessInfoMasterData,
  BusinessInfoTargetCOTData,
  BusinessInfoSpokenLanguages,
  DaysOfWeek,
  SpokenLanguages,
  DailyTimeOptionHours,
  DailyTimeOptionMinutes,
  DailyTimeOptionAmPm,
  BusinessInfoDailyHoursOfOperation,
};
