import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";

import {
  ShortDateFormat,
  StringToDate,
  DateWithoutTime,
} from "../../helpers/DateHelpers";

const PharmacyInfoPharmacistInCharge = ({
  dataPharmacist,
  setDataPharmacist,
  failedSubmitWasPressed,
  dataPharmacistValidated,
  editItem,
  clearEdit,
  isEditMode,
  setEdit,
  isReadOnly,
}) => {
  const {
    control,
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    if (!values || Object.keys(values).length === 0) return;

    let updatedDataPharmacist;

    if (values.editId < 0) {
      //const maxLocalId = Math.max(...dataPharmacist.map((t) => t.IdLocal));

      const maxLocalId =
        !dataPharmacist || dataPharmacist.length == 0
          ? 0
          : Math.max(...dataPharmacist.map((t) => t.IdLocal));

      const newRecord = {
        IdLocal: maxLocalId + 1,
        licenseNum: values.editlicenseNum,
        fullName: values.editfullName,
        expiration: values.editexpiration,
      };

      updatedDataPharmacist = [...dataPharmacist, newRecord];
    } else {
      updatedDataPharmacist = dataPharmacist.map((item) => {
        if (item.IdLocal == values.editId)
          return {
            ...item,
            licenseNum: values.editlicenseNum,
            fullName: values.editfullName,
            expiration: values.editexpiration,
          };

        return item;
      });
    }

    turnAddingViewOff();
    reset();

    setDataPharmacist(updatedDataPharmacist);
  };

  const DeletedDataPharmacist = (id) => {
    const updatedDataPharmacist = dataPharmacist.filter(
      (item) => item.IdLocal != id
    );

    setDataPharmacist(updatedDataPharmacist);
  };

  const [addingView, setAddingView] = useState(false);

  const turnAddingViewOff = () => {
    unRegisterAll();
    clearEdit();
    setAddingView(false);
  };
  //If this is not done the useform hook will retain values and cause many unwanted effects
  //There may be a better way to do it but didn't find it yet
  const unRegisterAll = () => {
    unregister("editfullName");
    unregister("editlicenseNum");
    unregister("editexpiration");
    unregister("editId");
  };

  const [value, setValue] = useState(new Date());
  // const handleChange = (event) => {
  //   alert(event.target.value);
  //   //logs.current.unshift("change: " + event.target.value);
  //   setValue(event.target.value);
  //   // setEvents(logs.current.slice());
  // };

  const EditView = (id, fullName, licenseNum, expiration) => {
    //If date is empty defualt it to today
    const processedDate = DateWithoutTime(
      expiration == "" ? new Date() : StringToDate(expiration)
    );

    return (
      <tr key={id}>
        <td>
          <input
            type="hidden"
            defaultValue={id}
            name="editId"
            {...register("editId")}
          />

          <input
            type="text"
            className="form-control form-control-sm uppercase"
            defaultValue={fullName}
            name="editfullName"
            {...register("editfullName", {
              required: "Value is required",
            })}
          />

          {errors.editfullName && (
            <p className="text-danger">{errors.editfullName.message} </p>
          )}
        </td>
        <td>
          <input
            type="text"
            className="form-control form-control-sm uppercase"
            defaultValue={licenseNum}
            name="editlicenseNum"
            {...register("editlicenseNum", {
              required: "Value is required",
            })}
          />

          {errors.editlicenseNum && (
            <p className="text-danger">{errors.editlicenseNum.message} </p>
          )}
        </td>
        <td>
          <Controller
            control={control}
            name="editexpiration"
            defaultValue={processedDate}
            {...register("editexpiration", {
              valueAsDate: true,
              required: "Not a valid date",
              validate: {
                isTodayOrLater: (i) => {
                  let enteredDate = StringToDate(i);
                  var currentDate = new Date();

                  return (
                    DateWithoutTime(enteredDate) >=
                      DateWithoutTime(currentDate) ||
                    "Cannot be earlier than today"
                  );
                },
              },
            })}
            render={({ field }) => (
              <DatePicker
                defaultValue={processedDate}
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />

          {errors.editexpiration && (
            <p className="text-danger">{errors.editexpiration.message} </p>
          )}
        </td>

        <td className="buttoncell">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
          <button
            className="btn btn-secondary ms-2"
            onClick={(e) => {
              e.preventDefault();

              reset();
              turnAddingViewOff();
            }}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <h6>Pharmacist-In-Charge-License *</h6>

      {failedSubmitWasPressed && !dataPharmacistValidated && (
        <div className="text-danger">
          At least one record needs to be entered
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col"></div>
          <div className="col"></div>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">PIC Name</th>
              <th scope="col">License#</th>
              <th scope="col">Expiration Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataPharmacist.map((t) => {
              return editItem.editName == "pharmacistincharge" &&
                editItem.editId == t.IdLocal ? (
                EditView(t.IdLocal, t.fullName, t.licenseNum, t.expiration)
              ) : (
                <tr key={t.IdLocal}>
                  <td>{t.fullName}</td>
                  <td>{t.licenseNum}</td>
                  <td>{ShortDateFormat(t.expiration)}</td>

                  {!isReadOnly && (
                    <td className="buttoncell">
                      <button
                        className="btn btn-primary"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          e.preventDefault();

                          setEdit("pharmacistincharge", t.IdLocal);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          if (window.confirm("Are you sure?")) {
                            e.preventDefault();

                            DeletedDataPharmacist(t.IdLocal);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
            {addingView && EditView(-1, "", "", "")}
          </tbody>
        </table>
        <button
          className="btn btn-primary"
          disabled={isEditMode() || isReadOnly}
          onClick={(e) => {
            e.preventDefault();
            setEdit("pharmacistincharge", -1);
            unRegisterAll();
            setAddingView(true);
          }}
        >
          Add New Pharmacist
        </button>
      </form>
    </>
  );
};

export default PharmacyInfoPharmacistInCharge;
