import { useParams } from 'react-router-dom';

import AlternateAddressForm from './AlternateAddressForm';

export default function AlternateCorporateAddress(props) {

    const params = useParams();

    return (
        <>
            <h4 className="text-start">Corporate Contact Address</h4>
            <hr/>  
            <AlternateAddressForm addressType={1} id={params.id}/>
        </>
    )
}