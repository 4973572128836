

export function CommandCell(props) {

    const {
        colSpan, 
        style, 
        dataItem, 
        onEdit, 
        onAdd, 
        onUpdate, 
        onDelete, 
        onDiscard, 
        onCancel, 
        editField,
        isInEdit} = props

    const inEdit = dataItem[editField]
    const isNewItem = dataItem.isNewItem

    function inEditButtons() {
        return (
            <div className="row">
                <div className="btn-group" role="group" aria-label="command-group">    
                    <button onClick={() => isNewItem ? onAdd(dataItem) : onUpdate(dataItem)} type="button" className="btn-sm btn btn-primary">{isNewItem ? "Add" : "Update"}</button>
                    <button onClick={() => isNewItem ? onDiscard(dataItem) : onCancel(dataItem)} type="button" className="btn-sm btn btn-danger">{isNewItem ? "Discard" : "Cancel"}</button>             
                </div>
            </div>
        )
    }


    function nonEditButtons() {
        return (
            <div className="btn-group" role="group" aria-label="command-group">
                <button disabled={isInEdit} onClick={() => isNewItem ? onAdd(dataItem) : onEdit(dataItem)} type="button" className="btn-sm btn btn-primary">{isNewItem ? "Add" : "Edit"}</button>
                <button disabled={isInEdit} onClick={() => onDelete(dataItem)} type="button" className="btn-sm btn btn-danger">Delete</button>
            </div>
        )
    }

    return (
        <td colSpan={colSpan} style={style}>
            {inEdit ? inEditButtons() : nonEditButtons()}
        </td>
    )
}