export const workflow = {
    GPO: {
      ControlName : "LTCOmnicare",
      Ncpdp: {
        required: true,
      },
      CorporateName: {
        label: "Corporate Name",
        required: true,
      },
      NameOf: {
        label: "Name of Pharmacy",
        required: true,
      },
      BusinessAs: {
        label: "Pharmacy Doing Business As",
        required: false,
      },
      County: {
        show: false,
        required: true,
      },
      Hin: {
        multiple: false,
        show: true,
      },
    },
    "LTC Network": {
      Ncpdp: {
        required: true,
      },
      CorporateName: {
        label: "Corporate Name",
        required: true,
      },
      NameOf: {
        label: "Name of Provider",
        required: true,
      },
      BusinessAs: {
        label: "Provider Doing Business As",
        required: false,
      },
      County: {
        show: true,
        required: true,
      },
      Hin: {
        multiple: false,
        show: false,
      },
    },
    // HME: {
    //   Ncpdp: {
    //     required: false,
    //   },
    //   CorporateName: {
    //     label: "Corporate Legal Name",
    //   },
    //   NameOf: {
    //     label: "Facility Name",
    //   },
    //   BusinessAs: {
    //     label: "Facility DBA",
    //     required: false,
    //   },
    //   County: {
    //     show: false,
    //   },
    //   Hin: {
    //     multiple: true,
    //     show: true,
    //   },
    // },
    "Home Medical Equipment": {
      Ncpdp: {
        required: false,
      },
      CorporateName: {
        label: "Corporate Legal Name",
        required: false,
      },
      NameOf: {
        label: "Facility Name",
        required: false,
      },
      BusinessAs: {
        label: "Facility DBA",
        required: false,
      },
      // CorporateName: {
      //   label: "Corporate Name",
      // },
      // NameOf: {
      //   label: "Name of Pharmacy",
      // },
      // BusinessAs: {
      //   label: "Pharmacy Doing Business As",
      //   required: false,
      // },
      County: {
        show: false,
        required: false,
      },
      Hin: {
        multiple: true,
        show: true,
      },
    },
  
    "Medical Distributor": {
      Ncpdp: {
        required: false,
      },
      CorporateName: {
        label: "Corporate Legal Name",
        required: false,
      },
      NameOf: {
        label: "Facility Name",
        required: false,
      },
      BusinessAs: {
        label: "Facility DBA",
        required: false,
      },
      // CorporateName: {
      //   label: "Corporate Name",
      // },
      // NameOf: {
      //   label: "Name of Pharmacy",
      // },
      // BusinessAs: {
      //   label: "Pharmacy Doing Business As",
      //   required: false,
      // },
      County: {
        show: false,
        required: false,
      },
      Hin: {
        multiple: true,
        show: true,
      },
    },
    HME: {
      Ncpdp: {
        required: true,
      },
      CorporateName: {
        label: "Corporate Legal Name",
        required: false,
      },
      NameOf: {
        label: "Facility Name",
        required: false,
      },
      BusinessAs: {
        label: "Facility DBA",
        required: false,
      },
      // CorporateName: {
      //   label: "Corporate Name",
      // },
      // NameOf: {
      //   label: "Name of Pharmacy",
      // },
      // BusinessAs: {
      //   label: "Pharmacy Doing Business As",
      //   required: false,
      // },
      County: {
        show: false,
        required: false,
      },
      Hin: {
        multiple: true,
        show: true,
      },
    },
    "Alt Site": {
      Ncpdp: {
        required: false,
      },
      CorporateName: {
        label: "Corporate Legal Name",
        required: false,
      },
      NameOf: {
        label: "Facility Name",
        required: false,
      },
      BusinessAs: {
        label: "Facility DBA",
        required: false,
      },
      // CorporateName: {
      //   label: "Corporate Name",
      // },
      // NameOf: {
      //   label: "Name of Pharmacy",
      // },
      // BusinessAs: {
      //   label: "Pharmacy Doing Business As",
      //   required: false,
      // },
      County: {
        show: false,
        required: false,
      },
      Hin: {
        multiple: true,
        show: true,
      },
    },
  };
  