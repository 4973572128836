import { React, useEffect } from "react";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import "./Unauthenticated.css";

function Unauthenticated() {
  let { login } = useAzureAuthentication();

  //This will return true if the URL has LOGGEDOUT which indicated the page is being accessed after a logout click
  const isLoggedOutAccess = () => {
    return window.location.href.toUpperCase().includes("LOGGEDOUT");
  };

  useEffect(() => {
    if (!isLoggedOutAccess()) login();
  }, []);

  return (
    isLoggedOutAccess() && (
      <>
        <header className="navbar navbar-dark sticky-top bg-primary text-white flex-md-nowrap p-0 shadow">
          <span className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
            Mercury
          </span>
          <button
            className="navbar-toggler position-absolute d-md-none collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-nav text-nowrap px-3"></div>
        </header>

        <div class="Unauthenticated container">
          <div class="container">
            <div className="row">
              <div className="col">
                <div className="p-3 bg-light">
                  <h2>You have signed out of Mercury Member application</h2>

                  <p class="fs-5 mt-5">
                    To log back in please click{" "}
                    <a className="link-primary" onClick={login}>
                      here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer col-md-9 ms-sm-auto mt-auto py-3 bg-light text-center">
          <span className="text-muted">
            Copyright © 2021 MHA. All rights reserved.
          </span>
        </footer>
      </>
    )
  );
}

export default Unauthenticated;
