import { DropDownList } from "@progress/kendo-react-dropdowns"

export function StateCell(props) {

    const {colSpan, style, dataItem, field, states, onChange} = props
    
    function handleChange(e) {

        if(onChange) {
            onChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value
            })
        }
    }

    return (
        <td colSpan={colSpan} style={style}>
            {dataItem.inEdit ? (
                <>
                <select
                    id="state"
                    onChange={handleChange}
                    className="form-select form-select-sm"
                    value={states.find(x => x.STATECODE == dataItem[field])?.STATECODE ?? ""}
                    required
                    disabled={props.disabled}
                >
                    <option value="">{"<--Not Selected-->"}</option>
                    {states.map((state) => {
                    return (
                        <option key={state.STATECODE} value={state.STATECODE}>
                        {state.STATENAME}
                        </option>
                    );
                    })}
                </select>
                <div className="invalid-feedback">
                    Please select a State.
                </div>
                    </>
                ) : (
                    states.find(x => x.STATECODE == dataItem[field])?.STATENAME ?? ""
                )
            }
        </td>
    )
}

