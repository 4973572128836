

export function CommandCell(props) {

    const {
        colSpan, 
        style, 
        dataItem, 
        onEdit, 
        onAdd, 
        onUpdate, 
        onDelete, 
        onDiscard, 
        onCancel, 
        editField,
        onIsSameAddressAsPharmacyChange,
        isInEdit, 
        workflow} = props
    
    const {label} = workflow ?? {}; 
    const inEdit = dataItem[editField]
    const isNewItem = dataItem.ContactId === undefined

    function inEditButtons() {
        return (
            <>
            <div className="row">
            <div className="btn-group" role="group" aria-label="command-group">
            
                <button onClick={() => isNewItem ? onAdd(dataItem) : onUpdate(dataItem)} type="button" className="btn btn-primary">{isNewItem ? "Add" : "Update"}</button>
                <button onClick={() => isNewItem ? onDiscard(dataItem) : onCancel(dataItem)} type="button" className="btn btn-danger">{isNewItem ? "Discard" : "Cancel"}</button>
                
            </div>
            </div>
            {isNewItem && (
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={(e) =>onIsSameAddressAsPharmacyChange(e, dataItem)} checked={dataItem.isSameAddressAsPharmacy}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{label} </label>
                </div>   
                )}
            </>  
        )
    }


    function nonEditButtons() {
        return (
            <div className="btn-group" role="group" aria-label="command-group">

                <button disabled={isInEdit} onClick={() => isNewItem ? onAdd(dataItem) : onEdit(dataItem)} type="button" className="btn btn-primary">{isNewItem ? "Add" : "Edit"}</button>
                <button disabled={isInEdit} onClick={() => onDelete(dataItem)} type="button" className="btn btn-danger">Delete</button>


            </div>
        )
    }

    return (
        <td colSpan={colSpan} style={style}>
            
                {inEdit ? inEditButtons() : nonEditButtons()}
        </td>
    )
}