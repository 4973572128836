import { GetEmailPattern } from "../../helpers/ValidationHelper";

export const validationInfo = {
    FirstName: {
        feedbackMessage: "Please enter a First Name.",
        maxLength:"50",
        required: true
    },
    LastName: {
        feedbackMessage: "Please enter a Last Name.",
        maxLength:"50",
        required: true
    },
    Title: {
        feedbackMessage: "Please enter a Title.",
        maxLength:"50",
        required: true
    },
    Address: {
        feedbackMessage: "Please enter an Address",
        maxLength:"50",
        required: true
    },
    City: {
        feedbackMessage: "Please enter a City.",
        maxLength:"100",
        required: true
    },
    Zip: {
        feedbackMessage: "Please enter a Zip.",
        maxLength:"5",
        minLength: "5",
        required: true
    },
    Fax: {
        minLength: "14",
        maxLength: "14",
        pattern: "[\(][0-9]{3}[\)][\ ][0-9]{3}[\-][0-9]{4}"
    },
    Phone: {
        feedbackMessage: "Please enter a valid phone number.",
        minLength:"14",
        maxLength:"14",
        required: true,
        pattern: "[\(][0-9]{3}[\)][\ ][0-9]{3}[\-][0-9]{4}"

    },
    Extension: {
        maxLength:"5"
    },
    Email: {
        feedbackMessage: "Please enter a valid Email.",
        required: true,
        pattern: GetEmailPattern(),
    }

   

}