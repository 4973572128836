import React, { useState, useEffect, useContext } from "react";
import { memberAPI } from "../../utility/axios";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { getWorkFlow } from "../../helpers/AppDataHelpers";

import { useHistory } from "react-router-dom";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { GetApplicationType } from "../../helpers/AppDataHelpers";
import AdditionalInfoGPO from "./AdditionalInfoGPO";
import AdditionalInfoNetwork from "./AdditionalInfoNetwork";
import {additionalworkflow as pageWorkflow } from "./AdditionalInfoWorkFlow";
// import { Controller, useForm } from "react-hook-form";

const AdditionalInformationForm = (props) => {
  let { id } = props;

  const memberApplication = useContext(MemberApplicationContext);

  const [isEditMode, setIsEditMode] = useState(false);

  let { authInfo } = useAzureAuthentication();

  const [ltcNetworkData, setLtcNetworkData] = useState();
  const [gpoData, setGpoData] = useState();
  const [applicationId, setApplicationId] = useState(id);

  const [wasSubmitted, setWasSubmitted] = useState(false);

  const [postedDetails, setPostedDetails] = useState();
  const [additionalInfoCurrentworkflow, setadditionalInfoCurrentworkflow] = useState(null);
  useEffect(() => {
    if (memberApplication.additionalworkflow) {
      setadditionalInfoCurrentworkflow(pageWorkflow[memberApplication.additionalworkflow]);
    }
    if (memberApplication.id) {
      if (authInfo) initialAPIFetch();
    } else {
      memberApplication.onIDChange(id);
    }
  }, [authInfo, memberApplication.additionalworkflow]);

  useEffect(() => {
    if (memberApplication.id) {
      if (authInfo) {
        initialAPIFetch();
      }
    }
  }, [memberApplication.id]);

  // useEffect(() => {
  //   if (wasSubmitted) alert("Submitted was pressed");
  // }, [wasSubmitted]);

  useEffect(() => {
    
    //change depending on cot
    if (wasSubmitted && memberApplication.isReadOnly)
      routeChange(memberApplication.workflow !== "LTC Network" ? "/AdditionalOutputInfo":"/CreatePacket");
    

    setWasSubmitted(false);
    console.log(memberApplication.isReadOnly);
    if (!postedDetails || !postedDetails.isValid) {
      return;
    }

    //if (postedDetails.wasUpdated) 
    postData();

    routeChange(memberApplication.workflow !== "LTC Network" ? "/AdditionalOutputInfo":"/CreatePacket");
  }, [postedDetails]);

  const ApplicationType = () => {
    if (memberApplication.service && memberApplication.service == 2)
      return "LTC";

    return "GPO";
  };

  const history = useHistory();

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onResetClick(e) {
    e.preventDefault();
    setGpoData();
    setLtcNetworkData();
    initialAPIFetch();
  }

  function onBackClick(e) {
    e.preventDefault();

    const previousUrl =
      GetApplicationType(memberApplication.service) === "GPO"
        ? "/DistributorWholesaler"
        : "/PharmacyInfo";
    routeChange(previousUrl);
  }

  const optionsYesNo = [
    {
      label: "Yes",
      value: Boolean(true),
    },
    {
      label: "No",
      value: Boolean(false),
    },
  ];

  function initialAPIFetch() {
    async function fetchNetworkGPO() {
      let data;
      if(additionalInfoCurrentworkflow?.ControlName  === "HomeMedical")
      {
        const response = await memberAPI.get(
          "api/AdditionalMemberInfo2/FetchAdditionalInfoGPOHomeMed",
          {
            params: {
              applicationId: applicationId,
            },
            headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
          }
        );
        
  
        if (!response.data.AdditionalInfoGPOHomeMed) {
          data = {
            isCompanyAccredited: false,
            identifySourceOfAccreditation: ""
          };
        } else {
          data = {
            isCompanyAccredited:
              response.data.AdditionalInfoGPOHomeMed.IsCompanyAccredited,
            identifySourceOfAccreditation:
              response.data.AdditionalInfoGPOHomeMed.IdentifySourceOfAccreditation
  
          };
        }
      }
      else if(additionalInfoCurrentworkflow?.ControlName === "MediDist" )
      {
        const response = await memberAPI.get(
          "api/AdditionalMemberInfo2/FetchAdditionalInfoGPOMedDist",
          {
            params: {
              applicationId: applicationId,
            },
            headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
          }
        );
        
  
        if (!response.data.AdditionalInfoGPOMedDist) {
          data = {
            companyProvidePedigree: false,
            identifySourceOfPedigree: ""
          };
        } else {
          data = {
            companyProvidePedigree:
              response.data.AdditionalInfoGPOMedDist.IsCompanyProvidePedigree,
            identifySourceOfPedigree:
              response.data.AdditionalInfoGPOMedDist.IdentifySouceOfPedigree
          };
        }
      }
      else
      {
      const response = await memberAPI.get(
        "api/AdditionalMemberInfo2/FetchAdditionalInfoGPO",
        {
          params: {
            applicationId: applicationId,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );
      

      if (!response.data.AdditionalInfoGPO) {
        data = {
          associatedPharmacyDeaNumbers: "",
          doesOfferIvTherapy: false,
          baxterAccountNumberDescription: "",
          doesShareBuildingWithRetailPharmacy: false,
          hasDeaNumber: true,
          hasPhysicalInventorySeparations: true,
          hasRetailPharmacyAffiliation: false,
          hasSeparateEntranceForEachPharmacy: true,
          hasSeparateLicenseForEachPharmacy: true,
          hasWholesalerCustomerNumber: true,
          howPrescriptionsDelivered: "",
          includeSalesOrRetailInventory: false,
          isDistributionCenter: false,
          profitFacility: "Profit",
          isSeparatedByFourWalls: true,
          physicalInventorySeparationDescriptions: "",
          toWhoDistributesTo: "",
          iscompanyProvidePedigree: false,
          identifySourceOfPedigree: "",
          isCompanyAccredited: false,
          identifySourceOfAccreditation: ""
        };
      } else {
        data = {

          associatedPharmacyDeaNumbers:
            response.data.AdditionalInfoGPO.AssociatedPharmacyDeaNumbers,
          doesOfferIvTherapy:
            response.data.AdditionalInfoGPO.DoesOfferIvTherapy,
          baxterAccountNumberDescription:
            response.data.AdditionalInfoGPO.BaxterAccountNumberDescription,
          doesShareBuildingWithRetailPharmacy:
            response.data.AdditionalInfoGPO.DoesShareBuildingWithRetailPharmacy,
          hasDeaNumber: response.data.AdditionalInfoGPO.HasDeaNumber,
          hasPhysicalInventorySeparations:
            response.data.AdditionalInfoGPO.HasPhysicalInventorySeparations,
          hasRetailPharmacyAffiliation:
            response.data.AdditionalInfoGPO.HasRetailPharmacyAffiliation,
          hasSeparateEntranceForEachPharmacy:
            response.data.AdditionalInfoGPO.HasSeparateEntranceForEachPharmacy,
          hasSeparateLicenseForEachPharmacy:
            response.data.AdditionalInfoGPO.HasSeparateLicenseForEachPharmacy,
          hasWholesalerCustomerNumber:
            response.data.AdditionalInfoGPO.HasWholesalerCustomerNumber,
          howPrescriptionsDelivered:
            response.data.AdditionalInfoGPO.HowPrescriptionsDelivered,
          includeSalesOrRetailInventory:
            response.data.AdditionalInfoGPO.IncludeSalesOrRetailInventory,
          isDistributionCenter:
            response.data.AdditionalInfoGPO.IsDistributionCenter,
          profitFacility: response.data.AdditionalInfoGPO.ProfitFacility,
          isSeparatedByFourWalls:
            response.data.AdditionalInfoGPO.IsSeparatedByFourWalls,
          physicalInventorySeparationDescriptions:
            response.data.AdditionalInfoGPO
              .PhysicalInventorySeparationDescriptions,
          toWhoDistributesTo:
            response.data.AdditionalInfoGPO.ToWhoDistributesTo,
          companyProvidePedigree:
            response.data.AdditionalInfoGPO.IsCompanyProvidePedigree,
          identifySourceOfPedigree:
            response.data.AdditionalInfoGPO.IdentifySouceOfPedigree,
          isCompanyAccredited:
            response.data.AdditionalInfoGPO.IsCompanyAccredited,
          identifySourceOfAccreditation:
            response.data.AdditionalInfoGPO.IdentifySourceOfAccreditation

        };
      }
    }
      setGpoData(data);
    }
    async function fetchNetworkData() {
      const response = await memberAPI.get(
        "api/AdditionalMemberInfo2/FetchAdditionalInfoLTCNetwork",
        {
          params: {
            applicationId: applicationId,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      let data;

      if (!response.data.AdditionalInfoLTCNetwork) {
        data = {
          hasCriminalInfo: false,
          wasDeniedLicense: false,
          hasDisciplinaryAction: false,
        };
      } else {
        data = {
          hasCriminalInfo:
            response.data.AdditionalInfoLTCNetwork.HasCriminalInfo,
          criminalInfoExplanation:
            response.data.AdditionalInfoLTCNetwork.CriminalInfoExplanation,
          wasDeniedLicense:
            response.data.AdditionalInfoLTCNetwork.WasDeniedLicense,
          deniedLicenseExplanation:
            response.data.AdditionalInfoLTCNetwork.DeniedLicenseExplanation,
          hasDisciplinaryAction:
            response.data.AdditionalInfoLTCNetwork.HasDisciplinaryAction,
          disciplinaryActionExplanation:
            response.data.AdditionalInfoLTCNetwork
              .DisciplinaryActionExplanation,
        };
      }

      setLtcNetworkData(data);
    }

    if (ApplicationType() == "LTC") fetchNetworkData();

    fetchNetworkGPO();
  }

  const toLtcViewModelMapper = () => {
    const userId = authInfo.userEmail;

    const updatedData = {
      HasCriminalInfo: ltcNetworkData.pharmacyCriminalInfo,
      CriminalInfoExplanation: ltcNetworkData.pharmacyCriminalInfoExplanation,
      WasDeniedLicense: ltcNetworkData.pharmacyDeniedLicense,
      DeniedLicenseExplanation: ltcNetworkData.pharmacyDeniedLicenseExplanation,
      HasDisciplinaryAction: ltcNetworkData.pharmacyDisciplanaryInfo,
      DisciplinaryActionExplanation:
        ltcNetworkData.pharmacyDisciplanaryInfoExplanation,
      modifiedByUser: userId,
    };

    const viewModel = {
      ApplicationId: Number(applicationId),
      AdditionalInfoLTCNetwork: updatedData,
    };

    return viewModel;
  };

  const toGpoViewModelMapper = () => {
    const userId = authInfo.userEmail;

    const updatedData = {
      AssociatedPharmacyDeaNumbers: gpoData.associatedPharmacyiesDEANumber,
      DoesOfferIvTherapy: gpoData.doesofferIvTherapy,
      BaxterAccountNumberDescription: gpoData.baxterAccountNumberDescription,
      DoesShareBuildingWithRetailPharmacy:
        gpoData.doesShareBuildingWithRetailPharmacy,
      HasDeaNumber: gpoData.hasDeaNumber,
      HasPhysicalInventorySeparations: gpoData.hasPhysicalInventorySeparations,
      HasRetailPharmacyAffiliation: gpoData.hasRetailPharmacyAffiliation,
      HasSeparateEntranceForEachPharmacy:
        gpoData.hasSeparateEntranceForEachPharmacy,
      HasSeparateLicenseForEachPharmacy:
        gpoData.hasSeparateLicenseForEachPharmacy,
      HasWholesalerCustomerNumber: gpoData.hasWholesalerCustomerNumber,
      HowPrescriptionsDelivered: gpoData.howPrescriptionsDelivered,
      IncludeSalesOrRetailInventory: gpoData.includeSalesorInventoryAtRetail,
      IsDistributionCenter: gpoData.isDistributionCenter,
      ProfitFacility: gpoData.isProfitFacility,
      IsSeparatedByFourWalls: gpoData.isSeparatedByFourWalls,
      PhysicalInventorySeparationDescriptions:
        gpoData.physicalInventorySeparationDescriptions,
      ToWhoDistributesTo: gpoData.toWhomeDistributesTo,
      modifiedByUser: userId,
      IsCompanyProvidePedigree:
        gpoData.isCompanyProvidePedigree,
      IdentifySouceOfPedigree:
        gpoData.identifySourceOfPedigreeValue,
      IsCompanyAccredited:
        gpoData.isCompanyAccreditedvalue,
      IdentifySourceOfAccreditation:
        gpoData.identifySourceOfAccreditationvalue
    };

    const viewModel = {
      ApplicationId: Number(applicationId),
      AdditionalInfoGPO: updatedData,
    };

    return viewModel;
  };
  const toGpoMedDistViewModelMapper = () => {
    const userId = authInfo.userEmail;

    const updatedData = {
      modifiedByUser: userId,
      IsCompanyProvidePedigree:
        gpoData.isCompanyProvidePedigree,
      IdentifySouceOfPedigree:
        gpoData.identifySourceOfPedigreeValue
     
    };

    const viewModel = {
      ApplicationId: Number(applicationId),
      AdditionalInfoGPOMedDist: updatedData,
    };

    return viewModel;
  };
  const toGpoHomeMedViewModelMapper = () => {
    const userId = authInfo.userEmail;
    const updatedData = {
      modifiedByUser: userId,
      IsCompanyAccredited:
        gpoData.isCompanyAccreditedvalue,
      IdentifySourceOfAccreditation:
        gpoData.identifySourceOfAccreditationvalue
    };

    const viewModel = {
      ApplicationId: Number(applicationId),
      AdditionalInfoGPOHomeMed: updatedData,
    };

    return viewModel;
  };
  async function postData() {
    if (ApplicationType() == "LTC") {
      postLTC();
      return;
    }

    postGPO();
  }

  async function postLTC() {
    let viewModel = toLtcViewModelMapper();

    //alert("Posting");
    try {
      const response = await memberAPI.post(
        "api/AdditionalMemberInfo2/UpdateAdditionalInfoLTCNetwork",
        viewModel,
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );

      //routeChange("/DistributorWholesaler", applicationId);
    } catch (err) {
      console.log(err);
    }
  }

  async function postGPO() {
    
    try {
      if(additionalInfoCurrentworkflow?.ControlName  === "HomeMedical")
      {
        let viewModel = toGpoHomeMedViewModelMapper();
        const response = await memberAPI.post(
          "api/AdditionalMemberInfo2/UpdateAdditionalInfoGPOHomeMed",
          viewModel,
          { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
        );
      }
      else if(additionalInfoCurrentworkflow?.ControlName  === "MediDist")
      {
        let viewModel = toGpoMedDistViewModelMapper();
        const response = await memberAPI.post(
          "api/AdditionalMemberInfo2/UpdateAdditionalInfoGPOMedDist",
          viewModel,
          { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
        );
      }
      else
      {
      let viewModel = toGpoViewModelMapper();
      const response = await memberAPI.post(
        "api/AdditionalMemberInfo2/UpdateAdditionalInfoGPO",
        viewModel,
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
      //routeChange("/DistributorWholesaler", applicationId);
    } 
  }
  catch (err) {
    console.log(err);
  }
  }

  return (
    <div>
      {ApplicationType() == "LTC" ? (
        <AdditionalInfoNetwork
          {...{
            ltcNetworkData,
            setLtcNetworkData,
            optionsYesNo,
            wasSubmitted,
            setPostedDetails,
          }}
          isReadOnly={memberApplication.isReadOnly}
        />
      ) : (
        <AdditionalInfoGPO
          {...{
            gpoData,
            setGpoData,
            optionsYesNo,
            wasSubmitted,
            setPostedDetails,
            additionalInfoCurrentworkflow

          }}
          isReadOnly={memberApplication.isReadOnly}
        />
      )}
      <hr />
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          onClick={onResetClick}
          disabled={memberApplication.isReadOnly}
          type="button"
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick} type="button">
          Back
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={(e) => {
            setWasSubmitted(true);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AdditionalInformationForm;
