//Use this to store data items that live only for duration of a user session
//To store complex Json objects or arrays it is your responsibility to JSON.Stringify them before passing them to save,  this is not done here

const doesSessionStorageItemExist = (key) => {
  return window.sessionStorage.getItem(key) != null;
};

const getSessionStorageItem = (key) => {
  return window.sessionStorage.getItem(key);
};

const saveSessionStorageItem = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

const removeSessionStorageItem = (key) => {
  window.sessionStorage.removeItem(key);
};

const clearAllSessionStorageItems = () => {
  window.sessionStorage.clear();
};

export {
  doesSessionStorageItemExist,
  getSessionStorageItem,
  saveSessionStorageItem,
  removeSessionStorageItem,
  clearAllSessionStorageItems,
};
