import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { useEffect, useRef, useState } from "react";
import { CommandCell } from "./CommandCell";
import { ValidationCell } from "./ValidationCell";
import { IsNullOrWhitespace } from "../../helpers/StringHelpers";

export function HinInput(props) {
  const { value, onChange, disabled, workflow } = props;
  const { multiple, show } = workflow || { show: false, multiple: false };

  const editField = "inEdit";

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const formRef = useRef();
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    if (value) {
      const values = value.trim().split("|");

      // const hasData = values.some((x) => x.length > 0);

      // if (!hasData) return;

      let index = 1;

      // const gridData = values.map((x) => {
      //   return { idLocal: index++, hin: x, inEdit: false, isNewItem: false };
      // });

      //We should nopt get empty HIN values as handlers were added on AdD/Update methods but just in case it does get in will filter it out
      const gridData = values
        .filter((t) => !IsNullOrWhitespace(t))
        .map((x) => {
          return { idLocal: index++, hin: x, inEdit: false, isNewItem: false };
        });

      setData(gridData);
      setOriginalData(gridData);
    }
  }, [value]);

  const MyCommandCell = (props) => {
    return (
      <CommandCell
        {...props}
        onEdit={onEdit}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onDiscard={onDiscard}
        onCancel={onCancel}
        editField={editField}
        isInEdit={data.some((x) => x.inEdit)}
      />
    );
  };

  function addNew() {
    setIsValidated(false);
    let idLocal;
    if (data.length == 0) idLocal = 1;
    else idLocal = Math.max(...data.map((t) => t.idLocal)) + 1;

    const newDataItem = {
      idLocal: idLocal,
      hin: "",
      inEdit: true,
      isNewItem: true,
    };

    setData([newDataItem, ...data]);
  }

  function onEdit(dataItem) {
    const newData = data.map((item) =>
      item.idLocal === dataItem.idLocal ? { ...item, inEdit: true } : item
    );
    setData(newData);
  }

  function onAdd(dataItem) {
    setIsValidated(true);
    if (!formRef.current.checkValidity())
    {
      return;
    }

    const originalData = data.filter(
      (item) => item.idLocal != dataItem.idLocal
    );

    //Test if the new item is empty - if so DO NOT ACCEPT IT - will treat it like a Discard and just call the onDiscard method
    if (IsNullOrWhitespace(dataItem.hin)) {
      onDiscard();
      return;
    }

    const newData = data.map((item) =>
      item.idLocal === dataItem.idLocal
        ? { ...item, inEdit: false, isNewItem: false }
        : item
    );

    setOriginalData(originalData);
    setData(newData);

    onChange(concatHins(newData));
  }

  function onUpdate(dataItem) {
    setIsValidated(true);
    if (!formRef.current.checkValidity())
    {
      return;
    }

    //If they update value to a null - we do not accept an empty value - so will treat iot like a Delete and call the onDelete method
    if (IsNullOrWhitespace(dataItem.hin)) {
      onDelete(dataItem);
      return;
    }

    dataItem.inEdit = false;

    const newData = [...data];
    const index = newData.findIndex(
      (record) => record.idLocal === dataItem.idLocal
    );
    newData[index] = dataItem;

    setOriginalData(newData);
    setData(newData);

    onChange(concatHins(newData));
  }

  function onDiscard() {
    setIsValidated(false);
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
  }

  function onCancel(dataItem) {
    const originalItemExists = originalData.some(
      (p) => p.idLocal === dataItem.idLocal
    );
    if (originalItemExists) {
      const originalItem = originalData.find(
        (p) => p.idLocal === dataItem.idLocal
      );

      const newData = data.map((item) =>
        item.idLocal === originalItem.idLocal ? originalItem : item
      );
      setData(newData);
    } else {
      setData(originalData);
    }
  }

  function onDelete(dataItem) {
    const newData = [...data];

    let index = newData.findIndex(
      (record) => record.idLocal === dataItem.idLocal
    );
    newData.splice(index, 1);
    setData(newData);
    onChange(concatHins(newData));
  }

  function itemChange(event) {
    const field = event.field || "";
    let value = event.value ?? "";

    value = value.toUpperCase();

    const newData = data.map((item) =>
      item.idLocal === event.dataItem.idLocal
        ? { ...item, [field]: value }
        : item
    );
    setData(newData);
  }

  function concatHins(newData) {
    const hins = newData.map((x) => x.hin);
    return hins.join("|");
  }
  return (
    show &&
    (multiple ? (
      <div>
        <form
          ref={formRef}
          className={isValidated ? "was-validated" : ""}
          noValidate
        >
        <Grid
          style={{ width: "325px" }}
          data={data}
          dataItemKey={"idLocal"}
          onItemChange={itemChange}
        >
          <GridColumn
            field="hin"
            title="HIN"
            //value="3"
            editField={editField}
            cell={ValidationCell}
          />
          <GridToolbar>
            <button
              title="Add new"
              disabled={disabled || data.some((x) => x.inEdit)}
              className="btn btn-sm btn-primary"
              type="button"
              onClick={addNew}
            >
              Add new
            </button>
          </GridToolbar>
          {!disabled && (
            <GridColumn title="Action" width="150px" cell={MyCommandCell} />
          )}
        </Grid>

        <br />
        </form>
      </div>
    ) : (
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="hin">
          HIN
        </label>
        <div className="col-sm-3">
          <input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type="text"
            className="form-control form-control-sm"
            id="hin"
            placeholder="HIN"
            maxLength="9"
            minLength="9"
            pattern="[a-zA-Z0-9]{9}"
            disabled={disabled}
          />
        </div>
      </div>
    ))
  );
}

// export function HinInput(props) {

//   return props.service === 1 && (

//         <div className="mb-3 row">
//             <label className="col-sm-2 col-form-label-sm" htmlFor="hin">HIN</label>
//             <div className="col-sm-3">
//                 <input 
//                   value={props.value}
//                   onChange={props.onChange}
//                   type="text" 
//                   className="form-control form-control-sm" 
//                   id="hin" 
//                   placeholder="HIN"
//                   maxLength="9"
//                   disabled={props.disabled}/>
//             </div>
//         </div>
//   )

// }

