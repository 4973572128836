export function MultiLocationCheckbox(props) {

    return props.service === 1 && (
        <div className="form-check">
            <input className="form-check-input" type="checkbox" disabled={props.disabled} value="" onChange={props.onChange} id="MultiLocation" checked={props.hasMultiLocation}/>
            <label className="form-check-label" htmlFor="MultiLocationCheck">
            Multi-Location/Roster Enrollment?
            </label>
          </div>
    )
}