import { useEffect, useState } from "react";
import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { MemberServiceState } from "./MemberServiceContext";

export default function ClassOfTradeSelector(props) {
  const [classOfTrades, setClassOfTrades] = useState(null);
  let { authInfo } = useAzureAuthentication();
  const {
    memAppProgram, setmemAppProgram

  } = MemberServiceState();

  useEffect(() => {
    async function getClassOfTrades() {
      let res = await memberAPI.get("api/COT/FetchAllClassOfTrades", {
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setClassOfTrades(res.data);
    }

    async function fetchService_Program() {
      const res = await memberAPI.get("api/MemApp/FetchMemberAppService_Programs", {
        params: {
          id: 0
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
  
      const program = res.data;
  
      let memberService_program =
        program.map((item) => {
          return {
            MGR_GRID: item.MGR_GRID,
            GR_DESC: item.GR_DESC
          };
        });
      setmemAppProgram(memberService_program);
    }

    if (authInfo) {
      getClassOfTrades();
      fetchService_Program();
    }
  }, [authInfo]);

  return (
    props.show &&
    classOfTrades && (
      <>
        <div className="mb-3 row">
          <label className="col-sm-2 col-form-label-sm" htmlFor="cot">
            COT
          </label>
          <select
            id="cot"
            onChange={props.onChange}
            className="form-select form-select-sm"
            value={props.selectedClassOfTrade ?? ""}
            required
            disabled={props.disabled}
          >
            <option value="">{"<--Not Selected-->"}</option>
            {classOfTrades.map((classOfTrade) => {
              return (
                <option key={classOfTrade.TRID} value={classOfTrade.TRID}>
                  {classOfTrade.TRDESCRIPTION}
                </option>
              );
            })}
          </select>
          <div className="invalid-feedback">
            Please select a Class of Trade.
          </div>
        </div>
      </>
    )
  );
}
