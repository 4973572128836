const GetISODate = (inputDate) => {
  return StringToDate(inputDate).toISOString();
};

const StringToDate = (inputDate) => {
  //This will ensure we are returning a daqte object - either it is already a date or it will be converted from a string to a date
  return getDate(inputDate);
};

const ShortDateFormat = (inputDate) => {
  //return GetISODate(inputDate);
  const dt = getDate(inputDate);

  return dt.toLocaleDateString("en-US");
};

function ParseTime(inputTime) {
  if (inputTime == "") return null;

  var time = inputTime.match(/(\d+)(:(\d\d))?\s*(p?)/i);
  if (time == null) return null;

  var hours = parseInt(time[1], 10);
  if (hours == 12 && !time[4]) {
    hours = 0;
  } else {
    hours += hours < 12 && time[4] ? 12 : 0;
  }
  var d = new Date();
  d.setHours(hours);
  d.setMinutes(parseInt(time[3], 10) || 0);
  d.setSeconds(0, 0);
  return d;
}

function DateWithoutTime(inputDate) {
  //If argument already a date just return it

  let dt = getDate(inputDate);

  const result = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());

  return result;
}

function getDate(inputDate) {
  //If argument already a date just return it
  if (isDate(inputDate)) return inputDate;

  let dt = new Date(inputDate);

  //If argument string not valid initialize it to default date (year 1900)
  if (isNaN(dt)) dt = new Date(0, 0);

  return dt;
}

function isDate(input) {
  return typeof input == typeof new Date();
}

function GetDefaultDate(dateText){
  var date = new Date(dateText);
  var month = (date.getMonth() + 1);               
  var day = date.getDate();
  if (month < 10) 
      month = "0" + month;
  if (day < 10) 
      day = "0" + day;
  var defaultDate = date.getFullYear() + '-' + month + '-' + day;
  return defaultDate;
}

export {
  GetISODate,
  StringToDate,
  ShortDateFormat,
  ParseTime,
  DateWithoutTime,
  GetDefaultDate
};


