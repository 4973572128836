export const workflow = {
    GPO: {
        Action: {
            label: "Same As Pharmacy",
            required: false,
          },
    },
    "LTC Network": {
        Action: {
            label: "Same As Pharmacy",
            required: false,
          },
    },
    HME: {
        Action: {
            label: "Same As Provider",
            required: false,
          },
    },
  
    "Medical Distributor": {
     
        Action: {
            label: "Same As Provider",
            required: false,
          },
    },
    "Alt Site": {
     
      Action: {
        label: "Same As Provider",
        required: false,
      },
     
    },
  };
  