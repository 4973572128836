import { useState, useEffect } from "react";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { memberAPI } from "../../utility/axios";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

// for paging
const initialDataState = {
  skip: 0,
  take: 10,
};

export function AggregateSelector(props) {
  const { value, onChange, disabled, type, show , required } = props;
  const [query, setQuery] = useState("");
  const [searchType, setSearchType] = useState("id");
  const [selectedState, setSelectedState] = useState({});
  const [elements, setElements] = useState([]);
  const [page, setPage] = useState(initialDataState);

  let { authInfo } = useAzureAuthentication();

  useEffect(() => {
    setElements([]);
    setSelectedState({});
    setQuery("");
    setSearchType("id");
  }, [type]);

  function onSelectionChange(event) {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  }

  function onSave(e) {
    e.preventDefault();
    const index = elements.findIndex(
      (element) => element.id == Object.keys(selectedState)[0]
    );

      onChange(elements[index]);
  }

  function onClick(e) {
    e.preventDefault();
    async function fetchById(aggregateType) {
      const res = await memberAPI.get(`api/${aggregateType}/FetchById`, {
        params: {
          id: query,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setElements(res.data);
    }

    async function fetchByName(aggregateType) {
      const res = await memberAPI.get(`api/${aggregateType}/FetchByName`, {
        params: {
          name: query,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setElements(res.data);
    }

    const aggregateType =
      type === "existingCorp" ? "ParentCompany" : "Affiliates";
    if (searchType === "id") {
      fetchById(aggregateType);
    } else if (searchType === "name") {
      fetchByName(aggregateType);
    }
  }

  return (
    show && (
      <>
        {!disabled && (
          <>
          <div className="mb-3 row">
          <label className="col-sm-1 col-form-label-sm" htmlFor="search-type">
            Search
          </label>
          <div className="col-sm-3">
            <select
              id="search-type"
              onChange={(e) => setSearchType(e.target.value)}
              className="form-select form-select-sm"
              value={searchType}
              required ={required}
              disabled={disabled}
              >
              <option value="id">By ID </option>
              <option value="name">By Name</option>
            </select>
          </div>
          <div className="col-sm-3">
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              className="form-control form-control-sm"
              id="query"
              placeholder="Search"
              disabled={disabled}
            />
          </div>
          <div className="col-sm-1">
            <button onClick={onClick} className="btn btn-sm btn-primary">
              Search
            </button>
          </div>
          <div className="col-sm-1">
            <button onClick={onSave} className="btn btn-sm btn-primary">
              Save
            </button>
          </div>
        </div>
          </>
        )}
        <div className="mb-3 row">
          <label className="col-sm-1 col-form-label-sm" htmlFor="aggregate-id">
            {type === "existingCorp" ? "Corp ID" : "Affiliate ID"}
          </label>
          <div className="col-sm-3">
            <input
              value={value?.id ?? ""}
              type="text"
              className="form-control form-control-sm"
              id="aggregate-id"
              placeholder={type === "existingCorp" ? "Corp ID" : "Affiliate ID"}
              required={required}
              onKeyDown={() => {
                return false;
              }}
            />
            <div className="invalid-feedback">
              Please Select a{" "}
              {type === "existingCorp" ? "Corporation" : "Affiliate"}
            </div>
          </div>
          <label className="col-sm-1 col-form-label-sm" htmlFor="name">
            {type === "existingCorp" ? "Corp" : "Affiliate"} Name
          </label>
          <div className="col-sm-4">
            <input
              value={value?.name ?? ""}
              type="text"
              className="form-control form-control-sm"
              id="name"
              placeholder={type === "existingCorp" ? "Corp Name" : "Affiliate Name"}
              required={required}
              onKeyDown={() => {
                return false;
              }}
            />
            <div className="invalid-feedback">
              Please Select a{" "}
              {type === "existingCorp" ? "Corporation" : "Affiliate"}
            </div>
          </div>
        </div>
        {!disabled && (<>
        <div className="mb-3 row">
          <Grid
            data={elements
              .slice(page.skip, page.take + page.skip)
              .map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              }))}
            skip={page.skip}
            take={page.take}
            total={elements.length}
            pageable
            onPageChange={(e) => setPage(e.page)}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            onSelectionChange={onSelectionChange}
            selectable={{
              enabled: true,
              mode: "single",
            }}
          >
            <Column field="id" title="ID" />
            <Column field="name" title="Name"/>
          </Grid>
        </div>
        </>)}
      </>
    )
  );
}
