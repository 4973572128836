import axios from "axios";

const memberAPI = axios.create({
  baseURL: process.env.REACT_APP_MEMBERAPI,
});

const adminAPI = axios.create({
  baseURL: process.env.REACT_APP_ADMINAPI,
});

export { memberAPI, adminAPI };
