import React from "react";
import { useParams } from "react-router";
import PharmacyInfoForm from "./PharmacyInfoForm";

const PharmacyInfo = () => {
  const params = useParams();

  return (
    <>
      <h4 className="text-start">Pharmacy / Provider Info</h4>
      <hr />

      <PharmacyInfoForm id={params.id} />
    </>
  );
};

export default PharmacyInfo;
