import React, { useState, useEffect,useContext } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { DistributorWholeCommandCell } from "./DistributorWholeCommandCell";
import { wholesalerworkflow as pageWorkflow } from "./DistributorWholesalerWorkflow";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";

const editField = "inEdit";

const WholesalerGrid = ({
  wholesalers,
  setWholesalerData,
  wholesalerParentCompanies,
  failedSubmitWasPressed,
  isValidated,
  isEditMode,
  setIsEditMode,
  isReadOnly,
}) => {
  const [originalWholesalers, setOriginalWholesalers] = useState();
  const memberApplication = useContext(MemberApplicationContext);
  const [showEmptyDropdownError, setShowEmptyDropdownError] = useState();
  const [wholesalerworkflow, setwholesalerworkflow] = useState(null);

  useEffect(() => {
    if (memberApplication.wholesalerworkflow) {
      setwholesalerworkflow(pageWorkflow[memberApplication.wholesalerworkflow]);
    }
  }, [memberApplication.wholesalerworkflow]);
  const remove = (dataItem) => {
    const updated = wholesalers.filter(
      (item) => item.idLocal != dataItem.idLocal
    );

    setWholesalerData(updated);
  };

  const update = (dataItem) => {
    let localId = Number(dataItem.idLocal);

    if (!dataItem.parentCompanyId) {
      setShowEmptyDropdownError(true);
      return;
    }

    if (localId == 0)
      localId = Math.max(...wholesalers.map((t) => t.idLocal)) + 1;

    let updated = wholesalers.map((item) =>
      item.idLocal === dataItem.idLocal
        ? {
            ...item,
            idLocal: localId,
            parentCompanyId: dataItem.parentCompanyId,
            accountNumber: dataItem.accountNumber,
            alternateAccountNumber: dataItem.alternateAccountNumber,
            tertiaryAccountNumber: dataItem.tertiaryAccountNumber,
            name: dataItem.name,
            inEdit: false,
          }
        : item
    );

    setWholesalerData(updated);
    setIsEditMode(false);
  };

  const cancel = (dataItem) => {
    setWholesalerData(originalWholesalers);
    if (showEmptyDropdownError) setShowEmptyDropdownError(false);

    setIsEditMode(false);
  };

  const enterEdit = (dataItem) => {
    const original = wholesalers.map((item) => ({ ...item, inEdit: false }));
    setOriginalWholesalers(original);

    setWholesalerData(
      wholesalers.map((item) =>
        item.idLocal === dataItem.idLocal ? { ...item, inEdit: true } : item
      )
    );
    setIsEditMode(true);
  };

  const itemChange = (event) => {
    const newData = wholesalers.map((item) =>
      item.idLocal === event.dataItem.idLocal
        ? { ...item, [event.field || ""]: event.value }
        : item
    );
    setWholesalerData(newData);
  };

  const addNew = () => {
    const original = wholesalers.map((item) => ({ ...item, inEdit: false }));
    setOriginalWholesalers(original);
    let localId=0;
    if (original.length == 0) localId=1;
    else 
    localId = Math.max(...wholesalers.map((t) => t.idLocal)) + 1;

    const newDataItem = {
      idLocal: localId,
      accountNumber: "",
      alternateAccountNumber: "",
      tertiaryAccountNumber: "",
      name: "",
      inEdit: true,
    };
    setWholesalerData([...wholesalers,newDataItem]);
    setIsEditMode(true);
  };

  const CommandCell = (props) => (
    <DistributorWholeCommandCell
      {...props}
      edit={enterEdit}
      remove={remove}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const DistributorWholeParentCompanyDropdown = (props) => {
    const { dataItem, field } = props;

    const itemRender = (li) => {
      const itemChildren = (
        <span className="distribWholeSmallDropdownFont">
          {li.props.children} 
        </span>
      );
      return React.cloneElement(li, li.props, itemChildren);
    };

    function handleDropdownValueChange(e) {
      let selectedCompany = wholesalerParentCompanies.find(
        (x) => x.wpid == e.value.wpid
      );

      const updated = wholesalers.map((item) =>
        item.idLocal == dataItem.idLocal
          ? {
              ...item,
              parentCompanyId: selectedCompany.wpid,
              name: selectedCompany.name,
            }
          : item
      );

      setWholesalerData(updated);

      if (showEmptyDropdownError) setShowEmptyDropdownError(false);
    }
    return (
      <td>
        {dataItem.inEdit ? (
          <>
            <DropDownList
              style={{
                width: "100%",
              }}
              itemRender={itemRender}
              data={wholesalerParentCompanies}
              textField="name"
              //dataItemKey="wpid"
              value={wholesalerParentCompanies.find(
                (x) => x.wpid == dataItem.parentCompanyId
              )}
              onChange={handleDropdownValueChange}
            />
            {showEmptyDropdownError && (
              <div className="text-danger">Selection is required</div>
            )}
          </>
        ) : (
          dataItem[field]
        )}
      </td>
    );
  };

  return (
    <>
    {(wholesalerworkflow?.validationRequired.required == false)&&(
      <h6>Wholesaler List </h6>
    )}
    {(wholesalerworkflow?.validationRequired.required != false)&&(
      <h6>Wholesaler List *</h6>
    )}
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        </form> */}
      <Grid data={wholesalers} onItemChange={itemChange} editField={editField}>
        <GridColumn
          field="name"
          title="Wholesaler"
          cell={DistributorWholeParentCompanyDropdown}
        />

        <GridColumn field="accountNumber" title="Account Number" />
        <GridColumn field="alternateAccountNumber" title="Alt Account Number" />

        {/* <GridColumn
          field="tertiaryAccountNumber"
          title="Tertiary Account Number"
        /> */}

        {!isReadOnly && <GridColumn cell={CommandCell} width="200px" />}
      </Grid>

      <GridToolbar>
        <button
          title="Add new"
          className="btn btn-primary"
          onClick={addNew}
          disabled={isReadOnly || isEditMode}
        >
          Add New Wholesaler
        </button>
      </GridToolbar>

      {failedSubmitWasPressed && !isValidated() && (
        <div className="text-danger">
          At least one wholesaler record needs to be entered
        </div>
      )}
    </>
  );
};

export default WholesalerGrid;
