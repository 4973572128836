export const wholesalerworkflow = {
  GPO: {
    validationRequired: {
      required: true,
      ControlName:"GPO"
    },
  },
  "LTC Network": {
    validationRequired: {
      required: true,
      ControlName:"LTC Network"
    },
  },
  
  "Home Medical Equipment": {
    validationRequired: {
      required: false,
      ControlName:"HomeMedical"
    },
  },

  "Medical Distributor": {
    validationRequired: {
      required: false,
      ControlName:"Medical Distributor"
    },
  },
  HME: {
    validationRequired: {
      required: false,
      ControlName:"HME"
    },
  },
  "Alt Site": {
    validationRequired: {
      required: false,
      ControlName:"Alt Site"
    },
  }
};
