import { useHistory } from "react-router-dom";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { useEffect, useState, useRef, useContext } from "react";
import { memberAPI } from "../../utility/axios";
import { maskPhone } from "../../utility/mask";
import { HinInput } from "./HinInput";
import { NameOfInput } from "./NameOfInput";
import { BusinessAsInput } from "./BusinessAsInput";
import { CountyInput } from "./CountyInput";
import { StateSelector } from "../../components/StateSelector";
import { Modal } from "bootstrap";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import { RebateAddressCheckbox } from "./RebateAddressCheckbox";
import { NetworkInvoiceMailingAddress } from "./NetworkInvoiceMailingAddress";
import { workflow as pageWorkflow } from "./MemberDemographicWorkflow";
import { NcpdpInput } from "./NcpdpInput";
import LoadingPageItem from "../../components/LoadingPageItem";
import { CorporateNameInput } from "./CorporateNameInput";
import { DEADateInput } from "./DEADateInput";
import { GetISODate } from "../../helpers/DateHelpers";
import {
  ShortDateFormat,
  StringToDate,
  DateWithoutTime,
} from "../../helpers/DateHelpers";

export default function MemberDemographicsForm(props) {
  const { id } = props;
  
  const [deadate, setDeadate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [dea, setDea] = useState("");
  const [ncpdp, setNcpdp] = useState("");
  const [npi, setNpi] = useState("");
  const [hin, setHin] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [nameOf, setNameOf] = useState("");
  const [businessAs, setBusinessAs] = useState("");
  const [address, setAddress] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [county, setCounty] = useState("");
  // const [fax, setFax] = useState("");
  const [phone, setPhone] = useState("");
  const [extension, setExtension] = useState("");

  const [Corporateaddress, setCorporateAddress] = useState("");
  const [CorporateaddressTwo, setCorporateAddressTwo] = useState("");
  const [Corporatecity, setCorporateCity] = useState("");
  const [Corporatestate, setCorporateState] = useState("");
  const [Corporatezip, setCorporateZip] = useState("");

  const [hasAlternateGpoAddress, setHasAlternateGpoAddress] = useState(false);
  const [hasAlternateRebateAddress, setHasAlternateRebateAddress] =
    useState(false);
  const [hasAlternateNetworkAddress, setHasAlternateNetworkAddress] =
    useState(false);
  const [hideWarning, setHideWarning] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [docusignContact, setDocusignContact] = useState("");
  const [hadPreviousAlternateGpoAddress, setHadPreviousAlternateGpoAddress] =
    useState(false);
  const [
    hadPreviousAlternateRebateAddress,
    setHadPreviousAlternateRebateAddress,
  ] = useState(false);
  const [
    hadPreviousAlternateNetworkAddress,
    setHadPreviousAlternateNetworkAddress,
  ] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteType, setDeleteType] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [currentWorkflow, setCurrentworkflow] = useState(null);
  const [ownParent, setownParent] = useState(false);
  const [MSCorpID, setMSCorpID] = useState("");
  const [MSCorpName, setMSCorpName] = useState("");
  const formRef = useRef();
  const history = useHistory();
  let { authInfo } = useAzureAuthentication();
  const memberApplication = useContext(MemberApplicationContext);
  
  useEffect(() => {
    if (id !== memberApplication.id) {
      memberApplication.onIDChange(id);
    }

    if (authInfo) {
      initDemographics();
      setIsLoading(false);
    }
  }, [authInfo]);

  useEffect(() => {
    if (memberApplication.workflow) {
      // console.log(
      //   pageWorkflow[memberApplication.workflow],
      //   memberApplication.workflow
      // );
      setCurrentworkflow(pageWorkflow[memberApplication.workflow]);
      setownParent(memberApplication.ownParent);
    }
  }, [memberApplication.workflow,memberApplication.ownParent]);

  function handleChange(date) {
    setDeadate(date);
  }
  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function initDemographics() {
    async function fetchDemographics() {
      const res = await memberAPI.get("api/MemApp/FetchMemberAppDemographics", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      const data = res.data[0];
      setHin(data.HIN ?? "");
      setDea(data.DEANO ? data.DEANO.trim(): "");
      setDeadate(data.DEADATE == "1900-01-01" ? new Date() : data.DEADATE);
      // setDeadate(data.DEADATE ?? "");
      // console.log(deadate);
      setNcpdp(data.NCPDP ?? "");
      setNpi(data.NPI ?? "");
      setCorporateName(data.CORPNAME ?? "");
      setNameOf(data.PHARNAME);
      setBusinessAs(data.PHARBUSINESS ?? "");
      if(ownParent === false)
      {
      setCorporateAddress(data.CorpAddress ?? "");
      setCorporateAddressTwo(data.CorpAddressTwo ?? "");
      setCorporateCity(data.CorpCity ?? "");
      setCorporateState(data.CorpState ?? "");
      setCorporateZip(data.CorpZip ?? "");
      } else {
        setCorporateAddress("");
      setCorporateAddressTwo("");
      setCorporateCity("");
      setCorporateState("");
      setCorporateZip("");
      }
      setAddress(data.ADDR ?? "");
      setAddressTwo(data.ADDR2 ?? "");
      setCity(data.CITY ?? "");
      setState(data.STATE ?? "");
      setZip(data.ZIP ?? "");
      setCounty(data.County ?? "");
      // setFax(data.FAX ?? "");
      setPhone(data.PHONE ?? "");
      setExtension(data.EXTN ?? "");
      setHasAlternateGpoAddress(data.AltGpoAddress);
      setHasAlternateRebateAddress(data.AltRedAddress);
      setPrimaryContact(data.PRIMARYCONTACT);
      setDocusignContact(data.DOCUSIGNCONTACT);
      setHadPreviousAlternateGpoAddress(data.AltGpoAddress);
      setHadPreviousAlternateRebateAddress(data.AltRedAddress);
      setHasAlternateNetworkAddress(data.HasAlternateNetworkAddress);
      setMSCorpID(data.MSCorpID);
      setMSCorpName(data.MSCorpName);
    }

    fetchDemographics();
  }

  function onResetClick(e) {
    e.preventDefault();
    initDemographics();
  }

  function onBackClick(e) {
    e.preventDefault();
    routeChange("/MemberSource");
  }

  function getNextPath() {
    if (hasAlternateGpoAddress) {
      return "/MemberAlternateCorporateAddress";
    }

    if (hasAlternateRebateAddress) {
      return "/MemberAlternateRebateAddress";
    }

    if (hasAlternateNetworkAddress) {
      return "/MemberAlternateNetworkAddress";
    }

    return "/BusinessInfo";
  }

  function finalizeSubmit() {
    async function updateDemographics() {
      const CopyName=
      (memberApplication.workflow != "LTC Network" && memberApplication.radioSelection !="existingCorp" && ownParent ) ? nameOf 
      : (memberApplication.workflow != "LTC Network" && memberApplication.radioSelection ==="existingAffiliate" && !ownParent && !MSCorpName) ? corporateName :
      (memberApplication.workflow != "LTC Network" && memberApplication.radioSelection ==="existingCorp" || (memberApplication.radioSelection ==="existingAffiliate" && !ownParent )) ? MSCorpName 
      : corporateName;
      await memberAPI.post(
        "api/MemApp/UpdateMemberApplicationDemographics",
        {
          user: authInfo.userEmail,
          id: id,
          dea: dea,
          deadate: GetISODate(deadate ?? ''),
          ncpdp: ncpdp,
          npi: npi,
          hin: hin,
          corporateName: CopyName,
          nameOf: nameOf,
          businessAs: businessAs,
          corpaddress: Corporateaddress,
          corpaddressTwo: CorporateaddressTwo,
          corpcity: Corporatecity,
          corpstate: Corporatestate,
          corpzip: Corporatezip,
          address: address,
          addressTwo: addressTwo,
          city: city,
          state: state,
          zip: zip,
          county: county,
          // fax: fax,
          phone: phone,
          extension: extension,
          hasAlternateGpoAddress: hasAlternateGpoAddress,
          hasAlternateRebateAddress: hasAlternateRebateAddress,
          hasAlternateNetworkAddress: hasAlternateNetworkAddress,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }
    // update db
    updateDemographics();

    // update context
    memberApplication.onHasAlternateCorporateAddressChange(
      hasAlternateGpoAddress
    );
    memberApplication.onHasAlternateRebateAddressChange(
      hasAlternateRebateAddress
    );
    memberApplication.onHasAlternateNetworkAddressChange(
      hasAlternateNetworkAddress
    );

    const path = getNextPath();

    routeChange(path);
  }

  function getDeleteMessage() {
    if (
      hadPreviousAlternateRebateAddress &&
      !hasAlternateRebateAddress &&
      hadPreviousAlternateGpoAddress &&
      !hasAlternateGpoAddress
    ) {
      setDeleteType(3);
      return "The entered Corporate Contact Address and Rebate Mailing Address will be deleted permanently, Are you sure to change the Address Type?";
    } else if (
      hadPreviousAlternateRebateAddress &&
      !hasAlternateRebateAddress
    ) {
      setDeleteType(2);
      return "The entered Rebate Mailing Address will be deleted permanently, Are you sure to change the Address Type?";
    } else if (hadPreviousAlternateGpoAddress && !hasAlternateGpoAddress) {
      setDeleteType(1);
      return "The entered Corporate Contact Address will be deleted permanently, Are you sure to change the Address Type?";
    } else {
      return "Something went wrong";
    }
  }

  function onDelete() {
    async function deleteAlternateAddress() {
      const res = await memberAPI.post(
        "api/MemAppAltAddress/DeleteAlternateAddress",
        {
          id: id,
          addressType: deleteType,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    if (deleteType === 1) {
      deleteAlternateAddress(1);
    } else if (deleteType === 2) {
      deleteAlternateAddress(2);
    } else if (deleteType === 3) {
      deleteAlternateAddress(1);
      deleteAlternateAddress(2);
    }
    finalizeSubmit();
    const modal = new Modal(document.getElementById("deleteModal"));
    modal.hide();
  }
  function onNextClick(e) {
    e.preventDefault();

    // alert(hin);
    // return;

    if (memberApplication.isReadOnly) {
      const path = getNextPath();
      routeChange(path);
    } else {
      setIsValidated(true);
      if (formRef.current.checkValidity()) {
        if (
          (hadPreviousAlternateRebateAddress && !hasAlternateRebateAddress) ||
          (hadPreviousAlternateGpoAddress && !hasAlternateGpoAddress) ||
          (hadPreviousAlternateNetworkAddress && !hasAlternateNetworkAddress)
        ) {
          setDeleteMessage(getDeleteMessage());
          const modal = new Modal(document.getElementById("deleteModal"));
          modal.show();
        } else {
          finalizeSubmit();
        }
      }
    }
  }

  function onAlternateGpoChange(e) {
    if (
      !e.target.checked &&
      (primaryContact === "GPO" || docusignContact === "GPO")
    ) {
      if (primaryContact === "GPO" && docusignContact === "GPO") {
        setWarningMessage(
          "You are not able to remove the Corporate Contact Address, because you have selected this Corporate Contact as primary Pharmacy Contact and Docusign Pharmacy Contact"
        );
      } else if (primaryContact === "GPO") {
        setWarningMessage(
          "You are not able to remove the Corporate Contact Address, because you have selected this Corporate Contact as primary Pharmacy Contact"
        );
      } else if ([docusignContact === "GPO"]) {
        setWarningMessage(
          "You are not able to remove the Corporate Contact Address, because you have selected this Corporate Contact as Docusign Pharmacy Contact"
        );
      }
      setHideWarning(false);
    } else {
      setHasAlternateGpoAddress(e.target.checked);
    }
  }

  function onAlternateRebateChange(e) {
    if (
      !e.target.checked &&
      (primaryContact === "Rebate" || docusignContact === "Rebate")
    ) {
      if (primaryContact === "Rebate" && docusignContact === "Rebate") {
        setWarningMessage(
          "You are not able to remove the Rebate mailing address, because you have selected this Rebate mailing address as primary Pharmacy Contact and Docusign Pharmacy Contact"
        );
      } else if (primaryContact === "Rebate") {
        setWarningMessage(
          "You are not able to remove the Rebate mailing address, because you have selected this Rebate mailing address as primary Pharmacy Contact"
        );
      } else if (docusignContact === "Rebate") {
        setWarningMessage(
          "You are not able to remove the Rebate mailing address, because you have selected this Rebate mailing address as Docusign Pharmacy Contact"
        );
      }
      setHideWarning(false);
    } else {
      setHasAlternateRebateAddress(e.target.checked);
    }
  }

  function onAlternateNetworkChange(e) {
    if (
      !e.target.checked &&
      (primaryContact === "Network" || docusignContact === "Network")
    ) {
      if (primaryContact === "Network" && docusignContact === "Network") {
        setWarningMessage(
          "You are not able to remove the Network Invoice mailing address, because you have selected this Network Invoce mailing address as primary Pharmacy Contact and Docusign Pharmacy Contact"
        );
      } else if (primaryContact === "Network") {
        setWarningMessage(
          "You are not able to remove the Network Invoice address, because you have selected this Network Invoice mailing address as primary Pharmacy Contact"
        );
      } else if (docusignContact === "Network") {
        setWarningMessage(
          "You are not able to remove the Network Invoice address, because you have selected this Network Invoice mailing address as Docusign Pharmacy Contact"
        );
      }
      setHideWarning(false);
    } else {
      setHasAlternateNetworkAddress(e.target.checked);
    }
  }

  function onExtensionChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    setExtension(e.target.value);
  }

  function onZipChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    setZip(e.target.value);
  }
  function onCorporateZipChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    setCorporateZip(e.target.value);
  }
  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <form
      ref={formRef}
      className={isValidated ? "was-validated" : ""}
      noValidate
    >
      <div
        className={`alert alert-warning alert-dismissible fade show ${
          hideWarning ? "d-none" : ""
        }`}
        role="alert"
      >
        {warningMessage}
        <button
          type="button"
          className="btn-close"
          onClick={() => setHideWarning(true)}
          aria-label="Close"
        ></button>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="dea">
          DEA
        </label>
        <div className="col-sm-3">
          <input
            value={dea}
            onChange={(e) => setDea(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm uppercase"
            id="dea"
            placeholder="DEA"
            maxLength="9"
            
            pattern = "[a-zA-Z0-9]{9}"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a valid DEA</div>
        </div>
        <DEADateInput
          value={deadate}
          onChange={(e) => setDeadate(e.target.value)}
          hasDEA={dea !== ""}
          disabled={memberApplication.isReadOnly}
          workflow={currentWorkflow?.DEADate}
        />
      </div>
      
      <div className="mb-3 row">
        <NcpdpInput
          value={ncpdp}
          onChange={(e) => setNcpdp(e.target.value)}
          disabled={memberApplication.isReadOnly}
          workflow={currentWorkflow?.Ncpdp}
        />
        <label className="col-sm-2 col-form-label-sm" htmlFor="npi">
          NPI
        </label>
        <div className="col-sm-3">
          <input
            value={npi}
            onChange={(e) => setNpi(e.target.value)}
            type="text"
            className="form-control form-control-sm"
            id="npi"
            placeholder="NPI"
            minLength="10"
            maxLength="10"
            pattern="[0-9]+"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a valid NPI.</div>
        </div>
      </div>
      <HinInput
        disabled={memberApplication.isReadOnly}
        service={memberApplication.service}
        value={hin}
        onChange={(e) => setHin(e)}
        workflow={currentWorkflow?.Hin}
      />
      {/* <HinInput
          disabled={memberApplication.isReadOnly}
          service={memberApplication.service}
          value={hin}
          onChange={(e) => setHin(e.target.value.toUpperCase())}
      /> */}

      <CorporateNameInput
        disabled={memberApplication.isReadOnly || (memberApplication.workflow != "LTC Network" && memberApplication.radioSelection !="existingCorp" && ownParent === true) || memberApplication.radioSelection ==="existingCorp" || (memberApplication.radioSelection ==="existingAffiliate" && ownParent === false && MSCorpName!=null) }
        value={(memberApplication.workflow != "LTC Network" && memberApplication.radioSelection !="existingCorp" && ownParent === true) ? nameOf
        : (memberApplication.workflow != "LTC Network" && memberApplication.radioSelection ==="existingAffiliate" && !ownParent && !MSCorpName) ? corporateName
        : (memberApplication.workflow != "LTC Network" && memberApplication.radioSelection ==="existingCorp" || (memberApplication.radioSelection ==="existingAffiliate" && ownParent === false)) ? MSCorpName : corporateName}
        onChange={(e) => setCorporateName(e.target.value.toUpperCase())}
        workflow={currentWorkflow?.CorporateName}
      />
      <NameOfInput
        disabled={memberApplication.isReadOnly}
        workflow={currentWorkflow?.NameOf}
        value={nameOf}
        onChange={(e) => setNameOf(e.target.value.toUpperCase())}
      />
      <BusinessAsInput
        disabled={memberApplication.isReadOnly}
        workflow={currentWorkflow?.BusinessAs}
        value={businessAs}
        onChange={(e) => setBusinessAs(e.target.value.toUpperCase())}
      />
      {
          ((memberApplication.radioSelection ==="newStart" || memberApplication.radioSelection ==="formerGpo") && memberApplication.workflow != "LTC Network" && ownParent === false || (memberApplication.radioSelection ==="existingAffiliate" && ownParent === false && MSCorpName===null)) && (
       <>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="Corporateaddress">
          Corporate Address
        </label>
        <div className="col-sm-3">
          <input
            value={Corporateaddress}
            onChange={(e) => setCorporateAddress(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="Corporateaddress"
            placeholder="Corporate Address"
            maxLength="150"
            required={currentWorkflow?.Ncpdp.required}
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter an Corporate Address.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="Corporateaddress-two">
        Corporate Address 2
        </label>
        <div className="col-sm-3">
          <input
            value={CorporateaddressTwo}
            onChange={(e) => setCorporateAddressTwo(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="Corporateaddress-two"
            placeholder="Corporate Address 2"
            maxLength="150"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="Corporatecity">
        Corporate City
        </label>
        <div className="col-sm-3">
          <input
            value={Corporatecity}
            onChange={(e) => setCorporateCity(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="Corporatecity"
            placeholder="CorporateCity"
            maxLength="100"
            required = {currentWorkflow?.Ncpdp.required}
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a Corporate city.</div>
        </div>
      </div>
      <StateSelector
        disabled={memberApplication.isReadOnly}
        selectedState={Corporatestate}
        Statetype="Corporate "
        required = {currentWorkflow?.Ncpdp.required}
        onChange={(e) => setCorporateState(e.target.value)}
      />
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="Corporatezip">
        Corporate Zip
        </label>
        <div className="col-sm-3">
          <input
            value={Corporatezip}
            onChange={onCorporateZipChange}
            type="text"
            className="form-control form-control-sm"
            id="Corporatezip"
            placeholder="Corporate Zip"
            minLength="5"
            maxLength="5"
            required = {currentWorkflow?.Ncpdp.required}
            disabled={memberApplication.isReadOnly}
            pattern={"[0-9]+"}
          />
          <div className="invalid-feedback">Please enter a Corporate Zip.</div>
        </div>
      </div>
      </>
      )}
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="address">
          Address
        </label>
        <div className="col-sm-3">
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="address"
            placeholder="Address"
            maxLength="150"
            required={currentWorkflow?.Ncpdp.required}
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter an Address.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="address-two">
          Address 2
        </label>
        <div className="col-sm-3">
          <input
            value={addressTwo}
            onChange={(e) => setAddressTwo(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="address-two"
            placeholder="Address 2"
            maxLength="150"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="city">
          City
        </label>
        <div className="col-sm-3">
          <input
            value={city}
            onChange={(e) => setCity(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="city"
            placeholder="City"
            maxLength="100"
            required = {currentWorkflow?.Ncpdp.required}
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a city.</div>
        </div>
      </div>
      <StateSelector
        disabled={memberApplication.isReadOnly}
        selectedState={state}
        Statetype=""
        required = {currentWorkflow?.Ncpdp.required}
        onChange={(e) => setState(e.target.value)}
      />
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="zip">
          Zip
        </label>
        <div className="col-sm-3">
          <input
            value={zip}
            onChange={onZipChange}
            type="text"
            className="form-control form-control-sm"
            id="zip"
            placeholder="Zip"
            minLength="5"
            maxLength="5"
            required = {currentWorkflow?.Ncpdp.required}
            disabled={memberApplication.isReadOnly}
            pattern={"[0-9]+"}
          />
          <div className="invalid-feedback">Please enter a Zip.</div>
        </div>
      </div>
      <CountyInput
        value={county}
        onChange={(e) => setCounty(e.target.value.toUpperCase())}
        service={memberApplication.service}
        disabled={memberApplication.isReadOnly}
        workflow={currentWorkflow?.County}
      />
      {/* <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="fax">
          Fax
        </label>
        <div className="col-sm-3">
          <input
            value={fax}
            onChange={(e) => setFax(maskPhone(e.target.value, fax))}
            type="text"
            className="form-control form-control-sm"
            id="fax"
            placeholder="Fax"
            minLength="14"
            maxLength="14"
            pattern="[\(][0-9]{3}[\)][\ ][0-9]{3}[\-][0-9]{4}"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div> */}
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="phone">
          Phone
        </label>
        <div className="col-sm-3">
          <input
            value={phone}
            onChange={(e) => setPhone(maskPhone(e.target.value, phone))}
            type="text"
            className="form-control form-control-sm"
            id="phone"
            placeholder="Phone"
            required = {currentWorkflow?.Ncpdp.required}
            pattern="[\(][0-9]{3}[\)][\ ][0-9]{3}[\-][0-9]{4}"
            minLength={14}
            maxLength={14}
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a phone number.</div>
        </div>
        <label className="col-sm-2 col-form-label-sm" htmlFor="extn">
          Extn
        </label>
        <div className="col-sm-3">
          <input
            value={extension}
            onChange={onExtensionChange}
            type="text"
            className="form-control form-control-sm"
            id="extn"
            placeholder="Extn"
            maxLength="5"
            pattern={"[0-9]+"}
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-6">
          Please check if address different for:
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            disabled={memberApplication.isReadOnly}
            value=""
            onChange={onAlternateGpoChange}
            id="alternateGpoAddressCheck"
            checked={hasAlternateGpoAddress}
          />
          <label
            className="form-check-label"
            htmlFor="alternateGpoAddressCheck"
          >
            Corporate Contact Address
          </label>
        </div>
        <RebateAddressCheckbox
          disabled={memberApplication.isReadOnly}
          service={memberApplication.service}
          hasAlternateRebateAddress={hasAlternateRebateAddress}
          onChange={onAlternateRebateChange}
        />
        <NetworkInvoiceMailingAddress
          disabled={memberApplication.isReadOnly}
          service={memberApplication.service}
          hasNetworkMailingAddress={hasAlternateNetworkAddress}
          onChange={onAlternateNetworkChange}
        />
      </div>
      <DeleteConfirmationModal
        deleteMessage={deleteMessage}
        onDelete={onDelete}
      />
      <hr />
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          disabled={memberApplication.isReadOnly}
          onClick={onResetClick}
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        <button className="btn btn-primary" onClick={onNextClick} type="submit">
          Next
        </button>
      </div>
    </form>
  );
}
