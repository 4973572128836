import { useParams } from 'react-router-dom';
import AlternateAddressForm from './AlternateAddressForm';

export default function AlternateRebateAddress(props) {

    const params = useParams();

    return (
        <>
            <h4 className="text-start">Rebate Mailing Address</h4>
            <hr/>  
            <AlternateAddressForm addressType={2} id={params.id}/>
        </>
    )
}