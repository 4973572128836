import React, { useEffect, useState, useContext } from "react";
import { memberAPI } from "../../utility/axios";
import { useHistory } from "react-router-dom";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";

import { GetApplicationType, getWorkFlow } from "../../helpers/AppDataHelpers";

import { MemberApplicationContext } from "../../context/MemberApplicationContext";

import LoadingPageItem from "../../components/LoadingPageItem";

import {
  BusinessInfoDailyHoursOfOperation,
  SpokenLanguages,
} from "./BusinessInfoConstants";
import BusinessInfoSpokenLanguages from "./BusinessInfoSpokenLanguages";
import BusinessInfoHoursOfOperation from "./BusinessInfoHoursOfOperation";
import BusinessInfoCotPercentages from "./BusinessInfoCotPercentages";
import { BusinessInfoState } from "./BusinessInfoContext";

import BusinessInfoMedHME from "./BusinessInfoMedHME";
import BusinessInfoAltSite from "./BusinessInfoAltSite";
import { businessworkflow as pageWorkflow } from "./BusinessInfoWorkflow";
const BusinessInfoForm = (props) => {

  const {
    applicationId,
    setApplicationId,
    rootBusinessInfoApplicationData,
    setRootBusinessInfoApplicationData,
    targetCotPercentageData,
    setTargetCotPercentageData,
    targetCotPercentageMedData,
    setTargetCotPercentageMedData,
    targetBusinessInfoAltSite,
    setTargetBusinessInfoAltSite,
    dailyHoursOfOperation,
    setDailyHoursOfOperation,
    selectedSpokenLanguages,
    setSelectedSpokenLanguages,
    failedSubmitWasPressed,
    setFailedSubmitWasPressed,
    spokenLanguagesValidationPassed,
    cotTotalsValidationPassed,
    setHoursOfOperationValidationPassed,
    hoursOfOperationValidationPassed,
    setAllCots,
    cotTotalsOtherDescriptionEnteredValidationPassed,
    AltValidationPassed,
    setAltValidationPassed
  } = BusinessInfoState();
  const { id } = props;
  let { authInfo } = useAzureAuthentication();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedClassOfTrade, setSelectedClassOfTrade] = useState("");
  setApplicationId(id);
  const [businessInfocurrentWorkflow, setbusinessInfocurrentWorkflow] = useState(null);

  const memberApplication = useContext(MemberApplicationContext);
  useEffect(() => {
    if (
      Object.keys(rootBusinessInfoApplicationData).length === 0 &&
      applicationId > 0
    ) {
      if (memberApplication.id) {
        if (authInfo) {
          initfetchBusinessInfo()
        }
      } else {
        memberApplication.onIDChange(id)
      }

    }


    //If there was a failed validator,  check if any updates to the state objects corrected the invalid messages
    //We only need to validaste this if user tried to submit but a validator failed
    if (!failedSubmitWasPressed) return;

    //If it gets here then user tried to submit data but failed a validator
    //Now we check if any invalid state (of Data) was corrected, and also if a valid state of data has been invalidated
    if (!isValidated()) {
      setFailedSubmitWasPressed(true);
    }

  }, [
    rootBusinessInfoApplicationData,
    targetCotPercentageData,
    targetCotPercentageMedData,
    targetBusinessInfoAltSite,
    selectedSpokenLanguages,
    dailyHoursOfOperation,
    applicationId,
    failedSubmitWasPressed,
    authInfo
  ]);

  useEffect(() => {
    if (memberApplication.businessworkflow) {
      setbusinessInfocurrentWorkflow(pageWorkflow[memberApplication.businessworkflow]);
    }
    if (memberApplication.id) {
      if (authInfo) {
        initfetchBusinessInfo()
      }
    }
  }, [memberApplication.id, memberApplication.businessworkflow])

  const setAltPassedValidator = (list) => {
    if (businessInfocurrentWorkflow?.ControlName == "Alt") {
      if(businessInfocurrentWorkflow?.Required)
      {
      if (list.length > 0) {
        setAltValidationPassed(true);
        return true;
      } else { setAltValidationPassed(false); 
        return false;
      }

    } else { setAltValidationPassed(true);
      return true;
    }
  }
  else
  {
    setAltValidationPassed(true);
      return true;
  }
  };

  function initfetchBusinessInfo() {

    async function fetchCOTService() {
      const res = await memberAPI.get("api/MemApp/FetchMemberAppService", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });

      const data = res.data[0];
      setSelectedClassOfTrade(data.ClassOfTradeID);
    }
    // //Get the business information data for existing member application
    async function fetchBusinessInfo() {
      setIsLoading((prev) => true);

      //Get the business information data for existing member application
      //alert("initfetchBusinessInfo");
      const response = await memberAPI.get(
        "api/BusinessInfo2/FetchBusinessInfo",
        {
          params: {
            applicationId: applicationId,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      let rootData;

      if (!response.data.BusinessInfo) {
        rootData = {
          applicationId: applicationId,
          numberBedsAndPharmacyServices: 0,
          numberFacilitiesAndPharmacyServices: 0,
          AnnualPharmacyRevenue: 0,
          AnnualMedSurgRevenue: 0,
          AnnualDMERevenue: 0,
          isAllDayOperation: false,
        };
      } else {
        rootData = {
          applicationId: applicationId,
          numberBedsAndPharmacyServices:
            response.data.BusinessInfo.BedsPharmacyServices,
          numberFacilitiesAndPharmacyServices:
            response.data.BusinessInfo.FacilitiesPharmacyServices,
          isAllDayOperation: response.data.BusinessInfo.IsAllDayOperation,

          AnnualPharmacyRevenue: response.data.BusinessInfo.AnnualPharmacyRevenue,
          AnnualMedSurgRevenue: response.data.BusinessInfo.AnnualMedSurgRevenue,
          AnnualDMERevenue: response.data.BusinessInfo.AnnualDMERevenue,
        };
      }

      setRootBusinessInfoApplicationData(rootData);

      let businessInfoCOTPercentages =
        response.data.BusinessInfoTargetCOTPercentages.map((item) => {
          return {
            applicationId: item.ApplicationId,
            cot: item.COT,
            percentage: item.Percentage,
            description: item.Description,
          };
        });

      setTargetCotPercentageData(businessInfoCOTPercentages);

      //HEM-MED/Medical Distributor COT

      let businessInfoMedCOTPercentages =
        response.data.BusinessInfoHMEMed.map((item) => {
          return {
            applicationId: item.ApplicationId,
            cot: item.ServiceId,
            percentage: item.Percentage,
            description: item.Description,
          };
        });

      setTargetCotPercentageMedData(businessInfoMedCOTPercentages);

      let businessInfoAltSite =
        response.data.BusinessInfoAltSite.map((item) => {
          return {
            applicationId: item.ApplicationId,
            ServiceId: item.ServiceId
          };
        });

      setTargetBusinessInfoAltSite(businessInfoAltSite);

      let hoursOfOperation = BusinessInfoDailyHoursOfOperation.map((item) => {
        const thisDay = response.data.BusinessInfoHoursOfOperation.find(
          (t) => t.DayOfWeek === item.dayOfWeek
        );
        if (!thisDay) return { ...item, applicationId: applicationId };

        return {
          applicationId: thisDay.ApplicationId,
          isActive: thisDay.IsActive,
          dayOfWeek: thisDay.DayOfWeek,
          fromHour: thisDay.StartHour,
          fromMin: thisDay.StartMin,
          fromAmPm: thisDay.StartAmPm,
          toHour: thisDay.EndHour,
          toMin: thisDay.EndMin,
          toAmPm: thisDay.EndAmPm,
        };
      });
      setDailyHoursOfOperation(hoursOfOperation);

      let spokenLanguages = response.data.BusinessInfoSpokenLanguages.map(
        (item) => {
          return {
            applicationId: item.ApplicationId,
            languageId: item.LanguageId,
            otherLanguageDescription: item.OtherLanguageName,
          };
        }
      );

      setSelectedSpokenLanguages(spokenLanguages);

      setAllCots(response.data.COT);

      setIsLoading((prev) => false);
    }
    fetchCOTService();
    fetchBusinessInfo();
  }

  const toViewModelMapper = () => {
    const userId = authInfo.userEmail;

    let businessInfo = {
      ApplicationId: Number(applicationId),
      BedsPharmacyServices: Number(
        rootBusinessInfoApplicationData.numberBedsAndPharmacyServices
      ),
      FacilitiesPharmacyServices: Number(
        rootBusinessInfoApplicationData.numberFacilitiesAndPharmacyServices
      ),
      IsAllDayOperation: Boolean(
        rootBusinessInfoApplicationData.isAllDayOperation
      ),
      AnnualPharmacyRevenue: Number(
        rootBusinessInfoApplicationData.AnnualPharmacyRevenue
      ),
      AnnualMedSurgRevenue: Number(
        rootBusinessInfoApplicationData.AnnualMedSurgRevenue
      ),
      AnnualDMERevenue: Number(
        rootBusinessInfoApplicationData.AnnualDMERevenue
      ),
    };

   
    let BusinessInfoHMEMed = targetCotPercentageMedData.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        ServiceId: Number(t.cot),
        Percentage: Number(t.percentage),
        Description: t.description,
        ModifiedByUser: userId,
      };
    });
    let BusinessInfoAltSite = targetBusinessInfoAltSite.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        ServiceId: Number(t.ServiceId),
        ModifiedByUser: userId,
      };
    });
    let businessInfoTargetCOTPercentages = targetCotPercentageData.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        COT: Number(t.cot),
        Percentage: Number(t.percentage),
        Description: t.description,
        ModifiedByUser: userId,
      };
    });

    let businessInfoSpokenLanguages = selectedSpokenLanguages.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        LanguageId: Number(t.languageId),
        OtherLanguageName: t.otherLanguageDescription,
        ModifiedByUser: userId,
      };
    });

    let businessInfoHoursOfOperation = dailyHoursOfOperation.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        DayOfWeek: Number(t.dayOfWeek),
        IsActive: Boolean(t.isActive),
        StartHour: String(t.fromHour),
        StartMin: String(t.fromMin),
        StartAmPm: String(t.fromAmPm),
        EndHour: String(t.toHour),
        EndMin: String(t.toMin),
        EndAmPm: String(t.toAmPm),
      };
    });

    let viewModel;
    switch (GetApplicationType(memberApplication.service)) {
      case "LTC":
        viewModel = {
          businessInfo,
          businessInfoTargetCOTPercentages,
          businessInfoSpokenLanguages,
          businessInfoHoursOfOperation,
        };
        break;
      case "GPO":
        viewModel = {
          businessInfo,
          businessInfoTargetCOTPercentages,
          // businessMedInfo,
          BusinessInfoHMEMed,
          BusinessInfoAltSite
        };
        break;
      default:
        viewModel = null; //should never get here
    }

    return viewModel;
  };

  const isValidated = () => {
    let result;

    switch (GetApplicationType(memberApplication.service)) {
      case "LTC":
        result =
          cotTotalsValidationPassed &
          cotTotalsOtherDescriptionEnteredValidationPassed &
          spokenLanguagesValidationPassed &
          hoursOfOperationValidationPassed;
        break;
      case "GPO":
        result =
          cotTotalsValidationPassed &
          cotTotalsOtherDescriptionEnteredValidationPassed &
          AltValidationPassed;

        break;
      default:
        result = false; //should never get here
    }

    return result;
  };

  async function updateBusinessInfo() {
    if (memberApplication.isReadOnly) {
      routeChange("/PharmacyPrimaryContact");
    } else if (!isValidated()) {
      setFailedSubmitWasPressed(true);
    } else if (!setAltPassedValidator(targetBusinessInfoAltSite)) {
      setFailedSubmitWasPressed(true);
    }
    else {

      let viewModel = toViewModelMapper();

      try {
        const response = await memberAPI.post(
          "api/BusinessInfo2/UpdateBusinessInfo",
          viewModel,
          { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
        );

        routeChange("/PharmacyPrimaryContact");
      } catch (err) {
        console.log(err);
      }
    }

  }

  const history = useHistory();

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onResetClick(e) {
    e.preventDefault();

    setRootBusinessInfoApplicationData({});
    setFailedSubmitWasPressed(false);
  }

  function onBackClick(e) {
    e.preventDefault();
    routeChange("/MemberDemographics");
  }

  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >


        {
          (businessInfocurrentWorkflow?.ControlName == "LTCOmnicare") && (
            <div className="row">
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Number of Beds/Patients Pharmacy Services
                </label>
                <div className="col-sm-3 ">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="numberBedsAndPharmacyServices"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.numberBedsAndPharmacyServices ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;

                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        numberBedsAndPharmacyServices: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>


              </div>
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Number of Facilities Pharmacy Services
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="numberFacilitiesAndPharmacyServices"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.numberFacilitiesAndPharmacyServices ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;

                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        numberFacilitiesAndPharmacyServices: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>


              </div>
              <BusinessInfoCotPercentages disabled={memberApplication.isReadOnly} />
            </div>

          )}

        {
          (businessInfocurrentWorkflow?.ControlName == "HomeMedical") && (
            <div className="row">
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Annual Pharmacy Revenue
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="AnnualPharmacyRevenue"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.AnnualPharmacyRevenue ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;

                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        AnnualPharmacyRevenue: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Annual Med/Surg Revenue
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="AnnualMedSurgRevenue"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.AnnualMedSurgRevenue ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;

                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        AnnualMedSurgRevenue: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Annual DME Revenue
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="AnnualDMERevenue"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.AnnualDMERevenue ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        AnnualDMERevenue: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <BusinessInfoMedHME disabled={memberApplication.isReadOnly}
                required={businessInfocurrentWorkflow?.Required}  />
              </div>
            </div>
          )}

        {
          (businessInfocurrentWorkflow?.ControlName == "Alt") && (
            <BusinessInfoAltSite disabled={memberApplication.isReadOnly}  required={businessInfocurrentWorkflow?.Required}/>
          )}


        {
          (businessInfocurrentWorkflow?.ControlName == "LTCNetwork") && (

            <div className="row">
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Number of Beds/Patients Pharmacy Services
                </label>
                <div className="col-sm-3 ">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="numberBedsAndPharmacyServices"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.numberBedsAndPharmacyServices ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;

                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        numberBedsAndPharmacyServices: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>


              </div>
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label-sm">
                  Number of Facilities Pharmacy Services
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="numberFacilitiesAndPharmacyServices"
                    placeholder=""
                    disabled={memberApplication.isReadOnly}
                    value={
                      rootBusinessInfoApplicationData.numberFacilitiesAndPharmacyServices ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;

                      let updated = {
                        ...rootBusinessInfoApplicationData,
                        numberFacilitiesAndPharmacyServices: e.target.value,
                      };
                      setRootBusinessInfoApplicationData(updated);
                    }}
                  />
                </div>
              </div>
              <BusinessInfoCotPercentages disabled={memberApplication.isReadOnly} 
              />
            </div>

          )}

        {businessInfocurrentWorkflow?.ControlName == "LTCNetwork" && (
          <div className="row">
            <div className="col">
              <BusinessInfoSpokenLanguages disabled={memberApplication.isReadOnly} />
            </div>
            <div className="col">{<BusinessInfoHoursOfOperation disabled={memberApplication.isReadOnly} />}</div>
          </div>
        )}


        <hr />
        <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
          <button disabled={memberApplication.isReadOnly} className="btn btn-primary" onClick={onResetClick}>
            Reset
          </button>
          <button className="btn btn-primary" onClick={onBackClick}>
            Back
          </button>
          <button
            className="btn btn-primary"
            onClick={updateBusinessInfo}
            type="submit"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default BusinessInfoForm;
