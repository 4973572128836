

import { useEffect, useState } from "react";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { memberAPI } from "../../utility/axios";

export function MemberApplicationsForm(props) { 
    const { onSubmit } = props
    let { authInfo } = useAzureAuthentication();

    const [classOfTrades, setClassOfTrades] = useState([])
    const [selectedClassOfTrade, setSelectedClassOfTrade] = useState(0)

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [selectedService, setSelectedService] = useState(0)
    const [dea, setDea] = useState('')
    const [ncpdp, setNcpdp] = useState('')
    const [name, setName] = useState('')
    const [hin, setHin] = useState('')
    const [npi, setNpi] = useState('')

    useEffect(() => {
        async function getClassOfTrades() {
          const res = await memberAPI.get("api/COT/FetchAllClassOfTrades", {
            headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
          });
          setClassOfTrades(res.data);
        }

        async function getUsers() {
            const res = await memberAPI.get("api/User/FetchUsers", {
                headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
              });
              setUsers(res.data);
              setSelectedUser(authInfo.userEmail)
        }
    
        if (authInfo) {
            getClassOfTrades()
            getUsers()
            initMemberApplications()
            
        }
      }, [authInfo]);

    async function initMemberApplications() {
        const formData = {
            selectedUser: authInfo.userEmail,
            dea:  "",
            ncpdp: "",
            name: "",
            selectedService: 0,
            selectedClassOfTrade: 0,
            hin: "",
            npi : "",
            userAccessToken: authInfo.userAccessToken
        }
        onSubmit(formData)
    }

    function onSearch(e) {
        e.preventDefault()
        const formData = {
            selectedUser: selectedUser,
            dea:  dea,
            ncpdp: ncpdp,
            name: name,
            selectedService: selectedService,
            selectedClassOfTrade: selectedClassOfTrade,
            hin: hin,
            npi : npi,
            userAccessToken: authInfo.userAccessToken
        }
        onSubmit(formData)
    }
      
    function onReset(e) {
        e.preventDefault()

        setSelectedUser(authInfo.userEmail)
        setDea('')
        setNcpdp('')
        setSelectedService(0)
        setSelectedClassOfTrade(0)
        setName('')
        setHin('')
        setNpi('')
        
        initMemberApplications()
    }

    function onClassOfTradeChange(e) {
        const value = parseInt(e.target.value);
        setSelectedClassOfTrade(value);
    }

    return (
        <form>
            <div className="mb-3 row">
                <div className="col-sm-2">
                    <label className="form-label-sm" htmlFor="user">User</label>
                    <select
                        id="user"
                        onChange={(e) => setSelectedUser(e.target.value)}
                        className="form-select form-select-sm"
                        value={selectedUser ?? ""}
                    >
                        <option value="">{"<--Not Selected-->"}</option>
                        {users.map((user) => {
                        return (
                            <option key={user} value={user}>
                                {user}
                            </option>
                        )
                        })}
                    </select>
                </div>
                <div className="col-md-1">
                    <label className="form-label-sm" htmlFor="dea">DEA</label>
                    <input
                        value={dea}
                        onChange={(e) => setDea(e.target.value.toUpperCase())}
                        type="text"
                        className="form-control form-control-sm uppercase"
                        id="dea"
                        placeholder="DEA"
                        maxLength="9"
                    />
                </div>
                <div className="col-sm-1">
                    <label className="form-label-sm" htmlFor="ncpdp">NCPDP</label>
                    <input
                        value={ncpdp}
                        onChange={(e) => setNcpdp(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        id="ncpdp"
                        placeholder="NCPDP"
                        maxLength="7"
                        pattern="[0-9]+"
                    />            
                </div>
                <div className="col-sm-3">
                    <label className="form-label-sm" htmlFor="name">Pharmacy / Provider Name</label>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        id="name"
                        placeholder="Pharmacy / Provider Name"
                    />            
                </div>
            </div>
            <div className="mb-3 row">

                <div className="col-sm-2">
                    <label className="form-label-sm" htmlFor="cot">Service</label>
                    <select
                        id="service"
                        onChange={(e) => setSelectedService(e.target.value)}
                        className="form-select form-select-sm"
                        value={selectedService}
                    >
                        <option value={0}>{"<--Not Selected-->"}</option>
                        <option key={1} value={1}>GPO</option>
                        <option key={2} value={2}>LTC Network</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    <label className="form-label-sm" htmlFor="cot">COT</label>
                    <select
                        id="cot"
                        onChange={onClassOfTradeChange}
                        className="form-select form-select-sm"
                        value={selectedClassOfTrade ?? ""}
                    >
                        <option value={0}>{"<--Not Selected-->"}</option>
                        {classOfTrades.map((classOfTrade) => {
                        return (
                            <option key={classOfTrade.TRID} value={classOfTrade.TRID}>
                            {classOfTrade.TRDESCRIPTION}
                            </option>
                        )
                        })}
                    </select>
                </div>
                <div className="col-sm-1">
                    <label className="form-label-sm" htmlFor="hin">HIN</label>
                    <input 
                        value={hin}
                        onChange={(e) => setHin(e.target.value)}
                        type="text" 
                        className="form-control form-control-sm" 
                        id="hin" 
                        placeholder="HIN"
                        maxLength="9"
                    />
                </div>
                <div className="col-sm-1">
                    <label className="form-label-sm" htmlFor="npi">NPI</label>
                    <input
                        value={npi}
                        onChange={(e) => setNpi(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        id="npi"
                        placeholder="NPI"
                        maxLength="10"
                        pattern="[0-9]+"
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className='col-auto'>
                    <button type="button" className="btn btn-primary" onClick={onSearch}>
                        Search
                    </button>
                </div>
                <div className='col-auto'>
                    <button type="button" className="btn btn-primary" onClick={onReset}>
                        Reset
                    </button>
                </div>
            </div>
        </form>
    )
}