export function CorporateNameInput(props) {

    const { value, onChange, disabled, workflow } = props
    const { label , required} = workflow ?? {}

    return (
        <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="corporate-name">
          {label}
        </label>
        <div className="col-sm-3">
          <input
            value={value}
            onChange={onChange}
            type="text"
            className="form-control form-control-sm"
            id="corporate-name"
            placeholder={label}
            maxLength="100"
            required={required}
            disabled={disabled}
            />
          <div className="invalid-feedback">Please enter a {label}.</div>
        </div>
      </div>
    )
}