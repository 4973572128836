import * as React from "react";
export const DistributorWholeCommandCell = (props) => {
  const { dataItem, isEditMode, setIsEditMode } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.idLocal === -1;
  return inEdit ? (
    <td className="k-command-cell">
      <button
        className="btn btn-primary"
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
      >
        Save
      </button>
      <button
        className="btn btn-secondary ms-2"
        onClick={() => props.cancel(dataItem)}
      >
        Cancel
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="btn btn-primary"
        onClick={() => props.edit(dataItem)}
        disabled={isEditMode}
      >
        Edit
      </button>
      <button
        className="btn btn-danger ms-2"
        onClick={() =>
          window.confirm("Are you sure?") && props.remove(dataItem)
        }
        disabled={isEditMode}
      >
        Delete
      </button>
    </td>
  );
};
