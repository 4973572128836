import { useEffect, useContext } from "react";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";

import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { MemberServiceState } from "./MemberServiceContext";
//import { MemberProgramsConstant as MemberPrograms } from "./MemberServiceConstant";
import { AltSiteProgramOptions } from "../../helpers/AppDataHelpers";
export default function MemberServiceExpansion(props) {
  const {
    targetTypeData,
    setTargetTypeData,
    ExpValidationPassed,
    setExpValidationPassed,
    selectedClassOfTrade,
    selectedType,
    setselectedType,
    memAppProgram,
    setmemAppProgram,
  } = MemberServiceState();

  const { disabled } = props;

  const memberApplication = useContext(MemberApplicationContext);

  let { authInfo } = useAzureAuthentication();

  let altSiteProgramOptions = AltSiteProgramOptions();

  const setExpPassedValidator = (list) => {
    let isValid;
    if (list.length > 0) isValid = true;
    else isValid = false;

    //Check if current validationPassed value already set to our isValid - if so no need tro do anything.
    if (ExpValidationPassed == isValid) return;

    //If it gets here the validation value is not same as isValid - we need to set it
    setExpValidationPassed(isValid);
  };

  function onTypeChange(e) {
    const value = parseInt(e.target.value);
    setselectedType(value);
  }
  const cotTypeUpdated = (programId, isSelected) => {
    let newList = [];

    if (isSelected == true) {
      let exists = targetTypeData.some((t) => t.TypeId == programId);

      if (!exists) {
        const newrecord = {
          ApplicationID: Number(memberApplication.id),
          ClassOfTradeID: Number(selectedClassOfTrade),
          Type: Number(selectedType),
          TypeId: Number(programId),
        };

        let updatednewrecord = [...targetTypeData, newrecord];

        setTargetTypeData(updatednewrecord);
        setExpPassedValidator(updatednewrecord);
        return;
      }
    } else {
      newList = targetTypeData.filter((item) => item.TypeId != programId);
      setTargetTypeData(newList);
      setExpPassedValidator(newList);
    }
  };

  //MOA-93 added 2 functions below to implement requirements specified in ticket:
  //Limit data to Ids which exist in AppData/AltSiteProgramOptions
  //Also, if the Id's required (forced) is set to true, force it to be selected AND disable the checkbox so it cannot be unselected
  const isAltSiteProgram = (ProgramId) => {
    const result = altSiteProgramOptions.findIndex(
      (t) => t.ProgramId === ProgramId
    );
    return result >= 0;
  };

  const isAltSiteProgramRequired = (ProgramId) => {
    const result = altSiteProgramOptions.findIndex(
      (t) => t.ProgramId === ProgramId && t.IsRequired === true
    );

    let IsRequired = result >= 0;

    //If program is required, ensure it is in the list
    if (IsRequired) ensureRequiredProgramInList(ProgramId);

    return IsRequired;
    //return result >= 0;
  };

  const ensureRequiredProgramInList = (programId) => {
    let exists = targetTypeData.some((t) => t.TypeId == programId);

    //Add the required program if not already in the list
    if (!exists) {
      const newrecord = {
        ApplicationID: Number(memberApplication.id),
        ClassOfTradeID: Number(selectedClassOfTrade),
        Type: Number(selectedType),
        TypeId: Number(programId),
      };

      let updatednewrecord = [...targetTypeData, newrecord];

      setTargetTypeData(updatednewrecord);
    }
  };

  const isProgramActive = (ProgramId) => {
    const result = targetTypeData.findIndex((t) => t.TypeId === ProgramId);
    return result >= 0;
  };

  return (
    <div>
      <hr />
      <div className="mb-3 row">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            onChange={onTypeChange}
            type="radio"
            name="service1"
            id="primary"
            value="1"
            checked={selectedType === 1}
            disabled={memberApplication.isReadOnly}
          />
          <label className="form-check-label" htmlFor="Primary">
            Primary
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            onChange={onTypeChange}
            type="radio"
            name="service2"
            id="secondary"
            value="2"
            checked={selectedType === 2}
            disabled={memberApplication.isReadOnly}
          />
          <label className="form-check-label" htmlFor="Secondary">
            Secondary
          </label>
        </div>
      </div>

      <div className="mb-3 row">
        <div className="row">
          <label className="col-sm-2">Select Program(s)</label>
        </div>
      </div>

      {memAppProgram.map((item) => {
        return (
          isAltSiteProgram(item.MGR_GRID) && (
            <ul
              key={"SpokenLang" + item.MGR_GRID}
              className="list-group list-group-flush list-group-horizontal "
            >
              <li className="list-group-item">
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value={`${item.MGR_GRID}`}
                  aria-label="..."
                  checked={
                    isProgramActive(item.MGR_GRID) ||
                    isAltSiteProgramRequired(item.MGR_GRID)
                  }
                  disabled={disabled || isAltSiteProgramRequired(item.MGR_GRID)}
                  onChange={(e) => {
                    cotTypeUpdated(item.MGR_GRID, e.target.checked);
                  }}
                />
                {item.GR_DESC}
              </li>
            </ul>
          )
        );
      })}

      {!ExpValidationPassed && (
        <div className="text-danger nowrap">Value is required</div>
      )}
    </div>
  );
}
