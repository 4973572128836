import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import Sidebar from "./Sidebar";
export default function Layout(props) {
  return (
    <>
      <Header />
      <Sidebar />
      {/* <div className="flex-shrink-0"> */}

      <div className="container-fluid">
        <Main>{props.children}</Main>
      </div>
      <Footer />
    </>
  );
}
