import LoggedInAs from "../components/Authentication/LoggedInAs";

export default function Header(props) {
  return (
    <header className="navbar navbar-dark sticky-top bg-primary text-white flex-md-nowrap p-0 shadow">
      <span className="navbar-brand col-md-3 col-lg-2 me-0 px-3">Mercury</span>
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="navbar-nav text-nowrap px-3">
        <LoggedInAs />
      </div>
    </header>
  );
}
