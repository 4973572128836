

export function RebateAddressCheckbox(props) {

    return props.service === 1 && (
        <div className="form-check">
            <input className="form-check-input" type="checkbox" disabled={props.disabled} value="" onChange={props.onChange} id="alternateRebateAddressCheck" checked={props.hasAlternateRebateAddress}/>
            <label className="form-check-label" htmlFor="alternateRebateAddressCheck">
              Rebate Mailing Address
            </label>
          </div>
    )
}