import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";

import {
  ShortDateFormat,
  StringToDate,
  DateWithoutTime,
} from "../../helpers/DateHelpers";

const PharmacyInfoLicenses = ({
  dataLicenses,
  setDataLicenses,
  failedSubmitWasPressed,
  dataLicensesValidated,
  editItem,
  clearEdit,
  isEditMode,
  isReadOnly,
  setEdit,
  selectedCOT,
}) => {
  const {
    control,
    register,
    unregister,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const stateOptions = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const onSubmit = (values) => {
    if (!values || Object.keys(values).length === 0) return;

    let updatedPharmacyLicenses;

    if (values.editId < 0) {
      const maxLocalId =
        !dataLicenses || dataLicenses.length == 0
          ? 0
          : Math.max(...dataLicenses.map((t) => t.IdLocal));

      const newRecord = {
        IdLocal: maxLocalId + 1,
        licenseNum: values.editLicenseNum,
        state: values.editState,
        expiration: values.editexpiration,
      };

      updatedPharmacyLicenses = [...dataLicenses, newRecord];

      //alert(JSON.stringify(updatedPharmacyLicenses));
    } else {
      updatedPharmacyLicenses = dataLicenses.map((item) => {
        if (item.IdLocal == values.editId)
          return {
            ...item,
            licenseNum: values.editLicenseNum,
            state: values.editState,
            expiration: values.editexpiration,
          };

        return item;
      });
    }

    turnAddingViewOff();
    reset();

    setDataLicenses(updatedPharmacyLicenses);
  };

  const DeletePharmacyLicense = (id) => {
    const updatedDataPharmacist = dataLicenses.filter(
      (item) => item.IdLocal != id
    );
    setDataLicenses(updatedDataPharmacist);
  };

  const [addingView, setAddingView] = useState(false);

  const turnAddingViewOff = () => {
    unRegisterAll();
    clearEdit();
    setAddingView(false);
  };

  //If this is not done the useform hook will retain values and cause many unwanted effects
  //There may be a better way to do it but didn't find it yet
  const unRegisterAll = () => {
    unregister("editLicenseNum");
    unregister("editState");
    unregister("editExpiration");
    unregister("editId");
  };

  const EditView = (id, licenseNum, state, expiration) => {
    const processedDate = DateWithoutTime(
      expiration == "" ? new Date() : StringToDate(expiration)
    );

    return (
      <tr key={id}>
        <td>
          <input
            type="hidden"
            value={id}
            name="editId"
            {...register("editId")}
          />

          <input
            type="text"
            className="form-control form-control-sm uppercase"
            defaultValue={licenseNum}
            name="editLicenseNum"
            {...register("editLicenseNum", {
              required: "Value is required",
            })}
          />

          {errors.editLicenseNum && (
            <p className="text-danger">{errors.editLicenseNum.message} </p>
          )}
        </td>
        <td>
          <Controller
            control={control}
            name="editState"
            defaultValue={state}
            {...register("editState", {
              required: "Selection is required",
            })}
            render={() => (
              <ComboBox
                clearButton={false}
                style={{ width: "120px" }}
                data={stateOptions}
                defaultValue={state}
                onChange={(e) => {
                  setValue("editState", e.value);
                }}
              />
            )}
          />

          {errors.editState && (
            <p className="text-danger">{errors.editState.message} </p>
          )}
        </td>
        <td>
          <Controller
            control={control}
            name="editexpiration"
            defaultValue={processedDate}
            {...register("editexpiration", {
              valueAsDate: true,
              required: "Not a valid date",
              validate: {
                isTodayOrLater: (i) => {
                  let enteredDate = StringToDate(i);
                  var currentDate = new Date();

                  return (
                    DateWithoutTime(enteredDate) >=
                      DateWithoutTime(currentDate) ||
                    "Cannot be earlier than today"
                  );
                },
              },
            })}
            render={({ field }) => (
              <DatePicker
                defaultValue={processedDate}
                placeholderText="Select date"
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              />
            )}
          />

          {errors.editexpiration && (
            <p className="text-danger">{errors.editexpiration.message} </p>
          )}
        </td>
        <td className="buttoncell">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
          <button
            className="btn btn-secondary ms-2"
            onClick={(e) => {
              e.preventDefault();

              reset();
              turnAddingViewOff();
            }}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {(selectedCOT?.ControlName != "Alt") && (
        <h6>Pharmacy State/Provider License *</h6>
        )}
        {(selectedCOT?.ControlName == "Alt") && (
        <h6>Pharmacy State/Provider License </h6>
        )}
        {failedSubmitWasPressed && !dataLicensesValidated && (
          <div className="text-danger">
            At least one record needs to be entered
          </div>
        )}

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">License</th>
              <th scope="col">State</th>
              <th scope="col">Expiration Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataLicenses.map((t) => {
              return editItem.editName == "licenses" &&
                editItem.editId == t.IdLocal ? (
                EditView(editItem.editId, t.licenseNum, t.state, t.expiration)
              ) : (
                <tr key={t.IdLocal}>
                  <td>{t.licenseNum}</td>
                  <td>{t.state}</td>
                  <td>{ShortDateFormat(t.expiration)}</td>
                  {!isReadOnly && (
                    <td className="buttoncell">
                      <button
                        className="btn btn-primary"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          e.preventDefault();

                          setEdit("licenses", t.IdLocal);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          if (window.confirm("Are you sure?")) {
                            e.preventDefault();

                            DeletePharmacyLicense(t.IdLocal);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
            {addingView && EditView(-1, "", "", "")}
          </tbody>
        </table>

        <button
          className="btn btn-primary"
          disabled={isEditMode() || isReadOnly}
          onClick={(e) => {
            e.preventDefault();
            setEdit("licenses", -1);
            unRegisterAll();
            setAddingView(true);
          }}
        >
          Add New License
        </button>
      </form>
    </>
  );
};

export default PharmacyInfoLicenses;
