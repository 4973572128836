import { useEffect, useState } from "react";

import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";

export function AccountRankSelector(props) {
  const [accountRank, setAccountRank] = useState([]);

  let { authInfo } = useAzureAuthentication();


  useEffect(() => {
    let data = [
        { label: "0", value: "" },
        { label: "1", value: "A" },
        { label: "2", value: "B" },
        { label: "3", value: "C" },
        { label: "4", value: "S" },
        { label: "5", value: "P" },
    ]
      setAccountRank(data);
  }, [props.selectedAccountRank]);

  return (
    <div className="mb-3 row">
      <label className="col-sm-2 col-form-label-sm" htmlFor={props.id}>
        {props.label}
      </label>
      <div className="col-sm-3">
        <select
          id={props.id}
          onChange={props.onChange}
          className="form-select form-select-sm"
          value={props.selectedAccountRank}
          required = {props.required}
          disabled={props.disabled}
        >
          <option value="">{"<--Not Selected-->"}</option>
          {accountRank.map((accountRank, i) => {
            return (
              <option key={i} value={accountRank.value}>
                {accountRank.value}
              </option>
            );
          })}
        </select>
        <div className="invalid-feedback">Please select a rank</div>
      </div>
    </div>
  );
}
