import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

function useAzureAuthentication() {
  const { instance, accounts } = useMsal();

  const [authInfo, setAuthInfo] = useState(null);

 

  useEffect(() => {
 
    if(accounts && accounts.length > 0) {
      fetchAuthInfo()
    }
  }, [accounts])


  async function fetchAuthInfo() {
    async function getAuthInfo() {
      const response = await instance.acquireTokenSilent({...loginRequest, account: accounts[0]})
      const result = {
        userEmail: accounts[0].username,
        userAccessToken: response.accessToken
      }
  
      setAuthInfo(result)
    }

    await getAuthInfo()
  }
  
  const login = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  const logout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: "/loggedout" });
  };

  return { authInfo, login, logout };
}

export default useAzureAuthentication;
