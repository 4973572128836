import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { useEffect, useState, useRef, useContext } from "react";

import { maskPhone } from "../../utility/mask";
import { StateSelector } from "../../components/StateSelector";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { memberAPI } from "../../utility/axios";
import { GetEmailPattern } from "../../helpers/ValidationHelper";

export default function AlternateAddressForm(props) {
  const location = useLocation();

  const memberApplication = useContext(MemberApplicationContext);
  const { id, addressType } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [fax, setFax] = useState("");
  const [phone, setPhone] = useState("");
  const [extension, setExtension] = useState("");
  const [email, setEmail] = useState("");
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  const [isDocusignContact, setIsDocusignContact] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [alternateAddressId, setAlternateAddressId] = useState(null);
  const formRef = useRef();
  const history = useHistory();
  let { authInfo } = useAzureAuthentication();

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  useEffect(() => {
    if (id !== memberApplication.id) {
      memberApplication.onIDChange(id);
    }

    if(memberApplication.id) {
      if (authInfo) {
        initAlternateAddress()
      }
    }
  }, [authInfo, memberApplication.id]);






  function initAlternateAddress() {
    async function fetchAlternateAddress() {
      const res = await memberAPI.get(
        "api/MemAppAltAddress/FetchAlternateAddress",
        {
          params: {
            id: id,
            addressType: addressType,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      if (res.data.length) {
        const data = res.data[0];

        setAddress(data.Address ?? "");
        setAddressTwo(data.AddressTwo ?? "");
        setCity(data.City ?? "");
        setEmail(data.Email ?? "");
        setExtension(data.Extension ?? "");
        setFax(data.Fax ?? "");
        setFirstName(data.FirstName ?? "");
        setIsDocusignContact(data.IsDocusign);
        setIsPrimaryContact(data.IsPrimary);
        setLastName(data.LastName ?? "");
        setPhone(data.Phone ?? "");
        setState(data.State ?? "");
        setTitle(data.Title ?? "");
        setZip(data.Zip ?? "");
        setAlternateAddressId(data.Id);
      }
    }

    fetchAlternateAddress();
  }
  function onResetClick(e) {
    e.preventDefault();
    initAlternateAddress();
  }

  function onBackClick(e) {
    e.preventDefault();

    if (location.pathname.includes("Corporate") || !memberApplication.hasAlternateCorporateAddress) {
      routeChange("/MemberDemographics");
    } else if ((location.pathname.includes("Rebate") || location.pathname.includes("Network")) 
                && memberApplication.hasAlternateCorporateAddress) {
      routeChange("/MemberAlternateCorporateAddress");
    } 
  }

  function routeToNextPage() {
    if (location.pathname.includes("Corporate")) {
      if (memberApplication.hasAlternateRebateAddress) {
        routeChange("/MemberAlternateRebateAddress")
      } else if (memberApplication.hasAlternateNetworkAddress) {
        routeChange("/MemberAlternateNetworkAddress")
      } else {
        routeChange("/BusinessInfo");
      }
    } else if (
      location.pathname.includes("Rebate") ||
      location.pathname.includes("Network")
    ) {
      routeChange("/BusinessInfo");
    }
  }
  function onNextClick(e) {
    e.preventDefault();

    async function updateAlternateAddress() {
      await memberAPI.post(
        "api/MemAppAltAddress/UpdateAlternateAddress",
        {
          user: authInfo.userEmail,
          address,
          addressTwo,
          city,
          email,
          extension,
          fax,
          firstName,
          lastName,
          isDocusign: isDocusignContact,
          isPrimary: isPrimaryContact,
          phone,
          state,
          title,
          zip,
          id: alternateAddressId,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    async function insertAlternateAddress() {
      await memberAPI.post(
        "api/MemAppAltAddress/InsertAlternateAddress",
        {
          user: authInfo.userEmail,
          address,
          addressTwo,
          city,
          email,
          extension,
          fax,
          firstName,
          lastName,
          isDocusign: isDocusignContact,
          isPrimary: isPrimaryContact,
          phone,
          state,
          title,
          zip,
          maid: id,
          addressType,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    setIsValidated(true);

    if (formRef.current.checkValidity()) {
      // update
      if (alternateAddressId) {
        updateAlternateAddress();
      } else {
        insertAlternateAddress();
      }

      routeToNextPage();
    }
  }

  function onExtensionChange(e) {
    if (isNaN(e.target.value)) {
      return
    }

    setExtension(e.target.value)
  }

  function onZipChange(e) {
    if (isNaN(e.target.value)) {
      return
    }

    setZip(e.target.value)
  }

  return (
    <form
      ref={formRef}
      className={isValidated ? "was-validated" : ""}
      noValidate
    >
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="first-name">
          First Name
        </label>
        <div className="col-sm-3">
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="first-name"
            placeholder="First Name"
            required
            maxLength="50"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a First Name.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="last-name">
          Last Name
        </label>
        <div className="col-sm-3">
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="last-name"
            placeholder="Last Name"
            required
            maxLength="50"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a Last Name.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="title">
          Title
        </label>
        <div className="col-sm-3">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="title"
            placeholder="Title"
            required
            maxLength="50"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a Title.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="address">
          Address
        </label>
        <div className="col-sm-3">
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="address"
            placeholder="Address"
            required
            maxLength="50"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter an Address.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="address-two">
          Address 2
        </label>
        <div className="col-sm-3">
          <input
            value={addressTwo}
            onChange={(e) => setAddressTwo(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="address-two"
            placeholder="Address 2"
            maxLength="50"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="city">
          City
        </label>
        <div className="col-sm-3">
          <input
            value={city}
            onChange={(e) => setCity(e.target.value.toUpperCase())}
            type="text"
            className="form-control form-control-sm"
            id="city"
            placeholder="City"
            maxLength="100"
            required
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a city.</div>
        </div>
      </div>
      <StateSelector
        selectedState={state}
        onChange={(e) => setState(e.target.value)}
        disabled={memberApplication.isReadOnly}
      />
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="zip">
          Zip
        </label>
        <div className="col-sm-3">
          <input
            value={zip}
            onChange={onZipChange}
            type="text"
            className="form-control form-control-sm"
            id="zip"
            placeholder="Zip"
            minLength="5"
            maxLength="5"
            required
            pattern={"[0-9]+"}
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a Zip.</div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="fax">
          Fax
        </label>
        <div className="col-sm-3">
          <input
            value={fax}
            onChange={(e) => setFax(maskPhone(e.target.value, fax))}
            type="text"
            className="form-control form-control-sm"
            id="fax"
            placeholder="Fax"
            minLength="14"
            maxLength="14"
            pattern="[\(][0-9]{3}[\)][\ ][0-9]{3}[\-][0-9]{4}"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="phone">
          Phone
        </label>
        <div className="col-sm-3">
          <input
            value={phone}
            onChange={(e) => setPhone(maskPhone(e.target.value, phone))}
            type="text"
            className="form-control form-control-sm"
            id="phone"
            placeholder="Phone"
            minLength="14"
            maxLength="14"
            pattern="[\/(][0-9]{3}[\)][\ ][0-9]{3}[\-][0-9]{4}"
            required
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter a phone number.</div>
        </div>
        <label className="col-sm-2 col-form-label-sm" htmlFor="extn">
          Extn
        </label>
        <div className="col-sm-3">
          <input
            value={extension}
            onChange={onExtensionChange}
            type="text"
            className="form-control form-control-sm"
            id="extn"
            placeholder="Extn"
            maxLength="5"
            pattern={"[0-9]+"}
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="email">
          Email
        </label>
        <div className="col-sm-3">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control form-control-sm"
            id="email"
            placeholder="Email"
            pattern={GetEmailPattern()}
            required
            type="email"
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">Please enter an email.</div>

        </div>
      </div>
      <div className="mb-3 row">
        
        Primary Contact and Docusign Recipient designation will have to be made in Pharmacy Primary Contact page
        {/* Commenting out because we are moving ability to update primary and docusign to pharmacy primary contact page
          <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            onChange={(e) => setIsPrimaryContact(e.target.checked)}
            id="primary-contact"
            checked={isPrimaryContact}
            disabled={memberApplication.isReadOnly}
          />
          <label className="form-check-label" htmlFor="primary-contact">
            Primary Contact
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            onChange={(e) => setIsDocusignContact(e.target.checked)}
            id="docusign-contact"
            checked={isDocusignContact}
            disabled={memberApplication.isReadOnly}
          />
          <label className="form-check-label" htmlFor="docusign-contact">
            Docusign Recipient
          </label>
        </div>
        */}
      </div>

      <hr />
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button className="btn btn-primary" disabled={memberApplication.isReadOnly} onClick={onResetClick}>
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        <button className="btn btn-primary" onClick={onNextClick} type="submit">
          Next
        </button>
      </div>
    </form>
  );
}
