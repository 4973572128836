/* eslint-disable default-case */
import React, { useState, useEffect, useContext } from "react";
import { memberAPI } from "../../utility/axios";
import { useHistory } from "react-router-dom";

import { MultiSelect } from "@progress/kendo-react-dropdowns";

import LoadingPageItem from "../../components/LoadingPageItem";
import { GetApplicationType } from "../../helpers/AppDataHelpers";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { useForm, Controller } from "react-hook-form";
import "./PharmacyInfo.css";
import PharmacyInfoPharmacistInCharge from "./PharmacyInfoPharmacistInCharge";
import PharmacyInfoLicenses from "./PharmacyInfoLicenses";
import PharmacyInfoDeaCertificate from "./PharmacyInfoDeaCertificate";
import PharmacyInfoInsurancePolicy from "./PharmacyInfoInsurancePolicy";
import { pharmacyworkflow as pageWorkflow } from "./PharmacyInfoWorkFlow";
import { GetISODate } from "../../helpers/DateHelpers";

const PharmacyInfoForm = (props) => {
  const { id } = props;
  let { authInfo } = useAzureAuthentication();

  const memberApplication = useContext(MemberApplicationContext);

  const [applicationId, setApplicationId] = useState(id);

  const [editItem, setEditItem] = useState({});

  const [dataLicenses, setDataLicenses] = useState([]);
  const [dataPharmacist, setDataPharmacist] = useState([]);
  const [dataDeaCert, setDataDeaCert] = useState([]);
  const [dataInsurancePolicy, setDataInsurancePolicy] = useState([]);

  const [dataPharmacySoftwareVendors, setDataPharmacySoftwareVendors] =
    useState([]);

  const [failedSubmitWasPressed, setFailedSubmitWasPressed] = useState(false);

  const [dataLicensesValidated, setDataLicensesValidated] = useState();
  const [dataPharmacistValidated, setDataPharmacistValidated] = useState();
  const [dataDeaCertValidated, setDataDeaCertValidated] = useState();
  const [dataInsurancePolicyValidated, setDataInsurancePolicyValidated] =
    useState();

  const [isLoading, setIsLoading] = useState(true);
  const [RequiredFields , setRequiredFields] = useState(true);
  let rootData;
  const [pharmacyInfoCurrentworkflow, setpharmacyInfoCurrentworkflow] = useState(null);
  const {
    control,
    unregister,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    unRegisterAll();
    if (memberApplication.pharmacyworkflow) {
      setpharmacyInfoCurrentworkflow(pageWorkflow[memberApplication.pharmacyworkflow]);
    }

    if (memberApplication.id) {
      if (authInfo) {
        initialAPIFetch();
      }
    } else {
      memberApplication.onIDChange(id);
    }
  }, [authInfo, memberApplication.pharmacyworkflow]);

  useEffect(() => {
    if (memberApplication.id) {
      if (authInfo) {
        initialAPIFetch();
      }
    }
  }, [memberApplication.id]);
useEffect(()=>{
  if(pharmacyInfoCurrentworkflow!=null)
  {
  if(pharmacyInfoCurrentworkflow?.required)
  setRequiredFields(pharmacyInfoCurrentworkflow?.required);
  validateGridRecord();
  }
}, [dataLicenses, dataPharmacist, dataDeaCert, dataInsurancePolicy]);
  useEffect(() => {
    isValidated();
  }, [dataLicenses, dataPharmacist, dataDeaCert, dataInsurancePolicy]);

  const unRegisterAll = () => {
    unregister("editTaxId");
    unregister("editEmail");
    unregister("editNameOfOwner");
    unregister("editexpiration");
    unregister("softwareVendorIds");
    unregister("medicaId");
  };

  function initialAPIFetch() {
    // //Get the business information data for existing member application
    async function fetchPharmacyInfoLTC() {
      setIsLoading((prev) => true);

      //Get the business information data for existing member application
      //alert("initfetchBusinessInfo");
      const response = await memberAPI.get(
        "api/PharmacyInfo2/FetchPharmacyInfoLTC",
        {
          params: {
            applicationId: applicationId,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      let apiRootData;

      if (!response.data.PharmacyInfo) {
        apiRootData = {
          applicationId: applicationId,
          taxId: "",
          email: "",
          nameOfOwner: "",
          medicaId : "",
          medicare : ""
        };
      } else {
        apiRootData = {
          applicationId: applicationId,
          taxId: response.data.PharmacyInfo.TaxId,
          email: response.data.PharmacyInfo.PharmacyEmail,
          nameOfOwner: response.data.PharmacyInfo.OwnerName,
          
        };
      }

      //Settig the root data
      //Need to set it here or the Reset will not work
      //the Default value on the element itself will only apply on initial render - I therefore need to set it here in case a reset is clicked and we want the default values applied
      setValue("editTaxId", apiRootData.taxId);
      setValue("editEmail", apiRootData.email);
      setValue("editNameOfOwner", apiRootData.nameOfOwner);
      

      // setRootBusinessInfoApplicationData(rootData);

      let index = 1;
      let apiDataLicenses = response.data.PharmacyInfoLicenses.map((item) => {
        return {
          IdLocal: index++,
          //applicationId: hardcodedApplicationId,
          licenseNum: item.LicenseNum,
          state: item.State,
          expiration: item.Expiration,
        };
      });
      setDataLicenses(apiDataLicenses);

      index = 1;
      let apiDataPharmacist = response.data.PharmacyInfoPharmacistsInCharge.map(
        (item) => {
          return {
            IdLocal: index++,
            //applicationId: hardcodedApplicationId,
            licenseNum: item.LicenseNum,
            fullName: item.FullName,
            expiration: item.Expiration,
          };
        }
      );
      setDataPharmacist(apiDataPharmacist);

      index = 1;
      let apiDataDeaCert = response.data.PharmacyInfoDeaCertificates.map(
        (item) => {
          return {
            IdLocal: index++,
            //applicationId: hardcodedApplicationId,
            deaNum: item.DeaNumber,
            expiration: item.Expiration,
          };
        }
      );
      setDataDeaCert(apiDataDeaCert);

      index = 1;
      let apiDataInsurancePolicy =
        response.data.PharmacyInfoInsurancePolicies.map((item) => {
          return {
            IdLocal: index++,
            //applicationId: hardcodedApplicationId,
            carrier: item.Carrier,
            policyNum: item.PolicyNum,
            expiration: item.Expiration,
          };
        });
      setDataInsurancePolicy(apiDataInsurancePolicy);
      setIsLoading((prev) => false);
    }

    async function fetchPharmacyInfoGPO() {
      setIsLoading((prev) => true);

      //Get the business information data for existing member application
      //alert("initfetchBusinessInfo");
      const response = await memberAPI.get(
        "api/PharmacyInfo2/FetchPharmacyInfoGPO",
        {
          params: {
            applicationId: applicationId,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      let apiRootData;

      if (!response.data.PharmacyInfo) {
        apiRootData = {
          applicationId: applicationId,
          taxId: "",
          pharmacySoftwareVendors: "",
          medicare: "",
          medicaId: ""
        };
      } else {
        apiRootData = {
          applicationId: applicationId,
          taxId: response.data.PharmacyInfo.TaxId,
          pharmacySoftwareVendors:
            response.data.PharmacyInfo.PharmacySoftwareVendors,
          medicaId : response.data.PharmacyInfo.MedicaId,
          medicare: response.data.PharmacyInfo.Medicare
        };
      }

      //Settig the root data
      //Need to set it here or the Reset will not work
      //the Default value on the element itself will only apply on initial render - I therefore need to set it here in case a reset is clicked and we want the default values applied
      setValue("editTaxId", apiRootData.taxId);
      setValue("medicaId", apiRootData.medicaId);
      setValue("medicare", apiRootData.medicare);

      let index = 1;
      let apiDataLicenses = response.data.PharmacyInfoLicenses.map((item) => {
        return {
          IdLocal: index++,
          //applicationId: hardcodedApplicationId,
          licenseNum: item.LicenseNum,
          state: item.State,
          expiration: item.Expiration,
        };
      });
      setDataLicenses(apiDataLicenses);

      let apiSoftwareVendors = response.data.PharmacySoftwareVendors.map(
        (item) => {
          return {
            id: item.STATUSID,
            name: item.STATUSDESCRIPTION,
          };
        }
      );
      setDataPharmacySoftwareVendors(apiSoftwareVendors);

      //The API returned a list of Software vendor Ids - we need to put it into key-value object array to bind it to the MultiSelect control
      if (
        apiRootData.pharmacySoftwareVendors &&
        apiRootData.pharmacySoftwareVendors.length > 0
      ) {
        //If it gets here we heve a vendor Id,  if multiple they are separated by commas so split it into array
        let vendorIds = apiRootData.pharmacySoftwareVendors.split(",");

        //Process each element in array and find the name of the vendor by ID
        let initialVendors = vendorIds.map((item) => {
          const thisVendor = apiSoftwareVendors.find((t) => t.id == item);

          //if vendor name was found return object with ID,Name of software vendor
          if (thisVendor) {
            return { id: thisVendor.id, name: thisVendor.name };
          }

          //Just in case ID of Saved pharmacy vendor was removed from lookup table - return -1 and it will be filtered out
          return { id: -1 };
        });

        //Now we have our Vendors object array - we need to clean it uo.
        //Remove any -1 Id records as they have neen removed, also sort the array by Vendor Name
        let cleanedVendors = initialVendors
          .filter((t) => t.id > 0)
          .sort(function (x, y) {
            let a = x.name.toUpperCase(),
              b = y.name.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });

        setValue("softwareVendorIds", cleanedVendors);
      }

      setIsLoading((prev) => false);
    }

    let viewModel;

    switch (GetApplicationType(memberApplication.service)) {
      case "LTC":
        fetchPharmacyInfoLTC();
        break;
      case "GPO":
        fetchPharmacyInfoGPO();
        break;
      default:
        viewModel = null; //should never get here
    }
  }

  const toViewModelMapper = () => {
    //const userId = loginDetails.userEmail;

    const userId = authInfo.userEmail;

    function getGPOViewModel() {
      let softwareVendorIds = "";
      if(rootData.pharmacySoftwareVendors !=undefined)
      {
      rootData.pharmacySoftwareVendors.forEach(
        (item) => (softwareVendorIds += `,${item.id}`)
      );
      }

      let savePharmacyInfo = {
        applicationId: Number(applicationId),
        taxId: rootData.taxId,
        pharmacySoftwareVendors: softwareVendorIds !="" ? softwareVendorIds.substring(1) : softwareVendorIds,
        modifiedByUser: userId,
        medicaId: rootData.medicaId,
        medicare: rootData.medicare
      };

      let savePharmacyInfoLicenses = dataLicenses.map((t) => {
        return {
          applicationId: Number(applicationId),
          licenseNum: t.licenseNum,
          state: t.state,
          expiration: GetISODate(t.expiration),
          modifiedByUser: userId,
        };
      });

      return {
        ApplicationId: Number(applicationId),
        PharmacyInfo: savePharmacyInfo,
        PharmacyInfoLicenses: savePharmacyInfoLicenses,
      };
    }

    function getLTCViewModel() {
      let savePharmacyInfo = {
        applicationId: Number(applicationId),
        taxId: rootData.taxId,
        pharmacyEmail: rootData.email,
        ownerName: rootData.nameOfOwner,
        modifiedByUser: userId,
      };

      let savePharmacyInfoDeaCertificates = dataDeaCert.map((t) => {
        return {
          applicationId: Number(applicationId),
          deaNumber: t.deaNum,
          expiration: GetISODate(t.expiration),

          modifiedByUser: userId,
        };
      });

      let savePharmacyInfoInsurancePolicies = dataInsurancePolicy.map((t) => {
        return {
          applicationId: Number(applicationId),
          carrier: t.carrier,
          policyNum: t.policyNum,
          expiration: GetISODate(t.expiration),
          modifiedByUser: userId,
        };
      });

      let savePharmacyInfoLicenses = dataLicenses.map((t) => {
        return {
          applicationId: Number(applicationId),
          licenseNum: t.licenseNum,
          state: t.state,
          expiration: GetISODate(t.expiration),
          modifiedByUser: userId,
        };
      });

      let savePharmacyInfoPharmacistsInCharge = dataPharmacist.map((t) => {
        return {
          applicationId: Number(applicationId),
          licenseNum: t.licenseNum,
          fullName: t.fullName,
          expiration: GetISODate(t.expiration),
          modifiedByUser: userId,
        };
      });

      return {
        PharmacyInfo: savePharmacyInfo,
        PharmacyInfoDeaCertificates: savePharmacyInfoDeaCertificates,
        PharmacyInfoInsurancePolicies: savePharmacyInfoInsurancePolicies,
        PharmacyInfoLicenses: savePharmacyInfoLicenses,
        PharmacyInfoPharmacistsInCharge: savePharmacyInfoPharmacistsInCharge,
      };
    }

    let viewModel;

    switch (GetApplicationType(memberApplication.service)) {
      case "LTC":
        viewModel = getLTCViewModel();
        break;
      case "GPO":
        viewModel = getGPOViewModel();
        break;
      default:
        viewModel = null; //should never get here
    }

    return viewModel;
  };

  function getNextUrl(applicationType) {
    return applicationType === "GPO"
      ? "/DistributorWholesaler"
      : "/AdditionalInformation";
  }
  async function updatePharmacyInfo() {
    let viewModel = toViewModelMapper();

    let postUrl;

    const applicationType = GetApplicationType(memberApplication.service);
    switch (applicationType) {
      case "LTC":
        postUrl = "api/PharmacyInfo2/UpdatePharmacyInfoLTC";
        break;
      case "GPO":
        postUrl = "api/PharmacyInfo2/UpdatePharmacyInfoGPO";
        break;
      // default:
      //   viewModel = null; //should never get here
    }

    try {
      await memberAPI.post(postUrl, viewModel, {
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });

      routeChange(getNextUrl(applicationType));
    } catch (err) {
      console.log(err);
    }
  }

  //This is a root level validation - as opposed to a form element
  //This will check that every subItem has at least one record
  //this is our requirement and it will determine if the item is valid or not
  const isValidated = () => {
    let result = false;
    switch (GetApplicationType(memberApplication.service)) {
      case "LTC":
        const licensesHasRecord = dataLicenses.some((t) => t);
        setDataLicensesValidated(licensesHasRecord);

        const pharmacistHasRecord = dataPharmacist.some((t) => t);
        setDataPharmacistValidated(pharmacistHasRecord);

        const deaCertHasRecord = dataDeaCert.some((t) => t);
        setDataDeaCertValidated(deaCertHasRecord);

        const insurancePolicyHasRecord = dataInsurancePolicy.some((t) => t);
        setDataInsurancePolicyValidated(insurancePolicyHasRecord);

        result =
          licensesHasRecord &&
          pharmacistHasRecord &&
          deaCertHasRecord &&
          insurancePolicyHasRecord;

        break;
      case "GPO":
        
        result = dataLicenses.some((t) => t);
        setDataLicensesValidated(result);

        break;
      default:
        result = false; //Shoiuld never get here
        break;
    }

    return result;
  };
  const validateGridRecord = ()=>{
    let result = false;
    switch(pharmacyInfoCurrentworkflow?.ControlName)
    {
    case "GPO":
      result = dataLicenses.some((t) => t);
      setDataLicensesValidated(result);
      break;
    case "LTCNetwork":
      const licensesHasRecord = dataLicenses.some((t) => t);
      setDataLicensesValidated(licensesHasRecord);

      const pharmacistHasRecord = dataPharmacist.some((t) => t);
      setDataPharmacistValidated(pharmacistHasRecord);

      const deaCertHasRecord = dataDeaCert.some((t) => t);
      setDataDeaCertValidated(deaCertHasRecord);

      const insurancePolicyHasRecord = dataInsurancePolicy.some((t) => t);
      setDataInsurancePolicyValidated(insurancePolicyHasRecord);

      result =
        licensesHasRecord &&
        pharmacistHasRecord &&
        deaCertHasRecord &&
        insurancePolicyHasRecord;

      break;
      default:
        result = true;
        setDataLicensesValidated(result); //Shoiuld never get here
        break;
    }
    return result;
  }

  const setEdit = (name, id) => {
    const item = { ...editItem, editName: name, editId: id };

    setEditItem(item);
  };

  const clearEdit = () => {
    const item = {};
    setEditItem(item);
  };

  const isEditMode = () => {
    return editItem.editName != null;
  };

  const history = useHistory();

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onResetClick(e) {
    e.preventDefault();

    setFailedSubmitWasPressed(false);
    initialAPIFetch();
    reset();
  }

  function onBackClick(e) {
    e.preventDefault();

    routeChange("/PharmacyPrimaryContact", applicationId);
  }

  const onSubmitMain = (values) => {
   if (memberApplication.isReadOnly) {
      routeChange(getNextUrl(GetApplicationType(memberApplication.service)));
    } 
   
    else if (!validateGridRecord()) {
      setFailedSubmitWasPressed(true);
      return;
    } else {
      switch (GetApplicationType(memberApplication.service)) {
        case "LTC":
          rootData = {
            taxId: values.editTaxId,
            email: values.editEmail,
            nameOfOwner: values.editNameOfOwner,
          };
          break;
        case "GPO":
          rootData = {
            taxId: values.editTaxId,
            pharmacySoftwareVendors: values.softwareVendorIds,
            medicare: values.medicare,
            medicaId: values.medicaId
          };
          break;

        default:
          throw "Unknown application type";
      }


      updatePharmacyInfo();
    }
  };

  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <div>
      <form id="formMain" onSubmit={handleSubmit(onSubmitMain)}>
      
       
        <div className="mb-3 row">
          {(pharmacyInfoCurrentworkflow?.required) && (
          <label className="col-sm-2 col-form-label-sm">Federal Tax Id *</label>
          )}
          {(pharmacyInfoCurrentworkflow?.required == false) && (
          <label className="col-sm-2 col-form-label-sm">Federal Tax Id </label>
          )}
          <div className="col-sm-3 ">
            <input
              name="editTaxId"
              autoComplete="off"
              //defaultValue={dataRoot && dataRoot.taxId}
              {...register("editTaxId", {
                required: {
                  message :"Value is required",
                  value :(pharmacyInfoCurrentworkflow?.required)
                },
                pattern: {
                  value: /^([0-9]){2}(-?)([0-9]){7}$/,
                  message: "Number needs to be 9 digits.",
                },
              })}
              disabled={memberApplication.isReadOnly}
            />
            {errors.editTaxId && (
              <p className="text-danger"> {errors.editTaxId.message} </p>
            )}
          </div>
          
        </div>
     


        {GetApplicationType(memberApplication.service) == "LTC" && (
          <>
            <div className="mb-3 row">
              <label className="col-sm-2 col-form-label-sm">
                Pharmacy Email *
              </label>

              <div className="col-sm-3 ">
                <input
                  name="editEmail"
                  autoComplete="off"
                  //defaultValue={dataRoot && dataRoot.email}
                  {...register("editEmail", {
                    required: "Value is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "invalid email",
                    },
                  })}
                  disabled={memberApplication.isReadOnly}
                />
                {errors.editEmail && (
                  <p className="text-danger"> {errors.editEmail.message} </p>
                )}
              </div>
            </div>

            <div className="mb-5 row">
              <label className="col-sm-2 col-form-label-sm">
                Name of Owner
              </label>

              <div className="col-sm-3 ">
                <input
                  type="text"
                  className="form-control form-control-sm uppercase"
                  name="editNameOfOwner"
                  autoComplete="off"
                  //defaultValue={dataRoot && dataRoot.nameOfOwner}
                  placeholder=""
                  {...register("editNameOfOwner")}
                  disabled={memberApplication.isReadOnly}
                />
              </div>
            </div>
          </>
        )}

        {
          (pharmacyInfoCurrentworkflow?.ControlName == "Alt") && (
            <>
              <div className="mb-3 row">
              {(pharmacyInfoCurrentworkflow?.required && 
                <label className="col-sm-2 col-form-label-sm">
                  Medicaid # *
                </label>

              )}
              {(pharmacyInfoCurrentworkflow?.required == false && 
                <label className="col-sm-2 col-form-label-sm">
                  Medicaid # 
                </label>

              )}
                <div className="col-sm-3 ">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="medicaId"
                    autoComplete="off"
                    placeholder=""
                    
                    {...register("medicaId", {
                      required: {
                        message :(pharmacyInfoCurrentworkflow?.requiredMessage),
                        value : (pharmacyInfoCurrentworkflow?.required)
                      },
                    })}
                    disabled={memberApplication.isReadOnly}
                  />
                   {errors.medicaId && (
                  <p className="text-danger"> {errors.medicaId.message} </p>
                )}
                </div>
              </div>
              <div className="mb-3 row">
              {(pharmacyInfoCurrentworkflow?.required && 
                <label className="col-sm-2 col-form-label-sm">
                   Medicare # *
                </label>

              )}
              {(pharmacyInfoCurrentworkflow?.required == false && 
                <label className="col-sm-2 col-form-label-sm">
                  Medicare # 
                </label>
              )}
                <div className="col-sm-3 ">
                  <input
                    type="text"
                    className="form-control form-control-sm uppercase"
                    name="medicare"
                    autoComplete="off"
                    placeholder=""
                    {...register("medicare", {
                      required: {
                        message :(pharmacyInfoCurrentworkflow?.requiredMessage),
                        value : (pharmacyInfoCurrentworkflow?.required)
                      },
                    })}
                    disabled={memberApplication.isReadOnly}
                  />
                   {errors.medicare && (
                  <p className="text-danger"> {errors.medicare.message} </p>
                )}
                </div>
              </div>
            </>

          )}
        {

          GetApplicationType(memberApplication.service) == "GPO" && (
            (pharmacyInfoCurrentworkflow?.showVendor == "Allowed") && (
              <div className="mb-5 row">
                  {(pharmacyInfoCurrentworkflow?.required && 
                <label className="col-sm-2 col-form-label-sm">
                  Software Vendor *
                </label>
                  )}
                   {(pharmacyInfoCurrentworkflow?.required == false && 
                <label className="col-sm-2 col-form-label-sm">
                  Software Vendor 
                </label>
                  )}
                <div className="col-sm-3 ">
                  <Controller
                    control={control}
                    name="softwareVendorIds"
                    {...register("softwareVendorIds", {
                      required: {
                        message :"At least one vendor needs to be selected",
                        value : (pharmacyInfoCurrentworkflow?.required)
                      },
                      //required: "At least one vendor needs to be selected",
                    })}
                    render={({ field }) => (
                      <MultiSelect
                        data={dataPharmacySoftwareVendors}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        defaultValue={getValues("softwareVendorIds")}
                        textField="name"
                        dataItemKey="id"
                        disabled={memberApplication.isReadOnly}
                      />
                    )}
                  />

                  {errors.softwareVendorIds && (
                    <p className="text-danger">
                      {errors.softwareVendorIds.message}{" "}
                    </p>
                  )}
                </div>
              </div>
            ))}
      </form>
      <div className="container-fluid">
        <div className="mb-3 row">
          <div className="col">
            <PharmacyInfoLicenses
              dataLicenses={dataLicenses}
              setDataLicenses={setDataLicenses}
              failedSubmitWasPressed={failedSubmitWasPressed}
              dataLicensesValidated={dataLicensesValidated}
              editItem={editItem}
              clearEdit={clearEdit}
              isEditMode={isEditMode}
              isReadOnly={memberApplication.isReadOnly}
              setEdit={setEdit}
              selectedCOT = {pharmacyInfoCurrentworkflow}
            />
          </div>

          <div className="col">
            {GetApplicationType(memberApplication.service) == "LTC" && (
              <PharmacyInfoDeaCertificate
                dataDeaCert={dataDeaCert}
                setDataDeaCert={setDataDeaCert}
                failedSubmitWasPressed={failedSubmitWasPressed}
                dataDeaCertValidated={dataDeaCertValidated}
                editItem={editItem}
                clearEdit={clearEdit}
                isEditMode={isEditMode}
                isReadOnly={memberApplication.isReadOnly}
                setEdit={setEdit}
              />
            )}
          </div>
        </div>
      </div>

      {GetApplicationType(memberApplication.service) == "LTC" && (
        <div className="row mt-4">
          <div className="col">
            <PharmacyInfoPharmacistInCharge
              dataPharmacist={dataPharmacist}
              setDataPharmacist={setDataPharmacist}
              failedSubmitWasPressed={failedSubmitWasPressed}
              dataPharmacistValidated={dataPharmacistValidated}
              editItem={editItem}
              clearEdit={clearEdit}
              isEditMode={isEditMode}
              setEdit={setEdit}
              isReadOnly={memberApplication.isReadOnly}
            />
          </div>
          <div className="col">
            <PharmacyInfoInsurancePolicy
              dataInsurancePolicy={dataInsurancePolicy}
              setDataInsurancePolicy={setDataInsurancePolicy}
              failedSubmitWasPressed={failedSubmitWasPressed}
              dataInsurancePolicyValidated={dataInsurancePolicyValidated}
              editItem={editItem}
              clearEdit={clearEdit}
              isEditMode={isEditMode}
              setEdit={setEdit}
              isReadOnly={memberApplication.isReadOnly}
            />
          </div>
        </div>
      )}
      <hr />
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          onClick={onResetClick}
          disabled={memberApplication.isReadOnly}
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        {!memberApplication.isReadOnly ? (
          <button className="btn btn-primary" type="submit" form="formMain">
            Next
          </button>
        ) : (
          <button className="btn btn-primary" onClick={() => routeChange(getNextUrl(GetApplicationType(memberApplication.service)))}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default PharmacyInfoForm;
