import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingPageItem from "../../components/LoadingPageItem";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { MemberApplicationsForm } from "./MemberApplicationsForm";
import { MemberApplicationsGrid } from "./MemberApplicationsGrid";
import { memberAPI } from "../../utility/axios";

export default function MemberApplications(props) {
  const memberApplication = useContext(MemberApplicationContext);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const history = useHistory();

  function routeChange(path, id) {
    if (id) {
      memberApplication.onIDChange(id);
    } else {
      memberApplication.reset();
    }
    history.push(`${path ?? ""}/${id ?? ""}`);
  }

  async function fetchMemberApplications(form) {
    setIsLoading(true);
    const res = await memberAPI.get(
      "api/MemberApplication/FetchMemberApplications",
      {
        params: {
          "Args.User": form.selectedUser,
          "Args.Dea": form.dea,
          "Args.Ncpdp": form.ncpdp,
          "Args.PharmacyName": form.name,
          "Args.Service": Number(form.selectedService),
          "Args.ClassOfTrade": Number(form.selectedClassOfTrade),
          "Args.Hin": form.hin,
          "Args.Npi": form.npi,
        },
        headers: { Authorization: `Bearer ${form.userAccessToken}` },
      }
    );

    const newData = res.data.map((item) => {
      let temp = Object.assign({}, item);
      temp.LastChangedDate = new Date(item.LastChangedDate);
      temp.Status = item.DocusignQueueId ? "Active" : "In Progress";
      temp.Service = getApplicationTypeName(item.Service);
      temp.DocusignStatus = temp.DocusignStatus? temp.DocusignStatus.replace(",",",\n") : temp.DocusignStatus;
      return temp;
    });
    setData(newData);
    setIsLoading(false);
    setFormData(form);
  }

  function getApplicationTypeName(service) {
    if (!service) return null;

    if (service == 2) return "LTC Network";
  
    if (service == 1) return "GPO";
  
    throw `Invalid service value of ${service} argument was passed - valid values are 1 or 2`;
  
  }
  return (
    <>
      <h4 className="text-start">Member Application Status</h4>
      <MemberApplicationsForm onSubmit={fetchMemberApplications} />
      {isLoading ? (
        <LoadingPageItem />
      ) : (
        <MemberApplicationsGrid
          onRefresh={() => fetchMemberApplications(formData)}
          data={data}
        />
      )}
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          onClick={() => routeChange("MemberService")}
          className="btn btn-primary"
        >
          Add New
        </button>
      </div>
      <hr />
    </>
  );
}
