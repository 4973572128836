import { validationInfo } from "./formValidations.jsx"

export function ValidationCell(props) {
    const {dataItem, field, onChange} = props

    
    function handleChange(e) {
        if (onChange) {
            onChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value
            })
        }
    }

    return (
        <td>
              {dataItem.inEdit ? (
                  <>
                    <input
                        className="form-control form-control-sm"
                        value={dataItem[field] ?? ""}
                        onChange={handleChange} 
                        type={field === "Email" ? undefined : "text"}
                        required={validationInfo[field]?.required ?? false}
                        minLength={validationInfo[field]?.minLength ?? ""}
                        maxLength={validationInfo[field]?.maxLength ?? ""}
                        pattern={validationInfo[field]?.pattern ?? undefined}
                    />
                    <div className="invalid-feedback">
                        {validationInfo[field] && ( validationInfo[field].feedbackMessage) }
                    </div>
                </>

            ) : (
                dataItem[field]
            )}
        </td>
    )
}