import { useParams } from "react-router";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import CreatePacketForm from "./CreatePacketForm";
import LTCViewMessage from ".//LTCViewMessage";

import { VendorGrid } from "./VendorGrid";
import React, { useState, useContext, useEffect } from "react";
import { GetApplicationType } from "../../helpers/AppDataHelpers";

const CreatePacket = () => {
  const params = useParams();
  const { id } = params;

  const memberApplication = useContext(MemberApplicationContext);
  const [applicationType, setApplicationType] = useState("");

  useEffect(() => {
    if (memberApplication.id) {
      setApplicationType(GetApplicationType(memberApplication.service));
    } else {
      memberApplication.onIDChange(id);
    }
  }, []);

  useEffect(() => {
    if (memberApplication.id) {
      setApplicationType(GetApplicationType(memberApplication.service));
    }
  }, [memberApplication.id]);

  return (
    <>
      <h4 className="text-start">Create Packet</h4>
      <hr />
      {applicationType === "GPO" && <VendorGrid id={id} />}

      {applicationType === "LTC" && <LTCViewMessage />}
      <CreatePacketForm id={id} applicationType={applicationType} />
    </>
  );
};

export default CreatePacket;
