import { useEffect, useContext, useState, useRef } from "react";
import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";

import { useHistory } from "react-router-dom";
import { BooleanYesNoCell } from "./BooleanYesNoCell";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { CommandCell } from "./CommandCell";
import { ValidationCell } from "./ValidationCell";
import { StateCell } from "./StateCell";
import { maskPhone } from "../../utility/mask";
import {workflow as pageWorkflow} from "./PharmacyProviderContactWorkflow";

export default function PharmacyPrimaryContactGrid(props) {
  const editField = "inEdit";
  const { id } = props;
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  let { authInfo } = useAzureAuthentication();
  const [states, setStates] = useState([]);
  const memberApplication = useContext(MemberApplicationContext);
  const [isValidated, setIsValidated] = useState(false);

  const formRef = useRef();
  const history = useHistory();
  const [currentWorkflow, setCurrentworkflow] = useState(null);

  useEffect(()=>{
    if( memberApplication.workflow){
      setCurrentworkflow(pageWorkflow[memberApplication.workflow]);
    }
  },[memberApplication.workflow])

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onIsSameAddressAsPharmacyChange(e, dataItem) {
    const value = e.target.checked;

    async function getMemberAddress(dataItem) {
      const res = await memberAPI.get("api/MemApp/FetchPharmacyAddress", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });

      const responseData = res.data[0];
      dataItem.Address = responseData.Address;
      dataItem.AddressTwo = responseData.AddressTwo;
      dataItem.City = responseData.City;
      dataItem.Extension = responseData.Extension ?? "";
      dataItem.Fax = responseData.Fax;
      dataItem.Phone = responseData.Phone;
      dataItem.State = responseData.State;
      dataItem.Zip = responseData.Zip;
      dataItem.isSameAddressAsPharmacy = true;

      const newData = [...data];
      setData(newData);
    }
    dataItem.isSameAddressAsPharmacy = value;

    if (value) {
      getMemberAddress(dataItem);
    } else {
      dataItem.Address = "";
      dataItem.AddressTwo = "";
      dataItem.City = "";
      dataItem.Extension = "";
      dataItem.Fax = "";
      dataItem.Phone = "";
      dataItem.State = "";
      dataItem.Zip = "";
      dataItem.isSameAddressAsPharmacy = false;

      const newData = [...data];
      setData(newData);
    }
  }

  const MyCommandCell = (props) => {
    return (
      <CommandCell
        {...props}
        onEdit={onEdit}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onDiscard={onDiscard}
        onCancel={onCancel}
        editField={editField}
        onIsSameAddressAsPharmacyChange={onIsSameAddressAsPharmacyChange}
        isInEdit={data.some((x) => x.inEdit)}
        workflow={currentWorkflow?.Action}
      />
    );
  };

  const MyStateCell = (props) => {
    return <StateCell {...props} states={states} />;
  };

  function onEdit(dataItem) {
    let newData = data.map((item) =>
      item.ContactId === dataItem.ContactId ? { ...item, inEdit: true } : item
    );
    setData(newData);
  }

  function PrimaryContactConfirmation(contactId) {
    const items = data.filter((x) => x.IsPrimaryContact);
    if (items.length > 1) {
      if (
        window.confirm(
          "There can be only one Primary Contact for a member. You have already added a Primary Contact. Would you like to change this contact as the member’s Primary Contact?"
        )
      ) {
        const itemsToChange = data
          .filter((x) => x.ContactId !== contactId)
          .map((item) => item.ContactId);
        const newData = [...data];

        itemsToChange.forEach((item) => {
          let index = newData.findIndex((record) => record.ContactId === item);
          newData[index].IsPrimaryContact = false;
        });
        setData(newData);
        return true;
      }
      return false;
    }
    return true;
  }

  function DocusignConfirmation(contactId) {
    const items = data.filter((x) => x.IsDocusignRecipient);
    if (items.length > 1) {
      if (
        window.confirm(
          "There can be only one Docusign Contact for a member. You have already added a Docusign Contact. Would you like to change this contact as the member’s Docusign Contact?"
        )
      ) {
        const itemsToChange = data
          .filter((x) => x.ContactId !== contactId)
          .map((item) => item.ContactId);
        const newData = [...data];
        console.log(itemsToChange);
        itemsToChange.forEach((item) => {
          let index = newData.findIndex((record) => record.ContactId === item);
          newData[index].IsDocusignRecipient = false;
        });
        setData(newData);
        return true;
      }
      return false;
    }
    return true;
  }

  function ReviewConfirmation(contactId) {
    
    if(data.some(x=>x.IsDocusignRecipient  && x.ContactId == contactId && x.IsReviewer ))
    { 
      window.alert("The Docusign Recipient cannot be the Reviewer. Please select another contact as the Reviewer.");
      return false;
    }
    const items = data.filter((x) => x.IsReviewer);
    debugger;
    if (items.length > 1) {
      if (
        window.confirm(
          "There can be only one Reviewer. You have already added a Reviewer. Would you like to change this contact as the member’s Reviewer?"
        )
      ) {
        const itemsToChange = data
          .filter((x) => x.ContactId !== contactId)
          .map((item) => item.ContactId);
        const newData = [...data];
        console.log(itemsToChange);
        itemsToChange.forEach((item) => {
          let index = newData.findIndex((record) => record.ContactId === item);
          newData[index].IsReviewer = false;
        });
        setData(newData);
        return true;
      }
      return false;
    }
    return true;
  }
  async function onAdd(dataItem) {

    async function insertPharmacyContact(dataItem) {
      console.log(dataItem)
      const res = await memberAPI.post(
        "api/PharmacyContact/InsertPharmacyContact",
        {
          maid: id,
          firstName: dataItem.FirstName,
          lastName: dataItem.LastName,
          address: dataItem.Address,
          title: dataItem.Title,
          addressTwo: dataItem.AddressTwo,
          city: dataItem.City,
          state: dataItem.State,
          zip: dataItem.Zip,
          email: dataItem.Email,
          fax: dataItem.Fax,
          extension: dataItem.Extension,
          isPrimaryContact: dataItem.IsPrimaryContact,
          isDocusignRecipient: dataItem.IsDocusignRecipient,
          isReviewer: dataItem.IsReviewer,
          phone: dataItem.Phone,
          user: authInfo.userEmail,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );

      const newItemId = res.data;

      dataItem.inEdit = false;
      dataItem.ContactId = newItemId;

      const newData = [...data];
      newData[0] = dataItem;
      setOriginalData(newData)
      setData(newData);
    }

    setIsValidated(true);

    function AddConfirmation() {
      return window.confirm("Are you sure you want to insert new record?");
    }
    if (formRef.current.checkValidity()) {
      if(
        PrimaryContactConfirmation(dataItem.ContactId) &&
        DocusignConfirmation(dataItem.ContactId) &&
        ReviewConfirmation(dataItem.ContactId) &&
        AddConfirmation()
      ) {
        await insertPharmacyContact(dataItem);
        setIsValidated(false);
      }
      
    }
  }

  async function onUpdate(dataItem) {
    setIsValidated(true);

    async function updatePharmacyContact(dataItem) {
      await memberAPI.post(
        "api/PharmacyContact/UpdatePharmacyContact",
        {
          contactId: dataItem.ContactId,
          maid: id,
          firstName: dataItem.FirstName,
          lastName: dataItem.LastName,
          address: dataItem.Address,
          title: dataItem.Title,
          addressTwo: dataItem.AddressTwo,
          city: dataItem.City,
          state: dataItem.State,
          zip: dataItem.Zip,
          email: dataItem.Email,
          fax: dataItem.Fax,
          extension: dataItem.Extension,
          isPrimaryContact: dataItem.IsPrimaryContact,
          isDocusignRecipient: dataItem.IsDocusignRecipient,
          isReviewer: dataItem.IsReviewer,
          phone: dataItem.Phone,

          user: authInfo.userEmail,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );

      dataItem.inEdit = false;

      const newData = [...data];
      let index = newData.findIndex(
        (record) => record.ContactId === dataItem.ContactId
      );
      newData[index] = dataItem;
      setOriginalData(newData)
      setData(newData);
    }

    function UpdateConfirmation() {
      return window.confirm("Are you sure you want to update?");
    }
    if (formRef.current.checkValidity()) {
      if (
        PrimaryContactConfirmation(dataItem.ContactId) &&
        DocusignConfirmation(dataItem.ContactId) &&
        ReviewConfirmation(dataItem.ContactId) &&
        UpdateConfirmation()
      ) {
        await updatePharmacyContact(dataItem);
        setIsValidated(false);
      }
    }
  }

  async function onDelete(dataItem) {
    async function deletePharmacyContact(contactId) {
      await memberAPI.post(
        "api/PharmacyContact/DeletePharmacyContact",
        {
          contactId: contactId,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );

      const newData = [...data];
      let index = newData.findIndex(
        (record) => record.ContactId === dataItem.ContactId
      );
      newData.splice(index, 1);
      setData(newData);
    }

    if (window.confirm("Are you sure you want to delete?")) {
      await deletePharmacyContact(dataItem.ContactId);
    }
  }

  function onDiscard() {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
  }

  function onCancel(dataItem) {
    const originalItem = originalData.find(
      (p) => p.ContactId === dataItem.ContactId
    );

    const newData = data.map((item) =>
      item.ContactId === originalItem.ContactId ? originalItem : item
    );
    setData(newData);
  }

  function itemChange(event) {
    const field = event.field || "";
    let value = event.value ?? "";
    
    if (field === "Fax" || field === "Phone") {
      value = maskPhone(value ?? "", event.dataItem[field]);
    } else if (field === "Extension" || field === "Zip") {
        
        if (isNaN(value)) {
            return
        }

    } else if (field !== "IsPrimaryContact" && field !== "IsDocusignRecipient" && field !=="IsReviewer") {
      value = value.toUpperCase();
    }

    const newData = data.map((item) =>
      item.ContactId === event.dataItem.ContactId
        ? { ...item, [field]: value }
        : item
    );
    setData(newData);
  }

  useEffect(() => {
    async function fetchStates() {
        const res = await memberAPI.get("api/State/FetchStates", {
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        });
        setStates(res.data);
    }

    if (authInfo) {
        fetchStates()

        if (memberApplication.id) {
            initPharmacyPrimaryContact();
        } else {
            memberApplication.onIDChange(id);
        }
    }
  }, [authInfo]);

  useEffect(() => {
    if (memberApplication.id && authInfo) {
      initPharmacyPrimaryContact();
    }
  }, [memberApplication.id, authInfo]);

  async function initPharmacyPrimaryContact() {
    const res = await memberAPI.get(
      "api/PharmacyContact/FetchPharmacyContact",
      {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      }
    );

    setData(res.data);
    setOriginalData(res.data);
  }

  function addNew() {
    const newDataItem = {
      inEdit: true,
      FirstName: "",
      LastName: "",
      Title: "",
      Address: "",
      AddressTwo: "",
      City: "",
      State: "",
      Zip: "",
      Phone: "",
      Email: "",
      IsPrimaryContact: false,
      IsDocusignRecipient: false,
      IsReviewer: false,
      isSameAddressAsPharmacy: false,
    };
    setData([newDataItem, ...data]);
  }

  function onNext() {
    if(memberApplication.isReadOnly) {
      routeChange("/PharmacyInfo");
 
    } else {
      const hasPharmacyContact = data.some((x) => x.IsPrimaryContact);
    const hasDocusignRecipient = data.some((x) => x.IsDocusignRecipient);
    

    if (!hasPharmacyContact || !hasDocusignRecipient ) {
      if (!hasPharmacyContact) {
        alert("Please select a Primary Pharmacy Contact");
      }

      if (!hasDocusignRecipient) {
        alert("Please select a Docusign Recipient");
      }
      
    } else {
      routeChange("/PharmacyInfo");
    }
    }
    
  }

  return (
    <>
      <div className="mb-3 row">
        <form
          ref={formRef}
          className={isValidated ? "was-validated" : ""}
          noValidate
        >
          <Grid
            data={data}
            editField={"inEdit"}
            dataItemKey={"ContactId"}
            onItemChange={itemChange}
          >
            <GridColumn
              field="FirstName"
              width="200px"
              title="First Name"
              cell={ValidationCell}
            />
            <GridColumn
              field="LastName"
              width="200px"
              title="Last Name"
              cell={ValidationCell}
            />
            <GridColumn field="Title" width="150px" cell={ValidationCell} />
            <GridColumn field="Address" width="200px" cell={ValidationCell} />
            <GridColumn
              field="AddressTwo"
              width="200px"
              title="Address 2"
              cell={ValidationCell}
            />
            <GridColumn field="City" width="200px" cell={ValidationCell} />
            <GridColumn field="State" width="200px" cell={MyStateCell} />
            <GridColumn field="Zip" width="100px" cell={ValidationCell} />
            <GridColumn field="Phone" width="175px" cell={ValidationCell} />
            <GridColumn
              field="Extension"
              width="100px"
              title="Extn."
              cell={ValidationCell}
            />
            <GridColumn field="Fax" width="175px" cell={ValidationCell} />
            <GridColumn field="Email" width="200px" cell={ValidationCell} />
            <GridColumn
              field="IsPrimaryContact"
              width="100px"
              title="Is Primary?"
              cell={BooleanYesNoCell}
            />
            <GridColumn
              field="IsDocusignRecipient"
              width="100px"
              title="Is Docusign?"
              cell={BooleanYesNoCell}
            />
            <GridColumn
              field="IsReviewer"
              width="100px"
              title="Is Reviewer?"
              cell={BooleanYesNoCell}
            />
            {!memberApplication.isReadOnly && (
              <GridColumn title="Action" width="200px" cell={MyCommandCell} />
            )}
            <GridToolbar>
              <button
                title="Add new"
                disabled={memberApplication.isReadOnly || data.some((x) => x.inEdit)}
                className="btn btn-primary"
                type="button"
                onClick={addNew}
              >
                Add new
              </button>
            </GridToolbar>
          </Grid>
        </form>
      </div>
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          onClick={() => routeChange("/BusinessInfo")}
        >
          Back
        </button>
        <button 
            disabled={data.some((x) => x.inEdit)}
            className="btn btn-primary" 
            onClick={onNext}>
          Next
        </button>
      </div>
    </>
  );
}
