import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  ShortDateFormat,
  StringToDate,
  DateWithoutTime,
} from "../../helpers/DateHelpers";

export const PharmacyInfoDeaCertificate = ({
  dataDeaCert,
  setDataDeaCert,
  failedSubmitWasPressed,
  dataDeaCertValidated,
  editItem,
  clearEdit,
  isEditMode,
  isReadOnly,
  setEdit,
}) => {
  const {
    control,
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    if (!values || Object.keys(values).length === 0) return;

    //alert(JSON.stringify(values));

    let updatedDeaCert;

    if (values.editId < 0) {
      // const maxLocalId = Math.max(...dataDeaCert.map((t) => t.IdLocal));

      const maxLocalId =
        !dataDeaCert || dataDeaCert.length == 0
          ? 0
          : Math.max(...dataDeaCert.map((t) => t.IdLocal));

      const newRecord = {
        IdLocal: maxLocalId + 1,
        deaNum: values.editDeaNum.toUpperCase(),
        expiration: values.editExpiration,
      };

      updatedDeaCert = [...dataDeaCert, newRecord];
    } else {
      updatedDeaCert = dataDeaCert.map((item) => {
        if (item.IdLocal == values.editId)
          return {
            ...item,
            deaNum: values.editDeaNum.toUpperCase(),
            expiration: values.editExpiration,
          };

        return item;
      });
    }

    turnAddingViewOff();
    reset();

    setDataDeaCert(updatedDeaCert);
  };

  const DeletedDeaCert = (id) => {
    const updatedDeaCert = dataDeaCert.filter((item) => item.IdLocal != id);

    setDataDeaCert(updatedDeaCert);
  };

  const [addingView, setAddingView] = useState(false);

  const turnAddingViewOff = () => {
    unRegisterAll();
    clearEdit();
    setAddingView(false);
  };

  //If this is not done the useform hook will retain values and cause many unwanted effects
  //There may be a better way to do it but didn't find it yet
  const unRegisterAll = () => {
    unregister("editDeaNum");
    unregister("editExpiration");
    unregister("editId");
  };

  const EditView = (id, deaNum, expiration) => {
    //If date is empty defualt it to today
    const processedDate = DateWithoutTime(
      expiration == "" ? new Date() : StringToDate(expiration)
    );
    return (
      <tr key={id}>
        <td>
          <input
            type="hidden"
            value={id}
            name="editId"
            {...register("editId")}
          />

          <input
            type="text"
            className="form-control form-control-sm uppercase"
            defaultValue={deaNum}
            autoComplete="off"
            name="editDeaNum"
            {...register("editDeaNum", {
              required: "Value is required",
              pattern: {
                value: /^[a-zA-Z][a-zA-Z9][0-9]{7}$/,
                message: "Number needs to be 2 chars then 7 digits.",
              },
            })}
          />

          {errors.editDeaNum && (
            <p className="text-danger">{errors.editDeaNum.message} </p>
          )}
        </td>

        <td>
          <Controller
            control={control}
            name="editExpiration"
            defaultValue={processedDate}
            {...register("editExpiration", {
              valueAsDate: true,
              required: "Not a valid date",
              validate: {
                isTodayOrLater: (i) => {
                  let enteredDate = StringToDate(i);
                  var currentDate = new Date();

                  return (
                    DateWithoutTime(enteredDate) >=
                      DateWithoutTime(currentDate) ||
                    "Cannot be earlier than today"
                  );
                },
              },
            })}
            render={({ field }) => (
              <DatePicker
                defaultValue={processedDate}
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />

          {errors.editExpiration && (
            <p className="text-danger">{errors.editExpiration.message} </p>
          )}
        </td>
        <td className="buttoncell">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
          <button
            className="btn btn-secondary ms-2"
            onClick={(e) => {
              e.preventDefault();

              reset();
              turnAddingViewOff();
            }}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>DEA Certificate *</h6>

        {failedSubmitWasPressed && !dataDeaCertValidated && (
          <div className="text-danger">
            At least one record needs to be entered
          </div>
        )}

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">DEA Number</th>
              <th scope="col">Expiration Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataDeaCert.map((t) => {
              return editItem.editName == "deacert" &&
                editItem.editId == t.IdLocal ? (
                EditView(editItem.editId, t.deaNum, t.expiration)
              ) : (
                <tr key={t.IdLocal}>
                  <td>{t.deaNum}</td>

                  <td>{ShortDateFormat(t.expiration)}</td>

                  {!isReadOnly && (
                    <td className="buttoncell">
                      <button
                        className="btn btn-primary"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          e.preventDefault();

                          setEdit("deacert", t.IdLocal);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        disabled={isEditMode()}
                        onClick={(e) => {
                          if (window.confirm("Are you sure?")) {
                            e.preventDefault();

                            DeletedDeaCert(t.IdLocal);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
            {addingView && EditView(-1, "", "")}
          </tbody>
        </table>

        <button
          className="btn btn-primary"
          disabled={isEditMode() || isReadOnly}
          onClick={(e) => {
            e.preventDefault();
            setEdit("deacert", -1);
            unRegisterAll();
            setAddingView(true);
          }}
        >
          Add New Certificate
        </button>
      </form>
    </>
  );
};

export default PharmacyInfoDeaCertificate;
