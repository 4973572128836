import { createContext, useContext, useState } from "react";

const BusInfoContext = createContext();

const BusinessInfoContext = ({ children }) => {
  const [rootBusinessInfoApplicationData, setRootBusinessInfoApplicationData] =
    useState({});

  const [targetCotPercentageData, setTargetCotPercentageData] = useState([]);

  const [targetCotPercentageMedData, setTargetCotPercentageMedData] = useState([]);

  const [targetBusinessInfoAltSite, setTargetBusinessInfoAltSite] = useState([]);

  const [dailyHoursOfOperation, setDailyHoursOfOperation] = useState([]);

  const [selectedSpokenLanguages, setSelectedSpokenLanguages] = useState([]);

  const [allCots, setAllCots] = useState([]);

  const [applicationId, setApplicationId] = useState();

  const [failedSubmitWasPressed, setFailedSubmitWasPressed] = useState(false);

  const [spokenLanguagesValidationPassed, setSpokenLanguagesValidationPassed] =
    useState(true);

  const [spokenLanguagesFailedMsg, setSpokenLanguagesFailedMsg] = useState("");

  const [cotTotalsValidationPassed, setCotTotalsValidationPassed] =
    useState(true);

    const [AltValidationPassed, setAltValidationPassed] =
    useState(true);

  const [
    cotTotalsOtherDescriptionEnteredValidationPassed,
    setCotTotalsOtherDescriptionEnteredValidationPassed,
  ] = useState(true);

  const [cotTotalsFailedMsg, setCotTotalsFailedMsg] = useState("");

  const [
    hoursOfOperationValidationPassed,
    setHoursOfOperationValidationPassed,
  ] = useState(true);

  const [hoursOfOperationFailMsg, setHoursOfOperationFailMsg] = useState("");

  const [newItemFailMsg, setNewItemFailMsgFailMsg] = useState("");

  return (
    <BusInfoContext.Provider
      value={{
        applicationId,
        setApplicationId,
        rootBusinessInfoApplicationData,
        setRootBusinessInfoApplicationData,
        targetCotPercentageData,
        setTargetCotPercentageData,
        targetCotPercentageMedData,
        setTargetCotPercentageMedData,
        targetBusinessInfoAltSite,
        setTargetBusinessInfoAltSite,
        dailyHoursOfOperation,
        setDailyHoursOfOperation,
        selectedSpokenLanguages,
        setSelectedSpokenLanguages,
        failedSubmitWasPressed,
        setFailedSubmitWasPressed,
        spokenLanguagesValidationPassed,
        setSpokenLanguagesValidationPassed,
        spokenLanguagesFailedMsg,
        setSpokenLanguagesFailedMsg,
        cotTotalsValidationPassed,
        setCotTotalsValidationPassed,
        AltValidationPassed,
        setAltValidationPassed,
        cotTotalsFailedMsg,
        setCotTotalsFailedMsg,
        hoursOfOperationValidationPassed,
        setHoursOfOperationValidationPassed,
        hoursOfOperationFailMsg,
        setHoursOfOperationFailMsg,
        newItemFailMsg,
        setNewItemFailMsgFailMsg,
        allCots,
        setAllCots,
        cotTotalsOtherDescriptionEnteredValidationPassed,
        setCotTotalsOtherDescriptionEnteredValidationPassed,
      }}
    >
      {children}
    </BusInfoContext.Provider>
  );
};

export const BusinessInfoState = () => {
  return useContext(BusInfoContext);
};

export default BusinessInfoContext;
