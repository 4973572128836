import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";

export function FormerGpoMultiSelect(props) {
  let { authInfo } = useAzureAuthentication();

  const [formerGpos, setFormerGpos] = useState([]);
  const [selectedFormerGpos, setSelectedFormerGpos] = useState([]);

  useEffect(() => {
    async function fetchFormerGpo() {
      const res = await memberAPI.get("api/MemAppFormerGPO/FetchFormerGpo", {
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setFormerGpos(res.data);
    }
    if (authInfo) fetchFormerGpo();
  }, [authInfo]);

  useEffect(() => {
    if (formerGpos.length && props.value) {
      const selected = formerGpos.filter((x) => props.value.includes(x.Id));
      setSelectedFormerGpos(selected);
    }
  }, [props.value, formerGpos]);

  return (
    props.show && (
      <div className="mb-3 row">
        <MultiSelect
          data={formerGpos}
          onChange={props.onChange}
          value={selectedFormerGpos}
          textField="Description"
          dataItemKey="Id"
          required = {props.required}
          validityStyles={props.isValidated}
          disabled={props.disabled}
        />
      </div>
    )
  );
}
