import React from "react";

const LoadingPageItem = () => {
  return (
    <div className="text-center  spinner text-primary">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingPageItem;
