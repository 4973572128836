import React, { useState, useEffect, useContext } from "react";
import { memberAPI } from "../../utility/axios";

import LoadingPageItem from "../../components/LoadingPageItem";

import DistributorGrid from "./DistributorGrid";
import WholesalerGrid from "./WholesalerGrid";

import { useHistory } from "react-router-dom";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import "./DistributorWholesalerStyles.css";
import { wholesalerworkflow as pageWorkflow } from "./DistributorWholesalerWorkflow";

const DistributorWholesalerForm = (props) => {
  let { id } = props;

  let { authInfo } = useAzureAuthentication();

  const memberApplication = useContext(MemberApplicationContext);

  const [distributors, setDistributorData] = useState();
  const [wholesalers, setWholesalerData] = useState();
  const [wholesalerParentCompanies, setWholesalerParentCompanies] = useState();
  const [distributorParentCompanies, setDistributorParentCompanies] =
    useState();

  const [applicationId, setApplicationId] = useState(id);

  const [failedSubmitWasPressed, setFailedSubmitWasPressed] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isEditMode, setIsEditMode] = useState(false);
  const [wholesalerworkflow, setwholesalerworkflow] = useState(null);

  useEffect(() => {
    if (memberApplication.id) {
      if (authInfo) initialAPIFetch();
    } else {
      memberApplication.onIDChange(id);
    }
  }, [authInfo]);

  useEffect(() => {
    if (memberApplication.wholesalerworkflow) {
      setwholesalerworkflow(pageWorkflow[memberApplication.wholesalerworkflow]);
    }
    if (memberApplication.id) {
      if (authInfo) {
        initialAPIFetch();
      }
    }
  }, [memberApplication.id,memberApplication.wholesalerworkflow]);

  function initialAPIFetch() {
    // //Get the business information data for existing member application
    async function fetchData() {
      setIsLoading((prev) => true);

      let index;
      const response = await memberAPI.get(
        "api/DistribWholesale2/FetchDistributorsWholesalersData",
        {
          params: {
            applicationId: applicationId,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );

      let apiWholesalerParentCompanies =
        response.data.WholesalerParentCompanies.map((item) => {
          return {
            wpid: item.Wpid,
            name: item.Name,
            city: item.City,
            state: item.State,
          };
        }).sort(function (x, y) {
          let a = x.name.toUpperCase(),
            b = y.name.toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;
        });

      setWholesalerParentCompanies(apiWholesalerParentCompanies);

      let apiDistributorParentCompanies =
        response.data.DistributorParentCompanies.map((item) => {
          return {
            wpid: item.Wpid,
            name: item.Name,
            city: item.City,
            state: item.State,
          };
        }).sort(function (x, y) {
          let a = x.name.toUpperCase(),
            b = y.name.toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;
        });

      setDistributorParentCompanies(apiDistributorParentCompanies);

      index = 1;
      let apiDistributors;

      apiDistributors = response.data.Distributors.map((item) => {
        return {
          idLocal: index++,
          parentCompanyId: item.ParentCompanyId,
          accountNumber: item.AccountNumber,
          alternateAccountNumber: item.AlternateAccountNumber,
          tertiaryAccountNumber: item.TertiaryAccountNumber,
          name: apiDistributorParentCompanies.find(
            (t) => t.wpid == item.ParentCompanyId
          ).name,
        };
      });

      setDistributorData(apiDistributors);

      index = 1;
      let apiWholesalers;

      apiWholesalers = response.data.Wholesalers.map((item) => {
        return {
          idLocal: index++,
          parentCompanyId: item.ParentCompanyId,
          accountNumber: item.AccountNumber,
          alternateAccountNumber: item.AlternateAccountNumber,
          tertiaryAccountNumber: item.TertiaryAccountNumber,
          name: apiWholesalerParentCompanies.find(
            (t) => t.wpid == item.ParentCompanyId
          ).name,
        };
      });
      setWholesalerData(apiWholesalers);
      setIsLoading((prev) => false);
    }
    fetchData();
  }

  const toViewModelMapper = () => {
    const userId = authInfo.userEmail;

    let saveDistributors = distributors.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        ParentCompanyId: t.parentCompanyId,
        AccountNumber: t.accountNumber,
        AlternateAccountNumber: t.alternateAccountNumber,
        TertiaryAccountNumber: t.tertiaryAccountNumber,
        Rank:Number(t.idLocal),
        modifiedByUser: userId,
      };
    });

    let saveWholesalers = wholesalers.map((t) => {
      return {
        ApplicationId: Number(applicationId),
        ParentCompanyId: t.parentCompanyId,
        AccountNumber: t.accountNumber,
        AlternateAccountNumber: t.alternateAccountNumber,
        TertiaryAccountNumber: t.tertiaryAccountNumber,
        Rank:Number(t.idLocal),
        modifiedByUser: userId,
      };
    });

    const viewModel = {
      ApplicationId: Number(applicationId),
      Distributors: saveDistributors,
      Wholesalers: saveWholesalers,
      //WholesalerParentCompanies: [],
      //DistributorParentCompanies: [],
    };

    return viewModel;
  };

  async function postData() {
    if (!isValidated()) {
      setFailedSubmitWasPressed(true);
      return;
    }

    let viewModel = toViewModelMapper();

    try {
      await memberAPI.post(
        "api/DistribWholesale2/UpdateDistributorsWholesalers",
        viewModel,
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
      // if(memberApplication.additionalworkflow !=="Alt Site")
      // {
      //   routeChange("/AdditionalInformation", applicationId);
      // }
      // else
      // {
      //   routeChange("/CreatePacket");
      // }
      return memberApplication.additionalworkflow !=="Alt Site"
              ? routeChange("/AdditionalInformation", applicationId)
              :  routeChange("/AdditionalOutputInfo");
     
    } catch (err) {
      console.log(err);
    }
  }

  const isValidated = () => {
    //Is valid if we have at least one Wholesaler record
    const hasRecord = wholesalers.some((t) => t);
    if(wholesalerworkflow?.validationRequired.required == false) return true;
    return hasRecord;
  };

  const history = useHistory();

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onResetClick(e) {
    e.preventDefault();

    setIsEditMode(false);
    initialAPIFetch();
    setFailedSubmitWasPressed(false);
  }

  function onBackClick(e) {
    e.preventDefault();

    routeChange("/PharmacyInfo", applicationId);
  }

  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <div>
      <div className="row">
        <WholesalerGrid
          {...{
            wholesalers,
            setWholesalerData,
            wholesalerParentCompanies,
            failedSubmitWasPressed,
            isValidated,
            isEditMode,
            setIsEditMode,
          }}
          isReadOnly={memberApplication.isReadOnly}
        />
      </div>

      <div className="mt-5 row">
        {/* <form onSubmit={handleSubmit(onSubmit)}>
        </form> */}

        <DistributorGrid
          {...{
            distributors,
            setDistributorData,
            distributorParentCompanies,
            isEditMode,
            setIsEditMode,
          }}
          isReadOnly={memberApplication.isReadOnly}
        />
      </div>
      {/* <PageCommonActions /> */}
      {/* <button
        className="btn btn-primary"
        form="formMain"
        type="button"
        onClick={postData}
      >
        Save Testing
      </button> */}
      <hr />
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          onClick={onResetClick}
          disabled={memberApplication.isReadOnly}
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={(e) => {
            e.preventDefault();

            if (memberApplication.isReadOnly) {
              return memberApplication.additionalworkflow !=="Alt Site"
              ? routeChange("/AdditionalInformation")
              :  routeChange("/CreatePacket");
             
            } else {
              postData();
            }
          }}
          disabled={isEditMode}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DistributorWholesalerForm;
