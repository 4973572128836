import React, { useState, useEffect } from "react";
import { SpokenLanguages as SpokenLanguagesConstant } from "./BusinessInfoConstants";

import { BusinessInfoState } from "./BusinessInfoContext";

const BusinessInfoSpokenLanguages = (props) => {
  const {
    selectedSpokenLanguages,
    setSelectedSpokenLanguages,
    failedSubmitWasPressed,
    spokenLanguagesValidationPassed,
    setSpokenLanguagesValidationPassed,
    spokenLanguagesFailedMsg,
    setSpokenLanguagesFailedMsg,
    
  } = BusinessInfoState();
  const {disabled} = props
  const otherLanguageId = 7;

  useEffect(() => {
    SetValidation();
  }, [selectedSpokenLanguages]);

  const SetValidation = () => {
    //Make sure at least one language selected

    if (selectedSpokenLanguages.length > 0) {
      //still need to validate if other language is selected

      let hasFailedOtherLanguageValidation = selectedSpokenLanguages.some(
        (t) =>
          t.languageId == otherLanguageId &&
          (!t.otherLanguageDescription ||
            t.otherLanguageDescription.length === 0)
      );

      if (hasFailedOtherLanguageValidation) {
        setSpokenLanguagesValidationPassed(false);
        setSpokenLanguagesFailedMsg(
          "Other language description needs to be provided"
        );

        return;
      }

      setSpokenLanguagesValidationPassed(true);
    } else {
      setSpokenLanguagesValidationPassed(false);
      setSpokenLanguagesFailedMsg(
        "At least one language needs to be selected."
      );
    }
  };

  const updateSpokenLanguages = (languageId, isSelected, otherLanguage) => {
    let updated = {};
    if (isSelected) {
      //Add the language

      const languageToAdd = {
        applicationId: 0,
        id: languageId,
        languageId: languageId,
        otherLanguageDescription: otherLanguage,
      };

      let exists = selectedSpokenLanguages.some(
        (t) => t.languageId == languageId
      );

      if (exists) {
        //This is an update of an existing item
        updated = selectedSpokenLanguages.map((item) => {
          if (item.languageId == languageId)
            return {
              ...item,
              otherLanguageDescription: otherLanguage,
            };

          return item;
        });
      } else {
        updated = (selectedSpokenLanguages) => [
          ...selectedSpokenLanguages,
          languageToAdd,
        ];
      }
      //alert(JSON.stringify(languageToAdd));
    } else {
      //Remove the language
      updated = selectedSpokenLanguages.filter(
        (t) => t.languageId !== languageId
      );
    }

    //Make sure at least one language selected

    if (updated.length > 0) {
      setSpokenLanguagesValidationPassed(true);
    } else {
      setSpokenLanguagesValidationPassed(false);
      setSpokenLanguagesFailedMsg(
        "At least one language needs to be selected."
      );
    }

    setSelectedSpokenLanguages(updated);
  };

  const isLanguageActive = (languageId) => {
    const result = selectedSpokenLanguages.findIndex(
      (t) => t.languageId === languageId
    );
    return result >= 0;
  };

  const getOtherLanguageName = (languageId) => {
    const result = selectedSpokenLanguages.find(
      (t) => t.languageId === languageId
    );
    return result.otherLanguageDescription;
  };

  return (
    <div>
      <h5>Languages Spoken *</h5>

      {SpokenLanguagesConstant.map((item) => {
        return (
          <ul
            key={"SpokenLang" + item.value}
            className="list-group list-group-flush list-group-horizontal "
          >
            <li className="list-group-item">
              <input
                className="form-check-input me-1"
                type="checkbox"
                value={`${item.value}`}
                aria-label="..."
                checked={isLanguageActive(item.value)}
                disabled={disabled}
                onChange={(e) => {
                  updateSpokenLanguages(item.value, e.target.checked, "");
                }}
              />
              {item.name}
            </li>

            {item.value === otherLanguageId && isLanguageActive(item.value) && (
              <li className="list-group-item">
                <input
                  type="text"
                  name="otherLanguageDesc"
                  placeholder="Other Description"
                  width="100"
                  disabled={disabled}
                  onChange={(e) => {
                    updateSpokenLanguages(item.value, true, e.target.value);
                  }}
                  defaultValue={getOtherLanguageName(otherLanguageId)}
                />
              </li>
            )}
          </ul>
        );
      })}

      {failedSubmitWasPressed && !spokenLanguagesValidationPassed && (
        <div className="text-danger">
          {spokenLanguagesFailedMsg}
          {/* */}
        </div>
      )}
    </div>
  );
};

export default BusinessInfoSpokenLanguages;
