
export function CheckboxCell(props) {

    const {
        colSpan, 
        style, 
        dataItem,
        field, 
        onChange
       } = props

    function handleChange(e) {
        if(onChange) {
            onChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.checked
            })
        }
    }

    return (
        <td colSpan={colSpan} style={style}>
            <input type="checkbox" onChange={handleChange} checked={dataItem[field]}/>
        </td>
    )
}