export function CountyInput(props) {

	const {value, onChange, disabled, workflow } = props
	const { show, required } = workflow || {}

	if (!show) {
		return null;
	}
	return show && (
		
		<div className="mb-3 row">
    
			<label className="col-sm-2 col-form-label-sm" htmlFor="county">County</label>
			<div className="col-sm-3">
				<input 
					value={value ?? ""}
					onChange={onChange}
					type="text" 
					className="form-control form-control-sm" 
					id="county" 
					placeholder="County"
					required={required}
					disabled={disabled}/>
					<div className="invalid-feedback">Please enter a County.</div>

			</div>	
		</div>
	) 
}