import { createContext, useContext, useState } from "react";

const MemberServContext = createContext();

const MemberServiceContext = ({ children }) => {

  const [targetTypeData, setTargetTypeData] = useState([]);
  //const [applicationId, setApplicationId] = useState();
    const [ExpValidationPassed, setExpValidationPassed] =
    useState(true);
    const [selectedClassOfTrade, setSelectedClassOfTrade] = useState("");
    const [selectedType, setselectedType] = useState(1);
    const [memAppProgram, setmemAppProgram] = useState([]);

  return (
    <MemberServContext.Provider
      value={{
        targetTypeData,
        setTargetTypeData,
        ExpValidationPassed,
        setExpValidationPassed,
        selectedClassOfTrade, setSelectedClassOfTrade,
        selectedType, setselectedType,
        memAppProgram, setmemAppProgram,
      }}
    >
      {children}
    </MemberServContext.Provider>
  );
};

export const MemberServiceState = () => {
  return useContext(MemberServContext);
};

export default MemberServiceContext;
